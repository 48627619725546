/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * API Docs
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { customClient } from "../../../utils/api";
export type AuthAdminRefreshToken201 = { [key: string]: any };

export type AdminCreateDashboardUser201 = { [key: string]: any };

export type AdminUpdateDashboardUser200 = { [key: string]: any };

export type AdminGetDashboardUser200 = { [key: string]: any };

export type MessageReplyToMessage200 = { [key: string]: any };

export type MessageGetConversationsParams = {
  page?: string;
  pageSize?: string;
  searchTerm?: string;
};

export type JobGetJobParams = {
  userId?: string;
};

export type JobCreateOrUpdateFromScraper201 = { [key: string]: any };

export type OrganizationFindOneParams = {
  userId?: string;
};

export type OrganizationGetWpPost200 = { [key: string]: any };

export type OrganizationGetWorkPrideBrochure201 = { [key: string]: any };

export type OrganizationGetOrganizationInfoPage200 = { [key: string]: any };

export type UserDeactivateUserParams = {
  isHardDelete: string;
};

export type UserGetUserWpMetaDataParams = {
  authorizationToken: string;
  searchTerm?: string;
};

export type AuthRefreshToken201 = { [key: string]: any };

export interface WpPostEntityResponse {
  ID: string;
  post_date: string;
  post_name: string;
  post_title: string;
}

export type WpPostsDtoPostType =
  (typeof WpPostsDtoPostType)[keyof typeof WpPostsDtoPostType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WpPostsDtoPostType = {
  news: "news",
  events: "events",
} as const;

export interface WpPostsDto {
  postType: WpPostsDtoPostType;
  searchTerm?: string;
}

export type NotificationLogResponseEntityUserType =
  (typeof NotificationLogResponseEntityUserType)[keyof typeof NotificationLogResponseEntityUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogResponseEntityUserType = {
  all: "all",
  graduate: "graduate",
  professional: "professional",
} as const;

export interface NotificationLogResponseEntity {
  body: string;
  countries?: string[];
  createdAt: string;
  externalUrl?: string;
  id: number;
  interactions: NotificationInteractionEntity;
  linkType: NotificationLogResponseEntityLinkType;
  postName?: string;
  postSlug?: string;
  sentBy: UserEntity;
  title: string;
  totalClicks: number;
  type: NotificationLogResponseEntityType;
  userIds?: number[];
  userPreference?: NotificationLogResponseEntityUserPreference;
  userType: NotificationLogResponseEntityUserType;
}

export interface NotificationLogResponse {
  data: NotificationLogResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type NotificationLogResponseEntityUserPreference =
  (typeof NotificationLogResponseEntityUserPreference)[keyof typeof NotificationLogResponseEntityUserPreference];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogResponseEntityUserPreference = {
  Subscribed_Users: "Subscribed Users",
  All_Users: "All Users",
  Specific_Users: "Specific Users",
} as const;

export type NotificationLogResponseEntityType =
  (typeof NotificationLogResponseEntityType)[keyof typeof NotificationLogResponseEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogResponseEntityType = {
  Email: "Email",
  Push: "Push",
  Friend_Request: "Friend Request",
  Friend_Request_Accepted: "Friend Request Accepted",
  Accepted_Join_Organization_Request: "Accepted Join Organization Request",
  Rejected_Join_Organization_Request: "Rejected Join Organization Request",
  Accepted_Organization_Invitation: "Accepted Organization Invitation",
  User_Profile_Viewed: "User Profile Viewed",
} as const;

export type NotificationLogResponseEntityLinkType =
  (typeof NotificationLogResponseEntityLinkType)[keyof typeof NotificationLogResponseEntityLinkType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogResponseEntityLinkType = {
  None: "None",
  Article: "Article",
  Event: "Event",
  External_URL: "External URL",
} as const;

export type NotificationLogDtoSortOrder =
  (typeof NotificationLogDtoSortOrder)[keyof typeof NotificationLogDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface NotificationLogDto {
  filters?: NotificationFiltersDto;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: NotificationLogDtoSortOrder;
}

export type NotificationFiltersDtoUserTypeSearchOperation =
  (typeof NotificationFiltersDtoUserTypeSearchOperation)[keyof typeof NotificationFiltersDtoUserTypeSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationFiltersDtoUserTypeSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type NotificationFiltersDtoUserType =
  (typeof NotificationFiltersDtoUserType)[keyof typeof NotificationFiltersDtoUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationFiltersDtoUserType = {
  all: "all",
  graduate: "graduate",
  professional: "professional",
} as const;

export type NotificationFiltersDtoTypeSearchOperation =
  (typeof NotificationFiltersDtoTypeSearchOperation)[keyof typeof NotificationFiltersDtoTypeSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationFiltersDtoTypeSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type NotificationFiltersDtoType =
  (typeof NotificationFiltersDtoType)[keyof typeof NotificationFiltersDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationFiltersDtoType = {
  Email: "Email",
  Push: "Push",
  Friend_Request: "Friend Request",
  Friend_Request_Accepted: "Friend Request Accepted",
  Accepted_Join_Organization_Request: "Accepted Join Organization Request",
  Rejected_Join_Organization_Request: "Rejected Join Organization Request",
  Accepted_Organization_Invitation: "Accepted Organization Invitation",
  User_Profile_Viewed: "User Profile Viewed",
} as const;

export type NotificationFiltersDtoTitleSearchOperation =
  (typeof NotificationFiltersDtoTitleSearchOperation)[keyof typeof NotificationFiltersDtoTitleSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationFiltersDtoTitleSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type NotificationFiltersDtoSentByEqualOperation =
  (typeof NotificationFiltersDtoSentByEqualOperation)[keyof typeof NotificationFiltersDtoSentByEqualOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationFiltersDtoSentByEqualOperation = {
  is_equal_to: "is equal to",
  is_not_equal_to: "is not equal to",
} as const;

export type NotificationFiltersDtoCreatedAtSearchOperation =
  (typeof NotificationFiltersDtoCreatedAtSearchOperation)[keyof typeof NotificationFiltersDtoCreatedAtSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationFiltersDtoCreatedAtSearchOperation = {
  between: "between",
  not_between: "not between",
} as const;

export interface NotificationFiltersDto {
  createdAt?: CreatedAtDto;
  createdAtSearchOperation?: NotificationFiltersDtoCreatedAtSearchOperation;
  sentBy?: number;
  sentByEqualOperation?: NotificationFiltersDtoSentByEqualOperation;
  title?: string;
  titleSearchOperation?: NotificationFiltersDtoTitleSearchOperation;
  type?: NotificationFiltersDtoType;
  typeSearchOperation?: NotificationFiltersDtoTypeSearchOperation;
  userType?: NotificationFiltersDtoUserType;
  userTypeSearchOperation?: NotificationFiltersDtoUserTypeSearchOperation;
}

export type CreateNotificationLogDtoUserType =
  (typeof CreateNotificationLogDtoUserType)[keyof typeof CreateNotificationLogDtoUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateNotificationLogDtoUserType = {
  all: "all",
  graduate: "graduate",
  professional: "professional",
} as const;

export type CreateNotificationLogDtoUserPreference =
  (typeof CreateNotificationLogDtoUserPreference)[keyof typeof CreateNotificationLogDtoUserPreference];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateNotificationLogDtoUserPreference = {
  Subscribed_Users: "Subscribed Users",
  All_Users: "All Users",
  Specific_Users: "Specific Users",
} as const;

export type CreateNotificationLogDtoType =
  (typeof CreateNotificationLogDtoType)[keyof typeof CreateNotificationLogDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateNotificationLogDtoType = {
  Email: "Email",
  Push: "Push",
  Friend_Request: "Friend Request",
  Friend_Request_Accepted: "Friend Request Accepted",
  Accepted_Join_Organization_Request: "Accepted Join Organization Request",
  Rejected_Join_Organization_Request: "Rejected Join Organization Request",
  Accepted_Organization_Invitation: "Accepted Organization Invitation",
  User_Profile_Viewed: "User Profile Viewed",
} as const;

export type CreateNotificationLogDtoLinkType =
  (typeof CreateNotificationLogDtoLinkType)[keyof typeof CreateNotificationLogDtoLinkType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateNotificationLogDtoLinkType = {
  None: "None",
  Article: "Article",
  Event: "Event",
  External_URL: "External URL",
} as const;

export type CreateNotificationLogDtoCountriesItem =
  (typeof CreateNotificationLogDtoCountriesItem)[keyof typeof CreateNotificationLogDtoCountriesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateNotificationLogDtoCountriesItem = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface CreateNotificationLogDto {
  body: string;
  countries?: CreateNotificationLogDtoCountriesItem[];
  externalUrl?: string;
  linkType?: CreateNotificationLogDtoLinkType;
  postName?: string;
  postSlug?: string;
  title: string;
  type: CreateNotificationLogDtoType;
  userIds?: number[];
  userPreference?: CreateNotificationLogDtoUserPreference;
  userType: CreateNotificationLogDtoUserType;
}

export interface UserAbuseReportUserDate {
  email: string;
  full_name: string;
  id: number;
}

export interface UserAbuseReportData {
  createdAt: string;
  id: number;
  reason: string;
  reported_by_user: UserAbuseReportUserDate;
  user: UserAbuseReportUserDate;
}

export interface UserAbuseReportResponse {
  data: UserAbuseReportData[];
  page: number;
  pageSize: number;
  total: number;
}

export type UserAbuseReportDtoSortOrder =
  (typeof UserAbuseReportDtoSortOrder)[keyof typeof UserAbuseReportDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAbuseReportDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface UserAbuseReportDto {
  keyword?: string;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: UserAbuseReportDtoSortOrder;
}

export type CreateBlacklistDtoType =
  (typeof CreateBlacklistDtoType)[keyof typeof CreateBlacklistDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateBlacklistDtoType = {
  ip_address: "ip_address",
  email: "email",
  banned: "banned",
} as const;

export interface CreateBlacklistDto {
  /**
   * @minLength 2
   * @maxLength 50
   */
  name?: string;
  type?: CreateBlacklistDtoType;
  /**
   * @minLength 2
   * @maxLength 50
   */
  value: string;
}

export type BlacklistEntityType =
  (typeof BlacklistEntityType)[keyof typeof BlacklistEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BlacklistEntityType = {
  ip_address: "ip_address",
  email: "email",
  banned: "banned",
} as const;

export interface BlacklistEntity {
  createdAt: string;
  createdBy: UserEntity;
  id: number;
  name?: string;
  type: BlacklistEntityType;
  user?: UserEntity;
  value: string;
}

export interface BlacklistResponse {
  data: BlacklistEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type BlacklistDtoSortOrder =
  (typeof BlacklistDtoSortOrder)[keyof typeof BlacklistDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BlacklistDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface BlacklistDto {
  keyword?: string;
  name?: string;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: BlacklistDtoSortOrder;
  value?: string;
}

export interface ProfileImage {
  fileType?: ProfileImageFileType;
  name?: string;
}

export interface CreateDashboardUserDto {
  contact_phone?: string;
  email: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  first_name: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  last_name: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  passwordConfirmation?: string;
  profileImage?: ProfileImage;
  /** @nullable */
  twoFactorPhoneNumber?: string | null;
  userRole: number;
}

export interface UpdateDashboardUserDto {
  contact_phone?: string;
  email?: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  first_name?: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  last_name?: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password?: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  passwordConfirmation?: string;
  profileImage?: ProfileImage;
  /** @nullable */
  twoFactorPhoneNumber?: string | null;
  userRole?: number;
}

export type ProfileImageFileType =
  (typeof ProfileImageFileType)[keyof typeof ProfileImageFileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProfileImageFileType = {
  "image/jpeg": "image/jpeg",
  "image/png": "image/png",
  "image/gif": "image/gif",
  "image/svg+xml": "image/svg+xml",
  "image/webp": "image/webp",
  "video/mp4": "video/mp4",
  "video/quicktime": "video/quicktime",
  "application/pdf": "application/pdf",
  "application/msword": "application/msword",
  "application/vndopenxmlformats-officedocumentwordprocessingmldocument":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vndms-excel": "application/vnd.ms-excel",
  "application/vndopenxmlformats-officedocumentspreadsheetmlsheet":
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vndms-powerpoint": "application/vnd.ms-powerpoint",
  "application/vndopenxmlformats-officedocumentpresentationmlpresentation":
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/csv": "text/csv",
  "audio/mpeg": "audio/mpeg",
} as const;

export interface DashboardUserResponse {
  data: UserEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type DashboardUserDtoSortOrder =
  (typeof DashboardUserDtoSortOrder)[keyof typeof DashboardUserDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DashboardUserDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface DashboardUserDto {
  keyword?: string;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: DashboardUserDtoSortOrder;
}

export interface EntryLevelVsRegularJobsData {
  early_career: number;
  organization_id: number;
  organization_name: string;
  regular_jobs: number;
}

export interface EntryLevelVsRegularJobsReportData {
  data: EntryLevelVsRegularJobsData[];
  page: number;
  pageSize: number;
  total: number;
}

export interface EntryLevelJobsData {
  country: string;
  job_title: string;
  organization_id: number;
  organization_name: string;
}

export interface EntryLevelJobsReportData {
  data: EntryLevelJobsData[];
  page: number;
  pageSize: number;
  total: number;
}

export type EntryLevelJobReportsDtoSortOrder =
  (typeof EntryLevelJobReportsDtoSortOrder)[keyof typeof EntryLevelJobReportsDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntryLevelJobReportsDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface EntryLevelJobReportsDto {
  endDate?: string;
  keyword?: string;
  organizationIds?: number[];
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: EntryLevelJobReportsDtoSortOrder;
  startDate?: string;
}

export interface OrganizationIdsReportResponse {
  id: number;
  organization: string;
}

export interface OrganizationJobsStatsData {
  jobs_missing_country: number;
  organization_name: string;
  total_jobs: number;
  updatedAt: string;
}

export interface OrganizationJobsStatsReportData {
  data: OrganizationJobsStatsData[];
  page: number;
  pageSize: number;
  total: number;
}

export type OrganizationJobsStatsReportDtoSortOrder =
  (typeof OrganizationJobsStatsReportDtoSortOrder)[keyof typeof OrganizationJobsStatsReportDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationJobsStatsReportDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface OrganizationJobsStatsReportDto {
  organization?: string;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: OrganizationJobsStatsReportDtoSortOrder;
}

export interface OrganizationClicksData {
  active_jobs: number;
  clicks: number;
  organization_name: string;
  views: number;
}

export interface OrganizationClicksReportData {
  data: OrganizationClicksData[];
  page: number;
  pageSize: number;
  total: number;
}

export type OrganizationClicksReportDtoSortOrder =
  (typeof OrganizationClicksReportDtoSortOrder)[keyof typeof OrganizationClicksReportDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationClicksReportDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface OrganizationClicksReportDto {
  endDate: string;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: OrganizationClicksReportDtoSortOrder;
  startDate: string;
}

export interface MonthlyUniqueLoginData {
  last_activity: string;
  unique_login: number;
  user_id: number;
  user_name: string;
}

export interface MonthlyUniqueLoginReportData {
  data: MonthlyUniqueLoginData[];
  page: number;
  pageSize: number;
  total: number;
}

export interface JobByMissingDescriptionData {
  id: number;
  organisation: string;
  organisation_id: number;
  source: string;
  source_internal_id: string;
  title: string;
}

export interface JobByCountryData {
  country: string;
  jobs: number;
}

export interface JobByClicksData {
  apply_url: string;
  area_name: string;
  clicks: number;
  country: string;
  created_at: string;
  description: string;
  expired_date: string;
  industry_name: string;
  job_id: number;
  job_url: string;
  /** @nullable */
  level: string | null;
  location: string;
  organisation: string;
  position: string;
  title: string;
  type_of_contract: string;
  years_of_experience: string;
}

export interface JobClicksViewsData {
  areaName: string;
  clicks: number;
  country: string;
  createdAt: string;
  /** @nullable */
  deletedAt: string | null;
  id: number;
  industryName: string;
  location: string;
  organizationName: string;
  title: string;
  views: number;
}

export interface JobClicksViewsReportData {
  data: JobClicksViewsData[];
  page: number;
  pageSize: number;
  total: number;
}

export type JobClicksViewsReportDtoSortOrder =
  (typeof JobClicksViewsReportDtoSortOrder)[keyof typeof JobClicksViewsReportDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobClicksViewsReportDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export type JobClicksViewsReportDtoCountry =
  (typeof JobClicksViewsReportDtoCountry)[keyof typeof JobClicksViewsReportDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobClicksViewsReportDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface JobClicksViewsReportDto {
  country?: JobClicksViewsReportDtoCountry;
  endDate: string;
  keyword?: string;
  organizationId?: number;
  page?: number;
  pageSize?: number;
  showWithNoAreaIndustry?: boolean;
  showWithNoClicks?: boolean;
  sort_column?: string;
  sort_order?: JobClicksViewsReportDtoSortOrder;
  startDate: string;
}

export interface UserAverageLoginData {
  average_login: number;
  last_activity: string;
  user_id: number;
  user_name: string;
}

export interface UserAverageLoginReportData {
  data: UserAverageLoginData[];
  page: number;
  pageSize: number;
  total: number;
}

export interface UserLoginData {
  last_activity: string;
  login_count: number;
  user_id: number;
  user_name: string;
}

export interface UserLoginReportData {
  data: UserLoginData[];
  page: number;
  pageSize: number;
  total: number;
}

export type UserLoginReportDtoSortOrder =
  (typeof UserLoginReportDtoSortOrder)[keyof typeof UserLoginReportDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserLoginReportDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface UserLoginReportDto {
  endDate?: string;
  keyword?: string;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: UserLoginReportDtoSortOrder;
  startDate?: string;
}

export interface JobScraperLogEntity {
  completedAt: string;
  error: string;
  id: number;
  jobSaveDuration: number;
  newlyCreatedJobs: number;
  removedJobs: number;
  startedAt: string;
  totalJobs: number;
  updatedJobs: number;
}

export interface JobScraperEntity {
  createdAt: string;
  executeDate: string;
  executeDay: string;
  executeInterval: string;
  executeTime: string;
  id: number;
  isActive: boolean;
  isImmediateDelete: boolean;
  jobScraperLogs: JobScraperLogEntity[];
  organizationId: number;
  organizationIdentifier: string;
  updatedAt: string;
  updatedBy: number;
  /** @nullable */
  updatedByUser: string | null;
}

export interface JobsScrapersResponse {
  data: JobScraperEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type JobsScrapersDtoSortOrder =
  (typeof JobsScrapersDtoSortOrder)[keyof typeof JobsScrapersDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobsScrapersDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface JobsScrapersDto {
  filters?: JobsScrapersFilters;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: JobsScrapersDtoSortOrder;
}

export type JobsScrapersFiltersOrganizationIdentifierSearchOperation =
  (typeof JobsScrapersFiltersOrganizationIdentifierSearchOperation)[keyof typeof JobsScrapersFiltersOrganizationIdentifierSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobsScrapersFiltersOrganizationIdentifierSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export interface JobsScrapersFilters {
  organizationIdentifier?: string;
  organizationIdentifierSearchOperation?: JobsScrapersFiltersOrganizationIdentifierSearchOperation;
}

export interface AdminWorkFairResponse {
  data: WorkFairEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type WorkFairDtoSortOrder =
  (typeof WorkFairDtoSortOrder)[keyof typeof WorkFairDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface WorkFairDto {
  filters?: WorkFairFiltersDto;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: WorkFairDtoSortOrder;
}

export type WorkFairFiltersDtoOrganizationSearchOperation =
  (typeof WorkFairFiltersDtoOrganizationSearchOperation)[keyof typeof WorkFairFiltersDtoOrganizationSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairFiltersDtoOrganizationSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkFairFiltersDtoLastNameSearchOperation =
  (typeof WorkFairFiltersDtoLastNameSearchOperation)[keyof typeof WorkFairFiltersDtoLastNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairFiltersDtoLastNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkFairFiltersDtoJobTitleSearchOperation =
  (typeof WorkFairFiltersDtoJobTitleSearchOperation)[keyof typeof WorkFairFiltersDtoJobTitleSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairFiltersDtoJobTitleSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkFairFiltersDtoFirstNameSearchOperation =
  (typeof WorkFairFiltersDtoFirstNameSearchOperation)[keyof typeof WorkFairFiltersDtoFirstNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairFiltersDtoFirstNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkFairFiltersDtoEmailSearchOperation =
  (typeof WorkFairFiltersDtoEmailSearchOperation)[keyof typeof WorkFairFiltersDtoEmailSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairFiltersDtoEmailSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkFairFiltersDtoCreatedAtSearchOperation =
  (typeof WorkFairFiltersDtoCreatedAtSearchOperation)[keyof typeof WorkFairFiltersDtoCreatedAtSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairFiltersDtoCreatedAtSearchOperation = {
  between: "between",
  not_between: "not between",
} as const;

export type WorkFairFiltersDtoCountrySearchOperation =
  (typeof WorkFairFiltersDtoCountrySearchOperation)[keyof typeof WorkFairFiltersDtoCountrySearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairFiltersDtoCountrySearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkFairFiltersDtoCountry =
  (typeof WorkFairFiltersDtoCountry)[keyof typeof WorkFairFiltersDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairFiltersDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface WorkFairFiltersDto {
  country?: WorkFairFiltersDtoCountry;
  countrySearchOperation?: WorkFairFiltersDtoCountrySearchOperation;
  createdAt?: CreatedAtDto;
  createdAtSearchOperation?: WorkFairFiltersDtoCreatedAtSearchOperation;
  email?: string;
  emailSearchOperation?: WorkFairFiltersDtoEmailSearchOperation;
  first_name?: string;
  firstNameSearchOperation?: WorkFairFiltersDtoFirstNameSearchOperation;
  job_title?: string;
  jobTitleSearchOperation?: WorkFairFiltersDtoJobTitleSearchOperation;
  last_name?: string;
  lastNameSearchOperation?: WorkFairFiltersDtoLastNameSearchOperation;
  organization?: string;
  organizationSearchOperation?: WorkFairFiltersDtoOrganizationSearchOperation;
}

export type WorkPrideEntityPronoun =
  (typeof WorkPrideEntityPronoun)[keyof typeof WorkPrideEntityPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideEntityPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export type WorkPrideEntityInterestedInBecoming =
  (typeof WorkPrideEntityInterestedInBecoming)[keyof typeof WorkPrideEntityInterestedInBecoming];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideEntityInterestedInBecoming = {
  Attendee: "Attendee",
  Main_Sponsor: "Main Sponsor",
  Day_Sponsor: "Day Sponsor",
  Session_Sponsor: "Session Sponsor",
  Speaker: "Speaker",
} as const;

export type WorkPrideEntityCountry =
  (typeof WorkPrideEntityCountry)[keyof typeof WorkPrideEntityCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideEntityCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface WorkPrideEntity {
  country: WorkPrideEntityCountry;
  createdAt: string;
  email: string;
  first_name: string;
  id: number;
  interested_in_becoming: WorkPrideEntityInterestedInBecoming;
  is_hiring: boolean;
  job_title?: string;
  last_name: string;
  location?: string;
  organization?: string;
  pronoun: WorkPrideEntityPronoun;
  /** @minimum 2024 */
  workpride_year: number;
}

export interface AdminWorkPrideResponse {
  data: WorkPrideEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type WorkPrideDtoSortOrder =
  (typeof WorkPrideDtoSortOrder)[keyof typeof WorkPrideDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface WorkPrideDto {
  filters?: WorkPrideFiltersDto;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: WorkPrideDtoSortOrder;
}

export type WorkPrideFiltersDtoPronounSearchOperation =
  (typeof WorkPrideFiltersDtoPronounSearchOperation)[keyof typeof WorkPrideFiltersDtoPronounSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoPronounSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkPrideFiltersDtoPronoun =
  (typeof WorkPrideFiltersDtoPronoun)[keyof typeof WorkPrideFiltersDtoPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export type WorkPrideFiltersDtoOrganizationSearchOperation =
  (typeof WorkPrideFiltersDtoOrganizationSearchOperation)[keyof typeof WorkPrideFiltersDtoOrganizationSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoOrganizationSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkPrideFiltersDtoLastNameSearchOperation =
  (typeof WorkPrideFiltersDtoLastNameSearchOperation)[keyof typeof WorkPrideFiltersDtoLastNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoLastNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkPrideFiltersDtoJobTitleSearchOperation =
  (typeof WorkPrideFiltersDtoJobTitleSearchOperation)[keyof typeof WorkPrideFiltersDtoJobTitleSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoJobTitleSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkPrideFiltersDtoInterestedInBecomingSearchOperation =
  (typeof WorkPrideFiltersDtoInterestedInBecomingSearchOperation)[keyof typeof WorkPrideFiltersDtoInterestedInBecomingSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoInterestedInBecomingSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkPrideFiltersDtoInterestedInBecoming =
  (typeof WorkPrideFiltersDtoInterestedInBecoming)[keyof typeof WorkPrideFiltersDtoInterestedInBecoming];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoInterestedInBecoming = {
  Attendee: "Attendee",
  Main_Sponsor: "Main Sponsor",
  Day_Sponsor: "Day Sponsor",
  Session_Sponsor: "Session Sponsor",
  Speaker: "Speaker",
} as const;

export type WorkPrideFiltersDtoFirstNameSearchOperation =
  (typeof WorkPrideFiltersDtoFirstNameSearchOperation)[keyof typeof WorkPrideFiltersDtoFirstNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoFirstNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkPrideFiltersDtoEmailSearchOperation =
  (typeof WorkPrideFiltersDtoEmailSearchOperation)[keyof typeof WorkPrideFiltersDtoEmailSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoEmailSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkPrideFiltersDtoCreatedAtSearchOperation =
  (typeof WorkPrideFiltersDtoCreatedAtSearchOperation)[keyof typeof WorkPrideFiltersDtoCreatedAtSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoCreatedAtSearchOperation = {
  between: "between",
  not_between: "not between",
} as const;

export type WorkPrideFiltersDtoCountrySearchOperation =
  (typeof WorkPrideFiltersDtoCountrySearchOperation)[keyof typeof WorkPrideFiltersDtoCountrySearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoCountrySearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type WorkPrideFiltersDtoCountry =
  (typeof WorkPrideFiltersDtoCountry)[keyof typeof WorkPrideFiltersDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkPrideFiltersDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface WorkPrideFiltersDto {
  country?: WorkPrideFiltersDtoCountry;
  countrySearchOperation?: WorkPrideFiltersDtoCountrySearchOperation;
  createdAt?: CreatedAtDto;
  createdAtSearchOperation?: WorkPrideFiltersDtoCreatedAtSearchOperation;
  email?: string;
  emailSearchOperation?: WorkPrideFiltersDtoEmailSearchOperation;
  first_name?: string;
  firstNameSearchOperation?: WorkPrideFiltersDtoFirstNameSearchOperation;
  interested_in_becoming?: WorkPrideFiltersDtoInterestedInBecoming;
  interestedInBecomingSearchOperation?: WorkPrideFiltersDtoInterestedInBecomingSearchOperation;
  is_hiring?: boolean;
  job_title?: string;
  jobTitleSearchOperation?: WorkPrideFiltersDtoJobTitleSearchOperation;
  last_name?: string;
  lastNameSearchOperation?: WorkPrideFiltersDtoLastNameSearchOperation;
  organization?: string;
  organizationSearchOperation?: WorkPrideFiltersDtoOrganizationSearchOperation;
  pronoun?: WorkPrideFiltersDtoPronoun;
  pronounSearchOperation?: WorkPrideFiltersDtoPronounSearchOperation;
}

export type NewsAttachmentLeadEntityCountry =
  (typeof NewsAttachmentLeadEntityCountry)[keyof typeof NewsAttachmentLeadEntityCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadEntityCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface NewsAttachmentLeadEntity {
  article_id: number;
  article_slug: string;
  article_title: string;
  country: NewsAttachmentLeadEntityCountry;
  createdAt: string;
  email: string;
  first_name: string;
  id: number;
  is_hiring: boolean;
  job_title?: string;
  last_name: string;
  location?: string;
  organization: string;
  report_name: string;
}

export interface NewsAttachmentLeadResponse {
  data: NewsAttachmentLeadEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type NewsAttachmentLeadDtoSortOrder =
  (typeof NewsAttachmentLeadDtoSortOrder)[keyof typeof NewsAttachmentLeadDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface NewsAttachmentLeadDto {
  filters?: NewsAttachmentLeadFiltersDto;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: NewsAttachmentLeadDtoSortOrder;
}

export type NewsAttachmentLeadFiltersDtoOrganizationSearchOperation =
  (typeof NewsAttachmentLeadFiltersDtoOrganizationSearchOperation)[keyof typeof NewsAttachmentLeadFiltersDtoOrganizationSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadFiltersDtoOrganizationSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type NewsAttachmentLeadFiltersDtoLastNameSearchOperation =
  (typeof NewsAttachmentLeadFiltersDtoLastNameSearchOperation)[keyof typeof NewsAttachmentLeadFiltersDtoLastNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadFiltersDtoLastNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type NewsAttachmentLeadFiltersDtoJobTitleSearchOperation =
  (typeof NewsAttachmentLeadFiltersDtoJobTitleSearchOperation)[keyof typeof NewsAttachmentLeadFiltersDtoJobTitleSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadFiltersDtoJobTitleSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type NewsAttachmentLeadFiltersDtoFirstNameSearchOperation =
  (typeof NewsAttachmentLeadFiltersDtoFirstNameSearchOperation)[keyof typeof NewsAttachmentLeadFiltersDtoFirstNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadFiltersDtoFirstNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type NewsAttachmentLeadFiltersDtoEmailSearchOperation =
  (typeof NewsAttachmentLeadFiltersDtoEmailSearchOperation)[keyof typeof NewsAttachmentLeadFiltersDtoEmailSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadFiltersDtoEmailSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type NewsAttachmentLeadFiltersDtoCreatedAtSearchOperation =
  (typeof NewsAttachmentLeadFiltersDtoCreatedAtSearchOperation)[keyof typeof NewsAttachmentLeadFiltersDtoCreatedAtSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadFiltersDtoCreatedAtSearchOperation = {
  between: "between",
  not_between: "not between",
} as const;

export type NewsAttachmentLeadFiltersDtoCountry =
  (typeof NewsAttachmentLeadFiltersDtoCountry)[keyof typeof NewsAttachmentLeadFiltersDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadFiltersDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export type NewsAttachmentLeadFiltersDtoArticleIdComparisonOperation =
  (typeof NewsAttachmentLeadFiltersDtoArticleIdComparisonOperation)[keyof typeof NewsAttachmentLeadFiltersDtoArticleIdComparisonOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsAttachmentLeadFiltersDtoArticleIdComparisonOperation = {
  is_equal_to: "is equal to",
  is_not_equal_to: "is not equal to",
  is_greater_than: "is greater than",
  is_greater_than_or_equal_to: "is greater than or equal to",
  is_less_than: "is less than",
  is_less_than_or_equal_to: "is less than or equal to",
} as const;

export interface NewsAttachmentLeadFiltersDto {
  article_id?: number;
  article_title?: string;
  articleIdComparisonOperation?: NewsAttachmentLeadFiltersDtoArticleIdComparisonOperation;
  country?: NewsAttachmentLeadFiltersDtoCountry;
  createdAt?: CreatedAtDto;
  createdAtSearchOperation?: NewsAttachmentLeadFiltersDtoCreatedAtSearchOperation;
  email?: string;
  emailSearchOperation?: NewsAttachmentLeadFiltersDtoEmailSearchOperation;
  first_name?: string;
  firstNameSearchOperation?: NewsAttachmentLeadFiltersDtoFirstNameSearchOperation;
  is_hiring?: boolean;
  job_title?: string;
  jobTitleSearchOperation?: NewsAttachmentLeadFiltersDtoJobTitleSearchOperation;
  last_name?: string;
  lastNameSearchOperation?: NewsAttachmentLeadFiltersDtoLastNameSearchOperation;
  organization?: string;
  organizationSearchOperation?: NewsAttachmentLeadFiltersDtoOrganizationSearchOperation;
  report_name?: string;
}

export interface OrganizationInquiryResponse {
  data: OrganizationInquiryEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type OrganizationInquiryDtoSortOrder =
  (typeof OrganizationInquiryDtoSortOrder)[keyof typeof OrganizationInquiryDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface OrganizationInquiryDto {
  filters?: OrganizationInquiryFiltersDto;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: OrganizationInquiryDtoSortOrder;
}

export type OrganizationInquiryFiltersDtoOrganizationNameSearchOperation =
  (typeof OrganizationInquiryFiltersDtoOrganizationNameSearchOperation)[keyof typeof OrganizationInquiryFiltersDtoOrganizationNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoOrganizationNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type OrganizationInquiryFiltersDtoNameSearchOperation =
  (typeof OrganizationInquiryFiltersDtoNameSearchOperation)[keyof typeof OrganizationInquiryFiltersDtoNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type OrganizationInquiryFiltersDtoJobTitleSearchOperation =
  (typeof OrganizationInquiryFiltersDtoJobTitleSearchOperation)[keyof typeof OrganizationInquiryFiltersDtoJobTitleSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoJobTitleSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type OrganizationInquiryFiltersDtoHearFormUsSearchOperation =
  (typeof OrganizationInquiryFiltersDtoHearFormUsSearchOperation)[keyof typeof OrganizationInquiryFiltersDtoHearFormUsSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoHearFormUsSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type OrganizationInquiryFiltersDtoHearFormUs =
  (typeof OrganizationInquiryFiltersDtoHearFormUs)[keyof typeof OrganizationInquiryFiltersDtoHearFormUs];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoHearFormUs = {
  "Search_engine_(Google,_Yahoo,_etc)": "Search engine (Google, Yahoo, etc.)",
  Recommended_by_a_friend_or_colleague: "Recommended by a friend or colleague",
  Social_Media: "Social Media",
  Blog_or_publication: "Blog or publication",
  Exhibition_or_event: "Exhibition or event",
  Other: "Other",
} as const;

export type OrganizationInquiryFiltersDtoEmailSearchOperation =
  (typeof OrganizationInquiryFiltersDtoEmailSearchOperation)[keyof typeof OrganizationInquiryFiltersDtoEmailSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoEmailSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type OrganizationInquiryFiltersDtoCreatedAtSearchOperation =
  (typeof OrganizationInquiryFiltersDtoCreatedAtSearchOperation)[keyof typeof OrganizationInquiryFiltersDtoCreatedAtSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoCreatedAtSearchOperation = {
  between: "between",
  not_between: "not between",
} as const;

export type OrganizationInquiryFiltersDtoCountrySearchOperation =
  (typeof OrganizationInquiryFiltersDtoCountrySearchOperation)[keyof typeof OrganizationInquiryFiltersDtoCountrySearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoCountrySearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type OrganizationInquiryFiltersDtoCountry =
  (typeof OrganizationInquiryFiltersDtoCountry)[keyof typeof OrganizationInquiryFiltersDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export type OrganizationInquiryFiltersDtoContactNumberSearchOperation =
  (typeof OrganizationInquiryFiltersDtoContactNumberSearchOperation)[keyof typeof OrganizationInquiryFiltersDtoContactNumberSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryFiltersDtoContactNumberSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export interface OrganizationInquiryFiltersDto {
  contact_number?: string;
  contactNumberSearchOperation?: OrganizationInquiryFiltersDtoContactNumberSearchOperation;
  country?: OrganizationInquiryFiltersDtoCountry;
  countrySearchOperation?: OrganizationInquiryFiltersDtoCountrySearchOperation;
  createdAt?: CreatedAtDto;
  createdAtSearchOperation?: OrganizationInquiryFiltersDtoCreatedAtSearchOperation;
  email?: string;
  emailSearchOperation?: OrganizationInquiryFiltersDtoEmailSearchOperation;
  hear_form_us?: OrganizationInquiryFiltersDtoHearFormUs;
  hearFormUsSearchOperation?: OrganizationInquiryFiltersDtoHearFormUsSearchOperation;
  job_title?: string;
  jobTitleSearchOperation?: OrganizationInquiryFiltersDtoJobTitleSearchOperation;
  name?: string;
  nameSearchOperation?: OrganizationInquiryFiltersDtoNameSearchOperation;
  organization_name?: string;
  organizationNameSearchOperation?: OrganizationInquiryFiltersDtoOrganizationNameSearchOperation;
}

export interface AcademyResponse {
  data: AcademyEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type AcademyDtoSortOrder =
  (typeof AcademyDtoSortOrder)[keyof typeof AcademyDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface AcademyDto {
  filters?: AcademyFiltersDto;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: AcademyDtoSortOrder;
}

export type AcademyFiltersDtoPhoneSearchOperation =
  (typeof AcademyFiltersDtoPhoneSearchOperation)[keyof typeof AcademyFiltersDtoPhoneSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyFiltersDtoPhoneSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type AcademyFiltersDtoOrganizationSearchOperation =
  (typeof AcademyFiltersDtoOrganizationSearchOperation)[keyof typeof AcademyFiltersDtoOrganizationSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyFiltersDtoOrganizationSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type AcademyFiltersDtoNameSearchOperation =
  (typeof AcademyFiltersDtoNameSearchOperation)[keyof typeof AcademyFiltersDtoNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyFiltersDtoNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type AcademyFiltersDtoJobTitleSearchOperation =
  (typeof AcademyFiltersDtoJobTitleSearchOperation)[keyof typeof AcademyFiltersDtoJobTitleSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyFiltersDtoJobTitleSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type AcademyFiltersDtoInterestedBy =
  (typeof AcademyFiltersDtoInterestedBy)[keyof typeof AcademyFiltersDtoInterestedBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyFiltersDtoInterestedBy = {
  "E-Learning_Courses": "E-Learning Courses",
  In_Person_or_Virtual_Training: "In Person or Virtual Training",
} as const;

export type AcademyFiltersDtoEmailSearchOperation =
  (typeof AcademyFiltersDtoEmailSearchOperation)[keyof typeof AcademyFiltersDtoEmailSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyFiltersDtoEmailSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type AcademyFiltersDtoCreatedAtSearchOperation =
  (typeof AcademyFiltersDtoCreatedAtSearchOperation)[keyof typeof AcademyFiltersDtoCreatedAtSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyFiltersDtoCreatedAtSearchOperation = {
  between: "between",
  not_between: "not between",
} as const;

export type AcademyFiltersDtoCountry =
  (typeof AcademyFiltersDtoCountry)[keyof typeof AcademyFiltersDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyFiltersDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface AcademyFiltersDto {
  country?: AcademyFiltersDtoCountry;
  createdAt?: CreatedAtDto;
  createdAtSearchOperation?: AcademyFiltersDtoCreatedAtSearchOperation;
  email?: string;
  emailSearchOperation?: AcademyFiltersDtoEmailSearchOperation;
  interested_by?: AcademyFiltersDtoInterestedBy;
  job_title?: string;
  jobTitleSearchOperation?: AcademyFiltersDtoJobTitleSearchOperation;
  name?: string;
  nameSearchOperation?: AcademyFiltersDtoNameSearchOperation;
  organization?: string;
  organizationSearchOperation?: AcademyFiltersDtoOrganizationSearchOperation;
  phone_number?: string;
  phoneSearchOperation?: AcademyFiltersDtoPhoneSearchOperation;
}

export interface BanUserDto {
  reason: string;
  userId: number;
}

export type UpdateUserDtoYearsOfExperience =
  (typeof UpdateUserDtoYearsOfExperience)[keyof typeof UpdateUserDtoYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUserDtoYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type UpdateUserDtoSeniority =
  (typeof UpdateUserDtoSeniority)[keyof typeof UpdateUserDtoSeniority];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUserDtoSeniority = {
  Entry_Level: "Entry Level",
  Associate: "Associate",
  Manager: "Manager",
  Senior_Manager: "Senior Manager",
  Director: "Director",
  Vice_President: "Vice President",
  Executive: "Executive",
  "C-Level": "C-Level",
  Board_Member: "Board Member",
} as const;

export type UpdateUserDtoPronoun =
  (typeof UpdateUserDtoPronoun)[keyof typeof UpdateUserDtoPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUserDtoPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export type UpdateUserDtoDesiredTypeOfContactItem =
  (typeof UpdateUserDtoDesiredTypeOfContactItem)[keyof typeof UpdateUserDtoDesiredTypeOfContactItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUserDtoDesiredTypeOfContactItem = {
  Chat: "Chat",
  Phone_call: "Phone call",
  Skype: "Skype",
  Lunch: "Lunch",
  Coffee: "Coffee",
  Video: "Video",
} as const;

export type UpdateUserDtoCountry =
  (typeof UpdateUserDtoCountry)[keyof typeof UpdateUserDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUserDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface UpdateUserDto {
  areaId?: number;
  contact_email?: string;
  contact_phone?: string;
  contact_video_details?: string;
  country?: UpdateUserDtoCountry;
  country_ambassador_country?: string;
  degree?: string;
  desired_type_of_contact?: UpdateUserDtoDesiredTypeOfContactItem[];
  expected_graduation_date?: string;
  expected_mark?: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  first_name?: string;
  imageId?: number;
  industryId?: number;
  is_become_professional_notified?: boolean;
  is_chat_blocked?: boolean;
  is_country_ambassador?: boolean;
  is_email_verified?: boolean;
  is_mental_health_mentor?: boolean;
  is_mentee?: boolean;
  is_mentor?: boolean;
  is_private?: boolean;
  is_speaker?: boolean;
  is_subscribed_to_newsletter?: boolean;
  job_title?: string;
  languages?: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  last_name?: string;
  location?: string;
  main_interests?: string;
  mentee_hours_per_week?: number;
  mentee_speciality?: string;
  mentor_hours_per_week?: number;
  mentor_speciality?: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  newPassword?: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  newPasswordConfirmation?: string;
  organization_name?: string;
  pdfId?: number;
  pendingOrganizationId?: number;
  personal_message?: string;
  portfolio_website?: string;
  pronoun?: UpdateUserDtoPronoun;
  pronoun_other?: string;
  school?: string;
  school_website?: string;
  seniority?: UpdateUserDtoSeniority;
  show_on_homepage?: boolean;
  /** @nullable */
  twoFactorPhoneNumber?: string | null;
  userOrganizationRoles?: string[];
  /** @nullable */
  verifiedAt?: string | null;
  video_cv_link?: string;
  volunteer_work?: string;
  years_of_experience?: UpdateUserDtoYearsOfExperience;
}

/**
 * @nullable
 */
export type OrganizationEntityResponseDefaultCountry = {
  [key: string]: any;
} | null;

/**
 * @nullable
 */
export type OrganizationEntityResponseAccountManager = UserEntity | null;

export interface ExternalJobProviderEntity {
  accessToken?: string;
  id: number;
  isActive: boolean;
  name: string;
  organization: OrganizationEntity;
  organizationId: number;
  organizationIdentifier: string;
  password: string;
  username: string;
}

export interface OrganizationEntityResponse {
  /** @nullable */
  account_manager?: OrganizationEntityResponseAccountManager;
  allow_graduate_jobs_in_xml: boolean;
  alternate_names?: string[];
  amount_of_employees: string;
  apply_email?: string;
  area: AreaEntity;
  bottom_link_background_color?: string;
  bottom_link_text?: string;
  bottom_link_text_color?: string;
  bottom_link_url?: string;
  custom_job_description?: string;
  custom_job_position?: string;
  /** @nullable */
  default_country?: OrganizationEntityResponseDefaultCountry;
  externalJobProvider: ExternalJobProviderEntity;
  facebook_url?: string;
  header_link_background_color?: string;
  header_link_text?: string;
  header_link_text_color?: string;
  header_link_url?: string;
  id: number;
  industry: IndustryEntity;
  instagram_url?: string;
  is_apply_via_email: boolean;
  is_featured: boolean;
  is_location_disabled: boolean;
  is_organization_of_the_week: boolean;
  jobs?: JobEntity[];
  jobs_remaining: number;
  linkedin_url?: string;
  membership: MembershipEntity;
  meta_description: string;
  meta_keywords: string;
  meta_title: string;
  name: string;
  organizationAdmins: OrganizationAdminEntity[];
  organizationImage: OrganizationImageEntity[];
  organizationUserRoles: OrganizationUserRoleEntity[];
  owner: number;
  pinterest_url?: string;
  promotion_name?: string;
  promotion_tags?: string[];
  /** @nullable */
  published_at?: string | null;
  reviews?: OrganizationReviewEntity[];
  slug: string;
  twitter_url?: string;
  utm_code?: string;
  youtube_url?: string;
}

export interface OrganizationsResponse {
  data: OrganizationEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type OrganizationsDtoSortOrder =
  (typeof OrganizationsDtoSortOrder)[keyof typeof OrganizationsDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationsDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export type OrganizationsFiltersDtoNameSearchOperation =
  (typeof OrganizationsFiltersDtoNameSearchOperation)[keyof typeof OrganizationsFiltersDtoNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationsFiltersDtoNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type OrganizationsFiltersDtoIndustryOperation =
  (typeof OrganizationsFiltersDtoIndustryOperation)[keyof typeof OrganizationsFiltersDtoIndustryOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationsFiltersDtoIndustryOperation = {
  is_equal_to: "is equal to",
  is_not_equal_to: "is not equal to",
} as const;

export type OrganizationsFiltersDtoEmailSearchOperation =
  (typeof OrganizationsFiltersDtoEmailSearchOperation)[keyof typeof OrganizationsFiltersDtoEmailSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationsFiltersDtoEmailSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export interface OrganizationsFiltersDto {
  email?: string;
  emailSearchOperation?: OrganizationsFiltersDtoEmailSearchOperation;
  industry?: number;
  industryOperation?: OrganizationsFiltersDtoIndustryOperation;
  keyword?: string;
  name?: string;
  nameSearchOperation?: OrganizationsFiltersDtoNameSearchOperation;
  rating?: number;
}

export interface OrganizationsDto {
  filters?: OrganizationsFiltersDto;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: OrganizationsDtoSortOrder;
}

export type DropdownUsersDtoUserType =
  (typeof DropdownUsersDtoUserType)[keyof typeof DropdownUsersDtoUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DropdownUsersDtoUserType = {
  all: "all",
  graduate: "graduate",
  professional: "professional",
} as const;

export type DropdownUsersDtoCountriesItem =
  (typeof DropdownUsersDtoCountriesItem)[keyof typeof DropdownUsersDtoCountriesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DropdownUsersDtoCountriesItem = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface DropdownUsersDto {
  countries?: DropdownUsersDtoCountriesItem[];
  searchTerm?: string;
  userType?: DropdownUsersDtoUserType;
}

export interface UsersResponse {
  data: UsersResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type UsersResponseEntityType =
  (typeof UsersResponseEntityType)[keyof typeof UsersResponseEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersResponseEntityType = {
  student: "student",
  graduate: "graduate",
  professional: "professional",
  admin: "admin",
} as const;

export type UsersResponseEntityPronoun =
  (typeof UsersResponseEntityPronoun)[keyof typeof UsersResponseEntityPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersResponseEntityPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export interface UsersResponseEntity {
  adminUserRole: AdminUserRoleEntity;
  connections: ConnectionEntity;
  createdAt: string;
  email: string;
  first_name: string;
  id: number;
  is_banned: boolean;
  is_chat_blocked: boolean;
  is_private: boolean;
  last_name: string;
  location: LocationEntity;
  message_count: number;
  notificationTokens: UserNotificationTokenEntity[];
  online: boolean;
  organizationAdmin: OrganizationAdminEntity;
  organizationRoles: OrganizationUserRoleEntity[];
  password: string;
  pronoun?: UsersResponseEntityPronoun;
  pronoun_other?: string;
  slug: string;
  timezone?: string;
  type: UsersResponseEntityType;
  userSetting: UserSettingsEntity;
  userTaxonomy: UserTaxonomyEntity;
  uuid: string;
  /** @nullable */
  verifiedAt: string | null;
}

export type UsersDtoSortOrder =
  (typeof UsersDtoSortOrder)[keyof typeof UsersDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsersDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface UsersDto {
  filters?: FiltersDto;
  page?: number;
  pageSize?: number;
  sort_column?: string;
  sort_order?: UsersDtoSortOrder;
}

export type FiltersDtoYearOfExperience =
  (typeof FiltersDtoYearOfExperience)[keyof typeof FiltersDtoYearOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FiltersDtoYearOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type FiltersDtoTypeSearchOperation =
  (typeof FiltersDtoTypeSearchOperation)[keyof typeof FiltersDtoTypeSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FiltersDtoTypeSearchOperation = {
  is_equal_to: "is equal to",
  is_not_equal_to: "is not equal to",
} as const;

export type FiltersDtoType =
  (typeof FiltersDtoType)[keyof typeof FiltersDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FiltersDtoType = {
  student: "student",
  graduate: "graduate",
  professional: "professional",
  admin: "admin",
} as const;

export type FiltersDtoNameSearchOperation =
  (typeof FiltersDtoNameSearchOperation)[keyof typeof FiltersDtoNameSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FiltersDtoNameSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type FiltersDtoEmailSearchOperation =
  (typeof FiltersDtoEmailSearchOperation)[keyof typeof FiltersDtoEmailSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FiltersDtoEmailSearchOperation = {
  contains: "contains",
  does_not_contain: "does not contain",
  is_equal_to: "is equal to",
} as const;

export type FiltersDtoCreatedAtSearchOperation =
  (typeof FiltersDtoCreatedAtSearchOperation)[keyof typeof FiltersDtoCreatedAtSearchOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FiltersDtoCreatedAtSearchOperation = {
  between: "between",
  not_between: "not between",
} as const;

export type FiltersDtoCountry =
  (typeof FiltersDtoCountry)[keyof typeof FiltersDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FiltersDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface CreatedAtDto {
  endDate?: string;
  startDate?: string;
}

export interface FiltersDto {
  country?: FiltersDtoCountry;
  createdAt?: CreatedAtDto;
  createdAtSearchOperation?: FiltersDtoCreatedAtSearchOperation;
  email?: string;
  emailSearchOperation?: FiltersDtoEmailSearchOperation;
  isChatBlocked?: boolean;
  isEmailVerified?: boolean;
  isMentor?: boolean;
  isOnboarded?: boolean;
  isSpeaker?: boolean;
  keyword?: string;
  name?: string;
  nameSearchOperation?: FiltersDtoNameSearchOperation;
  organizationId?: number;
  type?: FiltersDtoType;
  typeSearchOperation?: FiltersDtoTypeSearchOperation;
  yearOfExperience?: FiltersDtoYearOfExperience;
}

export interface ImpersonateOrganizationDto {
  id: string;
}

export interface ImpersonateResponse {
  success: boolean;
  token: string;
}

export interface ImpersonateUserDto {
  email: string;
}

export type AdminLoginResponseVerifiedAt = { [key: string]: any };

export type AdminLoginResponseRole =
  (typeof AdminLoginResponseRole)[keyof typeof AdminLoginResponseRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminLoginResponseRole = {
  Content_Editor: "Content Editor",
  "Marketing/Sales": "Marketing/Sales",
  Impersonation: "Impersonation",
  Account_Manager: "Account Manager",
  Admin: "Admin",
  Super_Admin: "Super Admin",
} as const;

export type AdminLoginResponsePronoun =
  (typeof AdminLoginResponsePronoun)[keyof typeof AdminLoginResponsePronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminLoginResponsePronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export interface AdminLoginResponse {
  accessToken: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  permissions: AdminPermissionEntity[];
  pronoun: AdminLoginResponsePronoun;
  refreshToken: string;
  role: AdminLoginResponseRole;
  slug: string;
  userTaxonomy: UserTaxonomyEntity;
  verifiedAt: AdminLoginResponseVerifiedAt;
}

export type AdminRolePermissionsResponseRole =
  (typeof AdminRolePermissionsResponseRole)[keyof typeof AdminRolePermissionsResponseRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminRolePermissionsResponseRole = {
  Content_Editor: "Content Editor",
  "Marketing/Sales": "Marketing/Sales",
  Impersonation: "Impersonation",
  Account_Manager: "Account Manager",
  Admin: "Admin",
  Super_Admin: "Super Admin",
} as const;

export type AdminPermissionEntityPermission =
  (typeof AdminPermissionEntityPermission)[keyof typeof AdminPermissionEntityPermission];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminPermissionEntityPermission = {
  Manage_Users: "Manage Users",
  Edit_User: "Edit User",
  Impersonate_User: "Impersonate User",
  Delete_User: "Delete User",
  Block_User: "Block User",
  Download_User: "Download User",
  Manage_Organizations: "Manage Organizations",
  Create_Organization: "Create Organization",
  Edit_Organization: "Edit Organization",
  Impersonate_Organization: "Impersonate Organization",
  Delete_Organization: "Delete Organization",
  Manage_Admins: "Manage Admins",
  Create_Admin: "Create Admin",
  Edit_Admin: "Edit Admin",
  Delete_Admin: "Delete Admin",
  Manage_Leads: "Manage Leads",
  Download_Lead: "Download Lead",
  Manage_Jobs: "Manage Jobs",
  Create_Job: "Create Job",
  Moderation: "Moderation",
  Delete_Reviews: "Delete Reviews",
  Ban_User: "Ban User",
  Impersonate_Reported_User: "Impersonate Reported User",
  Manage_Reports: "Manage Reports",
  View_Report: "View Report",
  Download_Report: "Download Report",
  Manage_Files: "Manage Files",
  Create_a_Folder: "Create a Folder",
  Share_a_File: "Share a File",
  Make_Public: "Make Public",
  "Delete_File/Folder": "Delete File/Folder",
  Manage_Push_Notifications: "Manage Push Notifications",
  Send_Push_Notification: "Send Push Notification",
  Organization_Sources: "Organization Sources",
  Jobs_Scrapers: "Jobs Scrapers",
} as const;

export interface AdminPermissionEntity {
  createdBy: UserEntity;
  id: number;
  permission: AdminPermissionEntityPermission;
}

export interface AdminRolePermissionsResponse {
  id: number;
  permissions: AdminPermissionEntity[];
  role: AdminRolePermissionsResponseRole;
}

export interface AdminMetadataResponse {
  accountManagers: UserEntity[];
  adminUsers: UserEntity[];
  areas: AreaEntity[];
  countries: IdNamePair[];
  industries: IndustryEntity[];
  organizationRoles: string[];
  organizations: IdNamePair[];
  permissions: AdminPermissionEntity[];
  roles: AdminRoleEntity[];
  rolesPermissions: AdminRolePermissionsResponse[];
  yearsOfExperience: IdNamePair[];
}

export interface NotificationInteractionEntity {
  clickedAt?: string;
  id: number;
  notification: NotificationLogEntity;
  user: UserEntity;
  viewedAt: string;
}

export type NotificationLogEntityUserType =
  (typeof NotificationLogEntityUserType)[keyof typeof NotificationLogEntityUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogEntityUserType = {
  all: "all",
  graduate: "graduate",
  professional: "professional",
} as const;

export type NotificationLogEntityUserPreference =
  (typeof NotificationLogEntityUserPreference)[keyof typeof NotificationLogEntityUserPreference];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogEntityUserPreference = {
  Subscribed_Users: "Subscribed Users",
  All_Users: "All Users",
  Specific_Users: "Specific Users",
} as const;

export type NotificationLogEntityType =
  (typeof NotificationLogEntityType)[keyof typeof NotificationLogEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogEntityType = {
  Email: "Email",
  Push: "Push",
  Friend_Request: "Friend Request",
  Friend_Request_Accepted: "Friend Request Accepted",
  Accepted_Join_Organization_Request: "Accepted Join Organization Request",
  Rejected_Join_Organization_Request: "Rejected Join Organization Request",
  Accepted_Organization_Invitation: "Accepted Organization Invitation",
  User_Profile_Viewed: "User Profile Viewed",
} as const;

export type NotificationLogEntityLinkType =
  (typeof NotificationLogEntityLinkType)[keyof typeof NotificationLogEntityLinkType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationLogEntityLinkType = {
  None: "None",
  Article: "Article",
  Event: "Event",
  External_URL: "External URL",
} as const;

export interface NotificationLogEntity {
  body: string;
  countries?: string[];
  createdAt: string;
  externalUrl?: string;
  id: number;
  interactions: NotificationInteractionEntity;
  linkType: NotificationLogEntityLinkType;
  postName?: string;
  postSlug?: string;
  sentBy: UserEntity;
  title: string;
  type: NotificationLogEntityType;
  userIds?: number[];
  userPreference?: NotificationLogEntityUserPreference;
  userType: NotificationLogEntityUserType;
}

export type CreateNotificationTokenDtoDeviceInfo = { [key: string]: any };

export interface CreateNotificationTokenDto {
  deviceInfo?: CreateNotificationTokenDtoDeviceInfo;
  deviceName?: string;
  token: string;
}

export type MessageAttachmentFileType =
  (typeof MessageAttachmentFileType)[keyof typeof MessageAttachmentFileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageAttachmentFileType = {
  "image/jpeg": "image/jpeg",
  "image/png": "image/png",
  "image/gif": "image/gif",
  "image/svg+xml": "image/svg+xml",
  "image/webp": "image/webp",
  "video/mp4": "video/mp4",
  "video/quicktime": "video/quicktime",
  "application/pdf": "application/pdf",
  "application/msword": "application/msword",
  "application/vndopenxmlformats-officedocumentwordprocessingmldocument":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vndms-excel": "application/vnd.ms-excel",
  "application/vndopenxmlformats-officedocumentspreadsheetmlsheet":
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vndms-powerpoint": "application/vnd.ms-powerpoint",
  "application/vndopenxmlformats-officedocumentpresentationmlpresentation":
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/csv": "text/csv",
  "audio/mpeg": "audio/mpeg",
} as const;

export interface MessageAttachment {
  fileType: MessageAttachmentFileType;
  name: string;
  original_name: string;
}

export interface ReplyMessageDto {
  attachment?: MessageAttachment;
  content?: string;
}

export interface CreateMessageDto {
  attachments?: MessageAttachment[];
  content?: string;
  conversationId?: number;
  recipientIds?: number[];
}

export interface ConversationDto {
  recipientIds: number[];
}

export interface ConversationMessagesResponse {
  data: ConversationMessageEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export interface ConversationMessagesDto {
  conversationId?: number;
  page?: number;
  pageSize?: number;
  recipientIds?: number[];
}

export interface MessageAttachmentEntity {
  asset: AssetEntity;
  id: number;
  message: ConversationMessageEntity;
}

/**
 * @nullable
 */
export type MessageReadRecipientEntityLastReadMessage =
  ConversationMessageEntity | null;

export type ConversationRecipientEntityType =
  (typeof ConversationRecipientEntityType)[keyof typeof ConversationRecipientEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConversationRecipientEntityType = {
  admin: "admin",
} as const;

export interface ConversationRecipientEntity {
  conversation: ConversationEntity;
  id: number;
  type: ConversationRecipientEntityType;
  user: UserEntity;
}

export interface MessageReadRecipientEntity {
  conversation: ConversationEntity;
  id: number;
  /** @nullable */
  lastReadMessage: MessageReadRecipientEntityLastReadMessage;
  recipient: ConversationRecipientEntity;
}

export interface ConversationDeletionsEntity {
  conversation: ConversationEntity;
  id: number;
  markedDeletedAt: string;
  user: UserEntity;
}

export interface ConversationResponseEntity {
  conversationDeletions: ConversationDeletionsEntity[];
  conversationMessages: ConversationMessageEntity[];
  conversationRecipients: ConversationRecipientEntity[];
  id: number;
  is_group: boolean;
  isBlocked?: boolean;
  isUnread?: boolean;
  lastMessage: ConversationMessageEntity;
  messageReadRecipients: MessageReadRecipientEntity[];
  name: string;
}

export interface ConversationResponse {
  data: ConversationResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export interface ConversationEntity {
  conversationDeletions: ConversationDeletionsEntity[];
  conversationMessages: ConversationMessageEntity[];
  conversationRecipients: ConversationRecipientEntity[];
  id: number;
  is_group: boolean;
  lastMessage: ConversationMessageEntity;
  messageReadRecipients: MessageReadRecipientEntity[];
  name: string;
}

export interface ConversationMessageEntity {
  content: string;
  conversation: ConversationEntity;
  createdAt: string;
  id: number;
  messageAttachments: MessageAttachmentEntity[];
  reply_of_message: ConversationMessageEntity;
  sender_user: UserEntity;
}

export interface FavouriteToggleDto {
  jobSlug: string;
}

export type UpdateJobDtoYearsOfExperience =
  (typeof UpdateJobDtoYearsOfExperience)[keyof typeof UpdateJobDtoYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateJobDtoYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type UpdateJobDtoTypeOfContract =
  (typeof UpdateJobDtoTypeOfContract)[keyof typeof UpdateJobDtoTypeOfContract];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateJobDtoTypeOfContract = {
  "Full-time": "Full-time",
  Contract: "Contract",
  "Part_time/Temporary": "Part time/Temporary",
} as const;

export type UpdateJobDtoType =
  (typeof UpdateJobDtoType)[keyof typeof UpdateJobDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateJobDtoType = {
  remote: "remote",
  onsite: "onsite",
  hybrid: "hybrid",
} as const;

export type UpdateJobDtoSalaryType =
  (typeof UpdateJobDtoSalaryType)[keyof typeof UpdateJobDtoSalaryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateJobDtoSalaryType = {
  daily: "daily",
  weekly: "weekly",
  bi_weekly: "bi_weekly",
  monthly: "monthly",
  annually: "annually",
} as const;

export type UpdateJobDtoSalaryCurrency =
  (typeof UpdateJobDtoSalaryCurrency)[keyof typeof UpdateJobDtoSalaryCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateJobDtoSalaryCurrency = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  FOK: "FOK",
  GBP: "GBP",
  GEL: "GEL",
  GGP: "GGP",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  IMP: "IMP",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JEP: "JEP",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KID: "KID",
  KMF: "KMF",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TVD: "TVD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XCD: "XCD",
  XOF: "XOF",
  XPF: "XPF",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
} as const;

export type UpdateJobDtoPosition =
  (typeof UpdateJobDtoPosition)[keyof typeof UpdateJobDtoPosition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateJobDtoPosition = {
  internship: "internship",
  entry_level: "entry_level",
  experienced_professional: "experienced_professional",
} as const;

export type UpdateJobDtoLevel =
  (typeof UpdateJobDtoLevel)[keyof typeof UpdateJobDtoLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateJobDtoLevel = {
  Graduates: "Graduates",
  Professionals: "Professionals",
} as const;

export type UpdateJobDtoCountry =
  (typeof UpdateJobDtoCountry)[keyof typeof UpdateJobDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateJobDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface UpdateJobDto {
  apply_url?: string;
  areaId?: number;
  country?: UpdateJobDtoCountry;
  date_expired?: string;
  description?: string;
  industryId?: number;
  is_multiple_candidates?: boolean;
  level?: UpdateJobDtoLevel;
  location?: string;
  position?: UpdateJobDtoPosition;
  salary_currency?: UpdateJobDtoSalaryCurrency;
  salary_from?: number;
  salary_to?: number;
  salary_type?: UpdateJobDtoSalaryType;
  /**
   * @minLength 2
   * @maxLength 50
   */
  title?: string;
  type?: UpdateJobDtoType;
  type_of_contract?: UpdateJobDtoTypeOfContract;
  years_of_experience?: UpdateJobDtoYearsOfExperience;
}

export interface UserFavouriteJobResponseEntity {
  id: number;
  job: JobResponseEntity;
  user: UserEntity;
}

export interface UserFavouriteJobResponse {
  data: UserFavouriteJobResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export interface FavouriteJobsDto {
  page?: number;
  pageSize?: number;
}

export interface MemberDashboardJobDto {
  page?: number;
  pageSize?: number;
}

export interface EarlyCareerAndApprenticeJobDto {
  organizationId?: number;
  organizationSlug?: string;
  page: number;
  pageSize?: number;
  userId?: number;
}

export interface JobResponseEntity {
  apply_url?: string;
  area: AreaEntity;
  createdAt: string;
  date_expired?: string;
  deleteCounter: number;
  deletedAt: string;
  description?: string;
  external_reference_id?: string;
  featured_at?: string;
  id: number;
  industry: IndustryEntity;
  is_auto_featured: boolean;
  is_favourite: boolean;
  is_multiple_candidates: boolean;
  level: JobResponseEntityLevel;
  locations: LocationEntity;
  made_manual_at?: string;
  organization: OrganizationEntity;
  position: JobResponseEntityPosition;
  salary_currency: JobResponseEntitySalaryCurrency;
  salary_from: number;
  salary_to: number;
  salary_type: JobResponseEntitySalaryType;
  slug: string;
  source?: string;
  source_internal_id?: string;
  timezone: JobResponseEntityTimezone;
  title: string;
  /** @nullable */
  type: JobResponseEntityType;
  type_of_contract: JobResponseEntityTypeOfContract;
  user: UserEntity;
  userSavedJobs?: UserSavedJobEntity[];
  years_of_experience: JobResponseEntityYearsOfExperience;
}

export interface JobResponse {
  data: JobResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type JobResponseEntityYearsOfExperience =
  (typeof JobResponseEntityYearsOfExperience)[keyof typeof JobResponseEntityYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseEntityYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type JobResponseEntityTypeOfContract =
  (typeof JobResponseEntityTypeOfContract)[keyof typeof JobResponseEntityTypeOfContract];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseEntityTypeOfContract = {
  "Full-time": "Full-time",
  Contract: "Contract",
  "Part_time/Temporary": "Part time/Temporary",
} as const;

/**
 * @nullable
 */
export type JobResponseEntityType =
  | (typeof JobResponseEntityType)[keyof typeof JobResponseEntityType]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseEntityType = {
  remote: "remote",
  onsite: "onsite",
  hybrid: "hybrid",
} as const;

export type JobResponseEntityTimezone =
  (typeof JobResponseEntityTimezone)[keyof typeof JobResponseEntityTimezone];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseEntityTimezone = {
  GMT: "GMT",
  PST: "PST",
} as const;

export type JobResponseEntitySalaryType =
  (typeof JobResponseEntitySalaryType)[keyof typeof JobResponseEntitySalaryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseEntitySalaryType = {
  daily: "daily",
  weekly: "weekly",
  bi_weekly: "bi_weekly",
  monthly: "monthly",
  annually: "annually",
} as const;

export type JobResponseEntitySalaryCurrency =
  (typeof JobResponseEntitySalaryCurrency)[keyof typeof JobResponseEntitySalaryCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseEntitySalaryCurrency = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  FOK: "FOK",
  GBP: "GBP",
  GEL: "GEL",
  GGP: "GGP",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  IMP: "IMP",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JEP: "JEP",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KID: "KID",
  KMF: "KMF",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TVD: "TVD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XCD: "XCD",
  XOF: "XOF",
  XPF: "XPF",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
} as const;

export type JobResponseEntityPosition =
  (typeof JobResponseEntityPosition)[keyof typeof JobResponseEntityPosition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseEntityPosition = {
  internship: "internship",
  entry_level: "entry_level",
  experienced_professional: "experienced_professional",
} as const;

export type JobResponseEntityLevel =
  (typeof JobResponseEntityLevel)[keyof typeof JobResponseEntityLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobResponseEntityLevel = {
  Graduates: "Graduates",
  Professionals: "Professionals",
} as const;

export interface SearchJobDto {
  country?: string;
  excludeEarlyCareerAndApprenticeshipJobs?: boolean;
  isRemote?: boolean;
  location?: string;
  organizationId?: number;
  organizationSlug?: string;
  page: number;
  pageSize?: number;
  searchTerm?: string;
  userId?: number;
}

export interface JobMetadata {
  areas: AreaEntity[];
  countries: IdNamePair[];
  industries: IndustryEntity[];
  jobLevel: IdNamePair[];
  jobPosition: IdNamePair[];
  organizations: IdNamePair[];
  typeOfContract: IdNamePair[];
  yearsOfExperience: IdNamePair[];
}

export interface RemoveScrapedJobDto {
  isImmediateDelete: boolean;
  jobIds: string[];
  organizationId: number;
}

export type CreateScraperJobDtoTypeOfContract =
  (typeof CreateScraperJobDtoTypeOfContract)[keyof typeof CreateScraperJobDtoTypeOfContract];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScraperJobDtoTypeOfContract = {
  "Full-time": "Full-time",
  Contract: "Contract",
  "Part_time/Temporary": "Part time/Temporary",
} as const;

export type CreateScraperJobDtoType =
  (typeof CreateScraperJobDtoType)[keyof typeof CreateScraperJobDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScraperJobDtoType = {
  remote: "remote",
  onsite: "onsite",
  hybrid: "hybrid",
} as const;

export type CreateScraperJobDtoTimezone =
  (typeof CreateScraperJobDtoTimezone)[keyof typeof CreateScraperJobDtoTimezone];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScraperJobDtoTimezone = {
  GMT: "GMT",
  PST: "PST",
} as const;

export type CreateScraperJobDtoSalaryType =
  (typeof CreateScraperJobDtoSalaryType)[keyof typeof CreateScraperJobDtoSalaryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScraperJobDtoSalaryType = {
  daily: "daily",
  weekly: "weekly",
  bi_weekly: "bi_weekly",
  monthly: "monthly",
  annually: "annually",
} as const;

export type CreateScraperJobDtoSalaryCurrency =
  (typeof CreateScraperJobDtoSalaryCurrency)[keyof typeof CreateScraperJobDtoSalaryCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScraperJobDtoSalaryCurrency = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  FOK: "FOK",
  GBP: "GBP",
  GEL: "GEL",
  GGP: "GGP",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  IMP: "IMP",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JEP: "JEP",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KID: "KID",
  KMF: "KMF",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TVD: "TVD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XCD: "XCD",
  XOF: "XOF",
  XPF: "XPF",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
} as const;

export type CreateScraperJobDtoPosition =
  (typeof CreateScraperJobDtoPosition)[keyof typeof CreateScraperJobDtoPosition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScraperJobDtoPosition = {
  internship: "internship",
  entry_level: "entry_level",
  experienced_professional: "experienced_professional",
} as const;

export type CreateScraperJobDtoLevel =
  (typeof CreateScraperJobDtoLevel)[keyof typeof CreateScraperJobDtoLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScraperJobDtoLevel = {
  Graduates: "Graduates",
  Professionals: "Professionals",
} as const;

export type CreateScraperJobDtoCountry =
  (typeof CreateScraperJobDtoCountry)[keyof typeof CreateScraperJobDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScraperJobDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface CreateScraperJobDto {
  apply_url?: string;
  areaName: string;
  country?: CreateScraperJobDtoCountry;
  date_expired?: string;
  description: string;
  external_reference_id?: string;
  featured_at?: string;
  industryName: string;
  is_auto_featured?: boolean;
  is_multiple_candidates?: boolean;
  level?: CreateScraperJobDtoLevel;
  location: string;
  made_manual_at?: string;
  organizationId: number;
  position?: CreateScraperJobDtoPosition;
  salary_currency?: CreateScraperJobDtoSalaryCurrency;
  salary_from?: number;
  salary_to?: number;
  salary_type?: CreateScraperJobDtoSalaryType;
  source: string;
  source_internal_id: string;
  timezone?: CreateScraperJobDtoTimezone;
  title: string;
  type?: CreateScraperJobDtoType;
  type_of_contract: CreateScraperJobDtoTypeOfContract;
}

export type CreateJobDtoYearsOfExperience =
  (typeof CreateJobDtoYearsOfExperience)[keyof typeof CreateJobDtoYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateJobDtoYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type CreateJobDtoTypeOfContract =
  (typeof CreateJobDtoTypeOfContract)[keyof typeof CreateJobDtoTypeOfContract];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateJobDtoTypeOfContract = {
  "Full-time": "Full-time",
  Contract: "Contract",
  "Part_time/Temporary": "Part time/Temporary",
} as const;

export type CreateJobDtoType =
  (typeof CreateJobDtoType)[keyof typeof CreateJobDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateJobDtoType = {
  remote: "remote",
  onsite: "onsite",
  hybrid: "hybrid",
} as const;

export type CreateJobDtoSalaryType =
  (typeof CreateJobDtoSalaryType)[keyof typeof CreateJobDtoSalaryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateJobDtoSalaryType = {
  daily: "daily",
  weekly: "weekly",
  bi_weekly: "bi_weekly",
  monthly: "monthly",
  annually: "annually",
} as const;

export type CreateJobDtoSalaryCurrency =
  (typeof CreateJobDtoSalaryCurrency)[keyof typeof CreateJobDtoSalaryCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateJobDtoSalaryCurrency = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  FOK: "FOK",
  GBP: "GBP",
  GEL: "GEL",
  GGP: "GGP",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  IMP: "IMP",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JEP: "JEP",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KID: "KID",
  KMF: "KMF",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TVD: "TVD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XCD: "XCD",
  XOF: "XOF",
  XPF: "XPF",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
} as const;

export type CreateJobDtoPosition =
  (typeof CreateJobDtoPosition)[keyof typeof CreateJobDtoPosition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateJobDtoPosition = {
  internship: "internship",
  entry_level: "entry_level",
  experienced_professional: "experienced_professional",
} as const;

export type CreateJobDtoLevel =
  (typeof CreateJobDtoLevel)[keyof typeof CreateJobDtoLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateJobDtoLevel = {
  Graduates: "Graduates",
  Professionals: "Professionals",
} as const;

export type CreateJobDtoCountry =
  (typeof CreateJobDtoCountry)[keyof typeof CreateJobDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateJobDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface CreateJobDto {
  apply_url?: string;
  areaId: number;
  country: CreateJobDtoCountry;
  date_expired: string;
  description: string;
  industryId: number;
  is_multiple_candidates?: boolean;
  level: CreateJobDtoLevel;
  location: string;
  position: CreateJobDtoPosition;
  salary_currency?: CreateJobDtoSalaryCurrency;
  salary_from?: number;
  salary_to?: number;
  salary_type?: CreateJobDtoSalaryType;
  /**
   * @minLength 2
   * @maxLength 50
   */
  title: string;
  type?: CreateJobDtoType;
  type_of_contract: CreateJobDtoTypeOfContract;
  years_of_experience: CreateJobDtoYearsOfExperience;
}

export type UploadFileBase64DtoFileType =
  (typeof UploadFileBase64DtoFileType)[keyof typeof UploadFileBase64DtoFileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadFileBase64DtoFileType = {
  "image/jpeg": "image/jpeg",
  "image/png": "image/png",
  "image/gif": "image/gif",
  "image/svg+xml": "image/svg+xml",
  "image/webp": "image/webp",
  "video/mp4": "video/mp4",
  "video/quicktime": "video/quicktime",
  "application/pdf": "application/pdf",
  "application/msword": "application/msword",
  "application/vndopenxmlformats-officedocumentwordprocessingmldocument":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vndms-excel": "application/vnd.ms-excel",
  "application/vndopenxmlformats-officedocumentspreadsheetmlsheet":
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vndms-powerpoint": "application/vnd.ms-powerpoint",
  "application/vndopenxmlformats-officedocumentpresentationmlpresentation":
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/csv": "text/csv",
  "audio/mpeg": "audio/mpeg",
} as const;

export interface UploadFileBase64Dto {
  base64: string;
  fileType: UploadFileBase64DtoFileType;
}

export type CreateAssetBase64DtoType =
  (typeof CreateAssetBase64DtoType)[keyof typeof CreateAssetBase64DtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAssetBase64DtoType = {
  banner: "banner",
  logo: "logo",
  resume: "resume",
  profile: "profile",
  message_attachment: "message_attachment",
  corporate_partners: "corporate_partners",
  inclusive_partners: "inclusive_partners",
  community_partners: "community_partners",
  media_partners: "media_partners",
  brochure: "brochure",
  organization_shared_files: "organization_shared_files",
  organization_gallery_images: "organization_gallery_images",
} as const;

export type CreateAssetBase64DtoFileType =
  (typeof CreateAssetBase64DtoFileType)[keyof typeof CreateAssetBase64DtoFileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAssetBase64DtoFileType = {
  "image/jpeg": "image/jpeg",
  "image/png": "image/png",
  "image/gif": "image/gif",
  "image/svg+xml": "image/svg+xml",
  "image/webp": "image/webp",
  "video/mp4": "video/mp4",
  "video/quicktime": "video/quicktime",
  "application/pdf": "application/pdf",
  "application/msword": "application/msword",
  "application/vndopenxmlformats-officedocumentwordprocessingmldocument":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vndms-excel": "application/vnd.ms-excel",
  "application/vndopenxmlformats-officedocumentspreadsheetmlsheet":
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vndms-powerpoint": "application/vnd.ms-powerpoint",
  "application/vndopenxmlformats-officedocumentpresentationmlpresentation":
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/csv": "text/csv",
  "audio/mpeg": "audio/mpeg",
} as const;

export type CreateAssetBase64DtoEntityType =
  (typeof CreateAssetBase64DtoEntityType)[keyof typeof CreateAssetBase64DtoEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAssetBase64DtoEntityType = {
  organizations: "organizations",
  jobs: "jobs",
  users: "users",
  analytics: "analytics",
  conversation_messages: "conversation_messages",
  workpride: "workpride",
  organization_shared_files: "organization_shared_files",
} as const;

export interface CreateAssetBase64Dto {
  base64: string;
  entity_id: string;
  entity_type: CreateAssetBase64DtoEntityType;
  fileType: CreateAssetBase64DtoFileType;
  original_name: string;
  type: CreateAssetBase64DtoType;
}

export type CreateAssetDtoType =
  (typeof CreateAssetDtoType)[keyof typeof CreateAssetDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAssetDtoType = {
  banner: "banner",
  logo: "logo",
  resume: "resume",
  profile: "profile",
  message_attachment: "message_attachment",
  corporate_partners: "corporate_partners",
  inclusive_partners: "inclusive_partners",
  community_partners: "community_partners",
  media_partners: "media_partners",
  brochure: "brochure",
  organization_shared_files: "organization_shared_files",
  organization_gallery_images: "organization_gallery_images",
} as const;

export type CreateAssetDtoFileType =
  (typeof CreateAssetDtoFileType)[keyof typeof CreateAssetDtoFileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAssetDtoFileType = {
  "image/jpeg": "image/jpeg",
  "image/png": "image/png",
  "image/gif": "image/gif",
  "image/svg+xml": "image/svg+xml",
  "image/webp": "image/webp",
  "video/mp4": "video/mp4",
  "video/quicktime": "video/quicktime",
  "application/pdf": "application/pdf",
  "application/msword": "application/msword",
  "application/vndopenxmlformats-officedocumentwordprocessingmldocument":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vndms-excel": "application/vnd.ms-excel",
  "application/vndopenxmlformats-officedocumentspreadsheetmlsheet":
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vndms-powerpoint": "application/vnd.ms-powerpoint",
  "application/vndopenxmlformats-officedocumentpresentationmlpresentation":
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/csv": "text/csv",
  "audio/mpeg": "audio/mpeg",
} as const;

export type CreateAssetDtoEntityType =
  (typeof CreateAssetDtoEntityType)[keyof typeof CreateAssetDtoEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAssetDtoEntityType = {
  organizations: "organizations",
  jobs: "jobs",
  users: "users",
  analytics: "analytics",
  conversation_messages: "conversation_messages",
  workpride: "workpride",
  organization_shared_files: "organization_shared_files",
} as const;

export interface CreateAssetDto {
  entity_id: string;
  entity_type: CreateAssetDtoEntityType;
  fileType: CreateAssetDtoFileType;
  original_name: string;
  type: CreateAssetDtoType;
}

export type AnalyticsEntityType =
  (typeof AnalyticsEntityType)[keyof typeof AnalyticsEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnalyticsEntityType = {
  job_apply_btn: "job_apply_btn",
  job_listing: "job_listing",
  user_profile: "user_profile",
} as const;

export type AnalyticsEntityEntityType =
  (typeof AnalyticsEntityEntityType)[keyof typeof AnalyticsEntityEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnalyticsEntityEntityType = {
  organizations: "organizations",
  jobs: "jobs",
  users: "users",
  analytics: "analytics",
  conversation_messages: "conversation_messages",
  workpride: "workpride",
  organization_shared_files: "organization_shared_files",
} as const;

export type AnalyticsEntityAction =
  (typeof AnalyticsEntityAction)[keyof typeof AnalyticsEntityAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnalyticsEntityAction = {
  click: "click",
  views: "views",
} as const;

export interface AnalyticsEntity {
  action: AnalyticsEntityAction;
  entity_id: number;
  entity_type: AnalyticsEntityEntityType;
  id: number;
  ip_address?: string;
  location: LocationEntity;
  type: AnalyticsEntityType;
  user: UserEntity;
  user_agent_browser?: string;
  user_agent_browser_version?: string;
  user_agent_device_type?: string;
  user_agent_operating_system?: string;
  user_agent_operating_system_version?: string;
}

export type CreateAnalyticsDtoType =
  (typeof CreateAnalyticsDtoType)[keyof typeof CreateAnalyticsDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAnalyticsDtoType = {
  job_apply_btn: "job_apply_btn",
  job_listing: "job_listing",
  user_profile: "user_profile",
} as const;

export type CreateAnalyticsDtoEntityType =
  (typeof CreateAnalyticsDtoEntityType)[keyof typeof CreateAnalyticsDtoEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAnalyticsDtoEntityType = {
  organizations: "organizations",
  jobs: "jobs",
  users: "users",
  analytics: "analytics",
  conversation_messages: "conversation_messages",
  workpride: "workpride",
  organization_shared_files: "organization_shared_files",
} as const;

export type CreateAnalyticsDtoAction =
  (typeof CreateAnalyticsDtoAction)[keyof typeof CreateAnalyticsDtoAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAnalyticsDtoAction = {
  click: "click",
  views: "views",
} as const;

export interface CreateAnalyticsDto {
  action: CreateAnalyticsDtoAction;
  entity_id: number;
  entity_type: CreateAnalyticsDtoEntityType;
  type: CreateAnalyticsDtoType;
  userId?: number;
}

export interface UpdateFileDto {
  is_public: boolean;
}

export interface CreateFileDto {
  folderId: number;
  is_public: boolean;
}

export interface UpdateFolderDto {
  is_public?: boolean;
  name?: string;
}

export interface OrganizationSharedFileEntity {
  asset: AssetEntity;
  createdAt: string;
  file_name: string;
  files: OrganizationSharedFileEntity[];
  folder: OrganizationSharedFileEntity;
  id: number;
  is_file: boolean;
  is_public: boolean;
}

export interface CreateFolderDto {
  is_public: boolean;
  name: string;
}

export interface OrganizationReviewResponse {
  data: OrganizationReviewEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type FindAllReviewDtoSortOrder =
  (typeof FindAllReviewDtoSortOrder)[keyof typeof FindAllReviewDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FindAllReviewDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface FindAllReviewDto {
  organizationSlug: string;
  page?: number;
  pageSize?: number;
  sortOrder?: FindAllReviewDtoSortOrder;
}

export interface UpdateReviewDto {
  is_anonymous_to_organizations?: boolean;
  organizationSlug?: string;
  /**
   * @minimum 1
   * @maximum 5
   */
  rating?: number;
  review?: string;
}

export interface CreateReviewDto {
  is_anonymous_to_organizations?: boolean;
  organizationSlug: string;
  /**
   * @minimum 1
   * @maximum 5
   */
  rating?: number;
  review?: string;
}

export type OrganizationInquiryEntityVacancies =
  (typeof OrganizationInquiryEntityVacancies)[keyof typeof OrganizationInquiryEntityVacancies];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryEntityVacancies = {
  Up_to_5: "Up to 5",
  Up_to_25: "Up to 25",
  Up_to_50: "Up to 50",
  More_than_50: "More than 50",
} as const;

export type OrganizationInquiryEntityReachTo =
  (typeof OrganizationInquiryEntityReachTo)[keyof typeof OrganizationInquiryEntityReachTo];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryEntityReachTo = {
  All: "All",
  Graduates: "Graduates",
  Professionals: "Professionals",
} as const;

export type OrganizationInquiryEntityNumberOfEmployees =
  (typeof OrganizationInquiryEntityNumberOfEmployees)[keyof typeof OrganizationInquiryEntityNumberOfEmployees];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryEntityNumberOfEmployees = {
  "0-500": "0-500",
  "501-1000": "501-1000",
  "1001-5000": "1001-5000",
  "5001_and_above": "5001 and above",
} as const;

export type OrganizationInquiryEntityInitiatives =
  (typeof OrganizationInquiryEntityInitiatives)[keyof typeof OrganizationInquiryEntityInitiatives];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryEntityInitiatives = {
  Yes: "Yes",
  No: "No",
} as const;

export type OrganizationInquiryEntityHearFormUs =
  (typeof OrganizationInquiryEntityHearFormUs)[keyof typeof OrganizationInquiryEntityHearFormUs];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryEntityHearFormUs = {
  "Search_engine_(Google,_Yahoo,_etc)": "Search engine (Google, Yahoo, etc.)",
  Recommended_by_a_friend_or_colleague: "Recommended by a friend or colleague",
  Social_Media: "Social Media",
  Blog_or_publication: "Blog or publication",
  Exhibition_or_event: "Exhibition or event",
  Other: "Other",
} as const;

export type OrganizationInquiryEntityDiverseTalent =
  (typeof OrganizationInquiryEntityDiverseTalent)[keyof typeof OrganizationInquiryEntityDiverseTalent];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryEntityDiverseTalent = {
  Yes: "Yes",
  No: "No",
} as const;

export type OrganizationInquiryEntityCountry =
  (typeof OrganizationInquiryEntityCountry)[keyof typeof OrganizationInquiryEntityCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryEntityCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export type OrganizationInquiryEntityAdvertiseEvents =
  (typeof OrganizationInquiryEntityAdvertiseEvents)[keyof typeof OrganizationInquiryEntityAdvertiseEvents];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInquiryEntityAdvertiseEvents = {
  Yes: "Yes",
  No: "No",
} as const;

export interface OrganizationInquiryEntity {
  advertise_events: OrganizationInquiryEntityAdvertiseEvents;
  contact_number: string;
  country: OrganizationInquiryEntityCountry;
  createdAt: string;
  diverse_talent: OrganizationInquiryEntityDiverseTalent;
  email: string;
  first_name: string;
  hear_form_us?: OrganizationInquiryEntityHearFormUs;
  id: number;
  initiatives: OrganizationInquiryEntityInitiatives;
  job_title: string;
  last_name: string;
  location?: string;
  number_of_employees: OrganizationInquiryEntityNumberOfEmployees;
  organization_name: string;
  reach_to: OrganizationInquiryEntityReachTo;
  status?: string;
  vacancies: OrganizationInquiryEntityVacancies;
}

export type CreateOrganizationInquiryDtoVacancies =
  (typeof CreateOrganizationInquiryDtoVacancies)[keyof typeof CreateOrganizationInquiryDtoVacancies];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrganizationInquiryDtoVacancies = {
  Up_to_5: "Up to 5",
  Up_to_25: "Up to 25",
  Up_to_50: "Up to 50",
  More_than_50: "More than 50",
} as const;

export type CreateOrganizationInquiryDtoReachTo =
  (typeof CreateOrganizationInquiryDtoReachTo)[keyof typeof CreateOrganizationInquiryDtoReachTo];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrganizationInquiryDtoReachTo = {
  All: "All",
  Graduates: "Graduates",
  Professionals: "Professionals",
} as const;

export type CreateOrganizationInquiryDtoNumberOfEmployees =
  (typeof CreateOrganizationInquiryDtoNumberOfEmployees)[keyof typeof CreateOrganizationInquiryDtoNumberOfEmployees];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrganizationInquiryDtoNumberOfEmployees = {
  "0-500": "0-500",
  "501-1000": "501-1000",
  "1001-5000": "1001-5000",
  "5001_and_above": "5001 and above",
} as const;

export type CreateOrganizationInquiryDtoInitiatives =
  (typeof CreateOrganizationInquiryDtoInitiatives)[keyof typeof CreateOrganizationInquiryDtoInitiatives];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrganizationInquiryDtoInitiatives = {
  Yes: "Yes",
  No: "No",
} as const;

export type CreateOrganizationInquiryDtoHearFormUs =
  (typeof CreateOrganizationInquiryDtoHearFormUs)[keyof typeof CreateOrganizationInquiryDtoHearFormUs];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrganizationInquiryDtoHearFormUs = {
  "Search_engine_(Google,_Yahoo,_etc)": "Search engine (Google, Yahoo, etc.)",
  Recommended_by_a_friend_or_colleague: "Recommended by a friend or colleague",
  Social_Media: "Social Media",
  Blog_or_publication: "Blog or publication",
  Exhibition_or_event: "Exhibition or event",
  Other: "Other",
} as const;

export type CreateOrganizationInquiryDtoDiverseTalent =
  (typeof CreateOrganizationInquiryDtoDiverseTalent)[keyof typeof CreateOrganizationInquiryDtoDiverseTalent];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrganizationInquiryDtoDiverseTalent = {
  Yes: "Yes",
  No: "No",
} as const;

export type CreateOrganizationInquiryDtoCountry =
  (typeof CreateOrganizationInquiryDtoCountry)[keyof typeof CreateOrganizationInquiryDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrganizationInquiryDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export type CreateOrganizationInquiryDtoAdvertiseEvents =
  (typeof CreateOrganizationInquiryDtoAdvertiseEvents)[keyof typeof CreateOrganizationInquiryDtoAdvertiseEvents];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrganizationInquiryDtoAdvertiseEvents = {
  Yes: "Yes",
  No: "No",
} as const;

export interface CreateOrganizationInquiryDto {
  advertise_events: CreateOrganizationInquiryDtoAdvertiseEvents;
  /**
   * @minLength 1
   * @maxLength 35
   */
  contact_number: string;
  country: CreateOrganizationInquiryDtoCountry;
  diverse_talent: CreateOrganizationInquiryDtoDiverseTalent;
  email: string;
  first_name: string;
  hear_form_us?: CreateOrganizationInquiryDtoHearFormUs;
  initiatives: CreateOrganizationInquiryDtoInitiatives;
  job_title: string;
  last_name: string;
  location?: string;
  number_of_employees: CreateOrganizationInquiryDtoNumberOfEmployees;
  organization_name: string;
  reach_to: CreateOrganizationInquiryDtoReachTo;
  vacancies: CreateOrganizationInquiryDtoVacancies;
}

export interface UpdateEventDto {
  content?: string;
  coverImageId?: number;
  cta_text?: string;
  cta_url?: string;
  event_date?: string;
  event_location?: string;
  event_time?: string;
  event_type?: string;
  meta_description?: string;
  meta_keywords?: string;
  meta_title?: string;
  title?: string;
}

export interface UpdateArticleDto {
  categoriesIds?: string[];
  content?: string;
  coverImageId?: number;
  tagsIds?: string[];
  title?: string;
}

export interface CreateEventDto {
  content: string;
  coverImageId: number;
  cta_text: string;
  cta_url: string;
  event_date: string;
  event_location: string;
  event_time: string;
  event_type?: string;
  meta_description: string;
  meta_keywords: string;
  meta_title: string;
  title: string;
}

export interface CreateArticleDto {
  categoriesIds: string[];
  content: string;
  coverImageId: number;
  tagsIds: string[];
  title: string;
}

export interface UpdateEmployeeInviteDto {
  inviteId: number;
  roles: OrganizationRoleDto[];
}

export type OrganizationInviteEntityStatus =
  (typeof OrganizationInviteEntityStatus)[keyof typeof OrganizationInviteEntityStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationInviteEntityStatus = {
  pending: "pending",
  accepted: "accepted",
  rejected: "rejected",
  left: "left",
} as const;

export interface OrganizationInviteEntity {
  createdAt: string;
  expires_at?: string;
  id: number;
  invite_code: string;
  invited_email: string;
  invitedUser: UserEntity;
  inviteRoles: OrganizationRoleDto[];
  invitingUser: UserEntity;
  organization: OrganizationEntity;
  roles: string;
  status: OrganizationInviteEntityStatus;
}

export interface OrganizationInviteResponse {
  data: OrganizationInviteEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type ManageInvitesDtoStatus =
  (typeof ManageInvitesDtoStatus)[keyof typeof ManageInvitesDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManageInvitesDtoStatus = {
  pending: "pending",
  accepted: "accepted",
  rejected: "rejected",
  left: "left",
} as const;

export interface ManageInvitesDto {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  status?: ManageInvitesDtoStatus;
}

export interface DeleteInviteEmployeeDto {
  inviteIds: number[];
}

export interface InviteEmployeeDto {
  emails: string[];
  roles: OrganizationRoleDto[];
}

export type UpdateOrganizationDtoDefaultCountry =
  (typeof UpdateOrganizationDtoDefaultCountry)[keyof typeof UpdateOrganizationDtoDefaultCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateOrganizationDtoDefaultCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface UpdateOrganizationDto {
  account_manager?: number;
  allow_graduate_jobs_in_xml?: boolean;
  alternate_names?: string[];
  amount_of_employees?: string;
  api_password?: string;
  api_username?: string;
  apply_email?: string;
  custom_job_description?: string;
  custom_job_position?: string;
  default_country?: UpdateOrganizationDtoDefaultCountry;
  email?: string;
  industry?: number;
  is_apply_via_email?: boolean;
  is_featured?: boolean;
  is_location_disabled?: boolean;
  is_organization_of_the_week?: boolean;
  jobs_remaining?: number;
  membership?: number;
  meta_description?: string;
  name?: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password?: string;
  promotion_name?: string;
  promotion_tags?: string[];
  published_at?: string;
  utm_code?: string;
}

/**
 * @nullable
 */
export type OrganizationDefaultCountry = { [key: string]: any } | null;

/**
 * @nullable
 */
export type OrganizationAccountManager = UserEntity | null;

export interface NavigationCounts {
  apprenticeshipJobs: number;
  earlyCareerJobs: number;
  employees: number;
  events: number;
  jobs: number;
  news: number;
  panels: number;
  reviews: number;
}

export interface Organization {
  /** @nullable */
  account_manager?: OrganizationAccountManager;
  allow_graduate_jobs_in_xml: boolean;
  alternate_names?: string[];
  amount_of_employees: string;
  apply_email?: string;
  area: AreaEntity;
  bottom_link_background_color?: string;
  bottom_link_text?: string;
  bottom_link_text_color?: string;
  bottom_link_url?: string;
  custom_job_description?: string;
  custom_job_position?: string;
  /** @nullable */
  default_country?: OrganizationDefaultCountry;
  facebook_url?: string;
  gallery_images: string[];
  header_link_background_color?: string;
  header_link_text?: string;
  header_link_text_color?: string;
  header_link_url?: string;
  id: number;
  industry: IndustryEntity;
  instagram_url?: string;
  is_apply_via_email: boolean;
  is_featured: boolean;
  is_location_disabled: boolean;
  is_organization_of_the_week: boolean;
  jobs?: JobEntity[];
  jobs_remaining: number;
  linkedin_url?: string;
  membership: MembershipEntity;
  meta_description: string;
  meta_keywords: string;
  meta_title: string;
  name: string;
  navigationCounts: NavigationCounts;
  organizationAdmins: OrganizationAdminEntity[];
  organizationImage: OrganizationImageEntity[];
  organizationUserRoles: OrganizationUserRoleEntity[];
  owner: number;
  pinterest_url?: string;
  promotion_name?: string;
  promotion_tags?: string[];
  /** @nullable */
  published_at?: string | null;
  rating: number;
  reviews?: OrganizationReviewEntity[];
  slug: string;
  twitter_url?: string;
  utm_code?: string;
  youtube_url?: string;
}

export interface PanelsWpPostsDto {
  organizationSlug: string;
  page?: number;
  pageSize?: number;
}

export interface EventsWpPostsDto {
  organizationId?: number;
  organizationSlug?: string;
  page: number;
  pageSize?: number;
  searchTerm?: string;
  showInPersonEvents?: boolean;
  showMygworkEvents?: boolean;
  showPastEvents?: boolean;
  showVirtualEvents?: boolean;
}

export interface WpPostsBasicData {
  id: string;
  name: string;
  slug: string;
}

export interface WpPostsBasicDataResponse {
  data: WpPostsBasicData[];
  page: number;
  pageSize: number;
  total: number;
}

export interface NewsWpPostsDto {
  category?: string;
  language?: string;
  organizationId?: number;
  organizationSlug?: string;
  page: number;
  pageSize?: number;
  searchTerm?: string;
  tag?: string;
}

export type WorkFairEntityLeadFrom =
  (typeof WorkFairEntityLeadFrom)[keyof typeof WorkFairEntityLeadFrom];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairEntityLeadFrom = {
  vFairs_brochure_leads: "vFairs brochure leads",
  Workfair: "Workfair",
} as const;

export type WorkFairEntityCountry =
  (typeof WorkFairEntityCountry)[keyof typeof WorkFairEntityCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkFairEntityCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface WorkFairEntity {
  country: WorkFairEntityCountry;
  createdAt: string;
  email: string;
  first_name: string;
  id: number;
  job_title?: string;
  last_name: string;
  lead_from: WorkFairEntityLeadFrom;
  location: string;
  organization: string;
  /** @minimum 2024 */
  workfair_year: number;
}

export type CreateWorkfairLeadDtoLeadFrom =
  (typeof CreateWorkfairLeadDtoLeadFrom)[keyof typeof CreateWorkfairLeadDtoLeadFrom];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateWorkfairLeadDtoLeadFrom = {
  vFairs_brochure_leads: "vFairs brochure leads",
  Workfair: "Workfair",
} as const;

export type CreateWorkfairLeadDtoCountry =
  (typeof CreateWorkfairLeadDtoCountry)[keyof typeof CreateWorkfairLeadDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateWorkfairLeadDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface CreateWorkfairLeadDto {
  country: CreateWorkfairLeadDtoCountry;
  email: string;
  first_name: string;
  job_title?: string;
  last_name: string;
  lead_from: CreateWorkfairLeadDtoLeadFrom;
  location: string;
  organization: string;
  workfair_year: number;
}

export type CreateNewsAttachmentLeadDtoCountry =
  (typeof CreateNewsAttachmentLeadDtoCountry)[keyof typeof CreateNewsAttachmentLeadDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateNewsAttachmentLeadDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface CreateNewsAttachmentLeadDto {
  article_id: number;
  article_slug: string;
  article_title: string;
  country: CreateNewsAttachmentLeadDtoCountry;
  email: string;
  first_name: string;
  is_hiring: boolean;
  job_title?: string;
  last_name: string;
  location?: string;
  organization: string;
  report_name: string;
}

export type CreateWorkPrideDtoPronoun =
  (typeof CreateWorkPrideDtoPronoun)[keyof typeof CreateWorkPrideDtoPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateWorkPrideDtoPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export type CreateWorkPrideDtoInterestedInBecoming =
  (typeof CreateWorkPrideDtoInterestedInBecoming)[keyof typeof CreateWorkPrideDtoInterestedInBecoming];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateWorkPrideDtoInterestedInBecoming = {
  Attendee: "Attendee",
  Main_Sponsor: "Main Sponsor",
  Day_Sponsor: "Day Sponsor",
  Session_Sponsor: "Session Sponsor",
  Speaker: "Speaker",
} as const;

export type CreateWorkPrideDtoCountry =
  (typeof CreateWorkPrideDtoCountry)[keyof typeof CreateWorkPrideDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateWorkPrideDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface CreateWorkPrideDto {
  country: CreateWorkPrideDtoCountry;
  email: string;
  first_name: string;
  interested_in_becoming: CreateWorkPrideDtoInterestedInBecoming;
  is_hiring: boolean;
  job_title?: string;
  last_name: string;
  location?: string;
  organization?: string;
  pronoun: CreateWorkPrideDtoPronoun;
  workpride_year?: number;
}

export interface WorkPrideEventsResponse {
  "June 17th": WpPostEntity[];
  "June 18th": WpPostEntity[];
  "June 19th": WpPostEntity[];
  "June 20th": WpPostEntity[];
  "June 21st": WpPostEntity[];
}

export interface WorkPrideResponse {
  eventDates: string;
  events: WorkPrideEventsResponse;
  hosts: UserEntity[];
  resources: AssetEntity[];
  speakers: UserEntity[];
}

export interface NewsMetadata {
  categories: IdNamePair[];
  categoriesWithTermIds: IdNamePair[];
  languages: IdNamePair[];
  tags: IdNamePair[];
  tagsWithTermIds: IdNamePair[];
}

export interface OrganizationMetadata {
  areas: AreaEntity[];
  featuredOrganizations: OrganizationEntity[];
  industries: IndustryEntity[];
  memberships: MembershipEntity[];
  roles: OrganizationRoleDto[];
}

export interface OrganizationBasicData {
  id: number;
  name: string;
  slug: string;
}

export interface OrganizationBasicDataResponse {
  data: OrganizationBasicData[];
  page: number;
  pageSize: number;
  total: number;
}

export interface OrganizationResponse {
  data: OrganizationResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type SearchOrganizationDtoSortOrder =
  (typeof SearchOrganizationDtoSortOrder)[keyof typeof SearchOrganizationDtoSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchOrganizationDtoSortOrder = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface SearchOrganizationDto {
  industryId?: number;
  page: number;
  pageSize?: number;
  searchTerm?: string;
  sortOrder?: SearchOrganizationDtoSortOrder;
}

export interface OrganizationJobPerformanceReportDto {
  endDate?: string;
  startDate?: string;
}

export interface OrganizationJobClicksByCountryDto {
  endDate?: string;
  startDate?: string;
}

export interface ClicksByCountry {
  clicks: number;
  country: string;
}

export interface OrganizationJobClicksByCountryResponse {
  clicksByCountry: ClicksByCountry[];
}

export interface OrganizationJobPerformanceDashboardResponse {
  activeJobs: number;
  clicksByCountry: ClicksByCountry[];
  jobsData: JobsData;
  totalClicks: number;
  totalViews: number;
}

export interface JobData {
  clicks: number;
  country: string;
  id: number;
  isActive: boolean;
  slug: string;
  title: string;
  views: number;
}

export interface JobsData {
  data: JobData[];
  page: number;
  pageSize: number;
  total: number;
}

export interface OrganizationJobPerformanceDto {
  endDate?: string;
  page?: number;
  pageSize?: number;
  startDate?: string;
}

export interface WpPostsResponse {
  data: WpPostEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export interface OrganizationExclusiveWebinarsDto {
  page?: number;
  pageSize?: number;
}

export interface OrganizationDashboardResponse {
  exclusiveWebinars: WpPostEntity[];
  organization: OrganizationEntity;
  rating: number;
}

export interface OrganizationUpdateProfileDto {
  areaId?: number;
  bottom_link_background_color?: string;
  bottom_link_text?: string;
  bottom_link_text_color?: string;
  bottom_link_url?: string;
  content?: string;
  coverPhotoId?: number;
  facebook_url?: string;
  header_link_background_color?: string;
  header_link_text?: string;
  header_link_text_color?: string;
  header_link_url?: string;
  industryId?: number;
  instagram_url?: string;
  linkedin_url?: string;
  logoId?: number;
  /** @minLength 1 */
  name?: string;
  pinterest_url?: string;
  twitter_url?: string;
  youtube_url?: string;
}

/**
 * @nullable
 */
export type OrganizationProfileResponseDefaultCountry = {
  [key: string]: any;
} | null;

export type OrganizationProfileResponseContent = { [key: string]: any };

/**
 * @nullable
 */
export type OrganizationProfileResponseAccountManager = UserEntity | null;

export interface OrganizationProfileResponse {
  /** @nullable */
  account_manager?: OrganizationProfileResponseAccountManager;
  allow_graduate_jobs_in_xml: boolean;
  alternate_names?: string[];
  amount_of_employees: string;
  apply_email?: string;
  area: AreaEntity;
  bottom_link_background_color?: string;
  bottom_link_text?: string;
  bottom_link_text_color?: string;
  bottom_link_url?: string;
  content: OrganizationProfileResponseContent;
  custom_job_description?: string;
  custom_job_position?: string;
  /** @nullable */
  default_country?: OrganizationProfileResponseDefaultCountry;
  facebook_url?: string;
  gallery_images: AssetEntity[];
  header_link_background_color?: string;
  header_link_text?: string;
  header_link_text_color?: string;
  header_link_url?: string;
  id: number;
  industry: IndustryEntity;
  instagram_url?: string;
  is_apply_via_email: boolean;
  is_featured: boolean;
  is_location_disabled: boolean;
  is_organization_of_the_week: boolean;
  jobs?: JobEntity[];
  jobs_remaining: number;
  linkedin_url?: string;
  membership: MembershipEntity;
  meta_description: string;
  meta_keywords: string;
  meta_title: string;
  name: string;
  organizationAdmins: OrganizationAdminEntity[];
  organizationImage: OrganizationImageEntity[];
  organizationUserRoles: OrganizationUserRoleEntity[];
  owner: number;
  pinterest_url?: string;
  promotion_name?: string;
  promotion_tags?: string[];
  /** @nullable */
  published_at?: string | null;
  reviews?: OrganizationReviewEntity[];
  slug: string;
  twitter_url?: string;
  utm_code?: string;
  youtube_url?: string;
}

export interface CreateOrganizationDto {
  account_manager?: number;
  allow_graduate_jobs_in_xml?: boolean;
  alternate_names?: string[];
  amount_of_employees?: string;
  custom_job_description?: string;
  custom_job_position?: string;
  email: string;
  industry?: number;
  is_featured?: boolean;
  is_organization_of_the_week?: boolean;
  membership?: number;
  meta_description?: string;
  name: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
  published_at?: string;
}

export type TwoFactorVerifyDtoISOCode =
  (typeof TwoFactorVerifyDtoISOCode)[keyof typeof TwoFactorVerifyDtoISOCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TwoFactorVerifyDtoISOCode = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface TwoFactorVerifyDto {
  code: string;
  ISOCode: TwoFactorVerifyDtoISOCode;
  phoneCountryCode: string;
  phoneNumber: string;
}

export type TwoFactorDtoISOCode =
  (typeof TwoFactorDtoISOCode)[keyof typeof TwoFactorDtoISOCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TwoFactorDtoISOCode = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface TwoFactorDto {
  ISOCode: TwoFactorDtoISOCode;
  phoneCountryCode: string;
  phoneNumber: string;
}

export type OrganizationRoleDtoRole =
  (typeof OrganizationRoleDtoRole)[keyof typeof OrganizationRoleDtoRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationRoleDtoRole = {
  Manage_Jobs: "Manage Jobs",
  Manage_Events: "Manage Events",
  Manage_Articles: "Manage Articles",
  Manage_Employees: "Manage Employees",
  Manage_Account_Details: "Manage Account Details",
  View_Dashboard_Statistics: "View Dashboard Statistics",
  View_CSM: "View CSM",
  Invite_Employees: "Invite Employees",
} as const;

export interface OrganizationRoleDto {
  hasAccess: boolean;
  role: OrganizationRoleDtoRole;
}

export interface CreateOrganizationUserRoleDto {
  roles: OrganizationRoleDto[];
  userSlug: string;
}

export interface IdNamePair {
  id: string;
  name: string;
}

export interface UserMetadata {
  areas: AreaEntity[];
  countries: IdNamePair[];
  industries: IndustryEntity[];
  languages: IdNamePair[];
  organizations: IdNamePair[];
  seniority: IdNamePair[];
  yearsOfExperience: IdNamePair[];
}

export interface ReportUserDto {
  conversationId?: number;
  conversationMessageId?: number;
  reason: string;
  userId: number;
}

export interface BlockUserToggleDto {
  userId: number;
}

export interface PaginatedUsers {
  data: UserResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type SearchUserDtoYearsOfExperience =
  (typeof SearchUserDtoYearsOfExperience)[keyof typeof SearchUserDtoYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchUserDtoYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type SearchUserDtoType =
  (typeof SearchUserDtoType)[keyof typeof SearchUserDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchUserDtoType = {
  student: "student",
  graduate: "graduate",
  professional: "professional",
  admin: "admin",
} as const;

export interface SearchUserDto {
  country?: string;
  industryId?: number;
  is_country_ambassador?: boolean;
  is_mental_health_mentor?: boolean;
  is_mentor?: boolean;
  is_speaker?: boolean;
  location?: string;
  organizationId?: number;
  organizationSlug?: string;
  page: number;
  pageSize?: number;
  searchTerm?: string;
  type?: SearchUserDtoType;
  years_of_experience?: SearchUserDtoYearsOfExperience;
}

export interface UserChangePasswordDto {
  /**
   * @minLength 8
   * @maxLength 64
   */
  newPassword: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  newPasswordConfirmation: string;
}

export interface BlockedUserResponseEntity {
  blockedByUser: UserEntity;
  id: number;
  user: UserResponseEntity;
}

export interface BlockedUserResponse {
  data: BlockedUserResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export interface BlockedUserDto {
  page?: number;
  pageSize?: number;
}

export interface ConnectionCountResponse {
  activeConnections: number;
  blockedUsers: number;
  pendingConnections: number;
  requestedConnections: number;
}

export type ConnectionResponseEntityStatus =
  (typeof ConnectionResponseEntityStatus)[keyof typeof ConnectionResponseEntityStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectionResponseEntityStatus = {
  active: "active",
  pending: "pending",
  request: "request",
} as const;

export interface ConnectionResponseEntity {
  connected_user: UserResponseEntity;
  id: number;
  status: ConnectionResponseEntityStatus;
  user: UserEntity;
}

export interface ConnectionResponse {
  data: ConnectionResponseEntity[];
  page: number;
  pageSize: number;
  total: number;
}

export type ConnectionsDtoStatus =
  (typeof ConnectionsDtoStatus)[keyof typeof ConnectionsDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectionsDtoStatus = {
  all: "all",
  active: "active",
  pending: "pending",
  request: "request",
} as const;

export type ConnectionsDtoCountry =
  (typeof ConnectionsDtoCountry)[keyof typeof ConnectionsDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectionsDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface ConnectionsDto {
  country?: ConnectionsDtoCountry;
  industryId?: number;
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  status: ConnectionsDtoStatus;
}

export interface SearchConnectionsDto {
  searchTerm?: string;
  userId?: number;
}

export interface DashboardResponse {
  exclusiveWebinars: WpPostEntity[];
  friendlyOrganizations: OrganizationResponseEntity[];
  latestNews: WpPostEntity[];
  peopleFromYourOrganizations: UserResponseEntity[];
  peopleYouMayKnow: UserResponseEntity[];
  profile: UserResponseEntity;
  totalConnections: number;
  upcomingEvents: WpPostEntity[];
}

export type UserSetupDtoType =
  (typeof UserSetupDtoType)[keyof typeof UserSetupDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserSetupDtoType = {
  student: "student",
  graduate: "graduate",
  professional: "professional",
  admin: "admin",
} as const;

export type UserSetupDtoPronoun =
  (typeof UserSetupDtoPronoun)[keyof typeof UserSetupDtoPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserSetupDtoPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export type UserSetupDtoCountry =
  (typeof UserSetupDtoCountry)[keyof typeof UserSetupDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserSetupDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface UserSetupDto {
  country: UserSetupDtoCountry;
  location: string;
  pronoun: UserSetupDtoPronoun;
  type: UserSetupDtoType;
}

export interface UserSettingsDto {
  is_notify_new_connection?: boolean;
  is_notify_profile_visit?: boolean;
  is_subscribed_to_newsletter?: boolean;
  show_on_homepage?: boolean;
}

export type UserUpdateProfileDtoYearsOfExperience =
  (typeof UserUpdateProfileDtoYearsOfExperience)[keyof typeof UserUpdateProfileDtoYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserUpdateProfileDtoYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type UserUpdateProfileDtoSeniority =
  (typeof UserUpdateProfileDtoSeniority)[keyof typeof UserUpdateProfileDtoSeniority];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserUpdateProfileDtoSeniority = {
  Entry_Level: "Entry Level",
  Associate: "Associate",
  Manager: "Manager",
  Senior_Manager: "Senior Manager",
  Director: "Director",
  Vice_President: "Vice President",
  Executive: "Executive",
  "C-Level": "C-Level",
  Board_Member: "Board Member",
} as const;

export type UserUpdateProfileDtoPronoun =
  (typeof UserUpdateProfileDtoPronoun)[keyof typeof UserUpdateProfileDtoPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserUpdateProfileDtoPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export type UserUpdateProfileDtoDesiredTypeOfContactItem =
  (typeof UserUpdateProfileDtoDesiredTypeOfContactItem)[keyof typeof UserUpdateProfileDtoDesiredTypeOfContactItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserUpdateProfileDtoDesiredTypeOfContactItem = {
  Chat: "Chat",
  Phone_call: "Phone call",
  Skype: "Skype",
  Lunch: "Lunch",
  Coffee: "Coffee",
  Video: "Video",
} as const;

export type UserUpdateProfileDtoCountry =
  (typeof UserUpdateProfileDtoCountry)[keyof typeof UserUpdateProfileDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserUpdateProfileDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface UserUpdateProfileDto {
  areaId?: number;
  contact_email?: string;
  contact_phone?: string;
  contact_video_details?: string;
  country?: UserUpdateProfileDtoCountry;
  country_ambassador_country?: string;
  degree?: string;
  desired_type_of_contact?: UserUpdateProfileDtoDesiredTypeOfContactItem[];
  expected_graduation_date?: string;
  expected_mark?: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  first_name?: string;
  imageId?: number;
  industryId?: number;
  is_become_professional_notified?: boolean;
  is_country_ambassador?: boolean;
  is_mental_health_mentor?: boolean;
  is_mentee?: boolean;
  is_mentor?: boolean;
  is_speaker?: boolean;
  job_title?: string;
  languages?: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  last_name?: string;
  location?: string;
  main_interests?: string;
  mentee_hours_per_week?: number;
  mentee_speciality?: string;
  mentor_hours_per_week?: number;
  mentor_speciality?: string;
  organization_name?: string;
  pdfId?: number;
  pendingOrganizationId?: number;
  personal_message?: string;
  portfolio_website?: string;
  pronoun?: UserUpdateProfileDtoPronoun;
  pronoun_other?: string;
  school?: string;
  school_website?: string;
  seniority?: UserUpdateProfileDtoSeniority;
  /** @nullable */
  twoFactorPhoneNumber?: string | null;
  video_cv_link?: string;
  volunteer_work?: string;
  years_of_experience?: UserUpdateProfileDtoYearsOfExperience;
}

export interface FetchUsersDto {
  ids: number[];
}

export type UserResponseEntityType =
  (typeof UserResponseEntityType)[keyof typeof UserResponseEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserResponseEntityType = {
  student: "student",
  graduate: "graduate",
  professional: "professional",
  admin: "admin",
} as const;

export type UserResponseEntityPronoun =
  (typeof UserResponseEntityPronoun)[keyof typeof UserResponseEntityPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserResponseEntityPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export type UserResponseEntityConnectionStatus =
  (typeof UserResponseEntityConnectionStatus)[keyof typeof UserResponseEntityConnectionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserResponseEntityConnectionStatus = {
  active: "active",
  pending: "pending",
  request: "request",
} as const;

export interface UserResponseEntity {
  adminUserRole: AdminUserRoleEntity;
  connections: ConnectionEntity;
  connectionStatus: UserResponseEntityConnectionStatus;
  createdAt: string;
  email: string;
  first_name: string;
  id: number;
  is_banned: boolean;
  is_chat_blocked: boolean;
  is_private: boolean;
  last_name: string;
  location: LocationEntity;
  notificationTokens: UserNotificationTokenEntity[];
  online: boolean;
  organizationAdmin: OrganizationAdminEntity;
  organizationRoles: OrganizationUserRoleEntity[];
  password: string;
  pronoun?: UserResponseEntityPronoun;
  pronoun_other?: string;
  slug: string;
  timezone?: string;
  type: UserResponseEntityType;
  userOrganizationRoles: string[];
  userSetting: UserSettingsEntity;
  userTaxonomy: UserTaxonomyEntity;
  uuid: string;
  /** @nullable */
  verifiedAt: string | null;
}

export type LocationEntityEntityType =
  (typeof LocationEntityEntityType)[keyof typeof LocationEntityEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocationEntityEntityType = {
  organizations: "organizations",
  jobs: "jobs",
  users: "users",
  analytics: "analytics",
  conversation_messages: "conversation_messages",
  workpride: "workpride",
  organization_shared_files: "organization_shared_files",
} as const;

export type LocationEntityCountry =
  (typeof LocationEntityCountry)[keyof typeof LocationEntityCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocationEntityCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface LocationEntity {
  country: LocationEntityCountry;
  entity_id: number;
  entity_type: LocationEntityEntityType;
  id: number;
  latitude?: number;
  location: string;
  longitude?: number;
}

export interface AuthResetPasswordDto {
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
  token: string;
}

export interface UserTokenEntity {
  email: string;
  expires_at: string;
  id: number;
  token: string;
}

export interface AuthVerifyTokenDto {
  token: string;
}

export interface AuthForgotPasswordDto {
  email: string;
  isResettingFromMobile?: boolean;
}

export interface OAuthAppleDto {
  code?: string;
  identityToken?: string;
  user?: string;
}

export interface OAuthGoogleDto {
  access_token: string;
}

export interface OAuthLinkedInDto {
  code: string;
}

export interface AuthRegisterDto {
  email: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  first_name: string;
  invite_code?: string;
  is_subscribed_to_newsletter?: boolean;
  /**
   * @minLength 1
   * @maxLength 40
   */
  last_name: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
}

export interface RefreshTokenRequestDto {
  refreshToken: string;
}

export interface TwoFactorLoginResponse {
  phoneNumber: string;
  twoFactorRequired: boolean;
  twoFactorToken: string;
}

export interface ResendTwoFactorLoginDto {
  token: string;
}

export interface TwoFactorLoginDto {
  code: string;
  token: string;
}

export type LoginResponseVerifiedAt = { [key: string]: any };

export type LoginResponseType =
  (typeof LoginResponseType)[keyof typeof LoginResponseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoginResponseType = {
  student: "student",
  graduate: "graduate",
  professional: "professional",
  admin: "admin",
} as const;

export type LoginResponsePronoun =
  (typeof LoginResponsePronoun)[keyof typeof LoginResponsePronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoginResponsePronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export interface OrganizationUserRoleResponse {
  hasAccess: boolean;
  role: string;
}

export interface LoginResponse {
  accessToken: string;
  country: string;
  createdAt: string;
  email: string;
  first_name: string;
  id: number;
  is_chat_blocked: boolean;
  isLegacyOrganizationAdmin: boolean;
  last_name: string;
  location: string;
  organizationRoles: OrganizationUserRoleResponse[];
  pronoun: LoginResponsePronoun;
  refreshToken: string;
  slug: string;
  type: LoginResponseType;
  userTaxonomy: UserTaxonomyEntity;
  uuid: string;
  verifiedAt: LoginResponseVerifiedAt;
}

export interface AuthLoginDto {
  email: string;
  /**
   * @minLength 8
   * @maxLength 64
   */
  password: string;
}

export type CreateAspenJobDtoYearsOfExperience =
  (typeof CreateAspenJobDtoYearsOfExperience)[keyof typeof CreateAspenJobDtoYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAspenJobDtoYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type CreateAspenJobDtoSalaryCurrency =
  (typeof CreateAspenJobDtoSalaryCurrency)[keyof typeof CreateAspenJobDtoSalaryCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAspenJobDtoSalaryCurrency = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  FOK: "FOK",
  GBP: "GBP",
  GEL: "GEL",
  GGP: "GGP",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  IMP: "IMP",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JEP: "JEP",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KID: "KID",
  KMF: "KMF",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TVD: "TVD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XCD: "XCD",
  XOF: "XOF",
  XPF: "XPF",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
} as const;

export type CreateAspenJobDtoJobCountry =
  (typeof CreateAspenJobDtoJobCountry)[keyof typeof CreateAspenJobDtoJobCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAspenJobDtoJobCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export type CreateAspenJobDtoEvent =
  (typeof CreateAspenJobDtoEvent)[keyof typeof CreateAspenJobDtoEvent];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAspenJobDtoEvent = {
  add: "add",
  delete: "delete",
  replace: "replace",
} as const;

export type CreateAspenJobDtoCountry =
  (typeof CreateAspenJobDtoCountry)[keyof typeof CreateAspenJobDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAspenJobDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export type CreateAspenJobDtoCommand =
  (typeof CreateAspenJobDtoCommand)[keyof typeof CreateAspenJobDtoCommand];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAspenJobDtoCommand = {
  add: "add",
  delete: "delete",
  replace: "replace",
} as const;

export interface CreateAspenJobDto {
  application_url?: string;
  apply_url?: string;
  area?: string;
  command: CreateAspenJobDtoCommand;
  company_name?: string;
  contact_email?: string;
  contact_name?: string;
  contact_telephone?: string;
  contact_url?: string;
  country?: CreateAspenJobDtoCountry;
  days_to_advertise?: string;
  description: string;
  event: CreateAspenJobDtoEvent;
  job_area?: string;
  job_country?: CreateAspenJobDtoJobCountry;
  job_description: string;
  job_duration?: string;
  job_id?: string;
  job_industry?: string;
  job_level?: string;
  job_location: string;
  job_reference?: string;
  job_skills?: string;
  job_startdate?: string;
  job_title: string;
  job_type?: string;
  location: string;
  password: string;
  position?: string;
  salary?: string;
  salary_benefits?: string;
  salary_currency?: CreateAspenJobDtoSalaryCurrency;
  salary_from?: string;
  salary_per?: string;
  salary_to?: string;
  title: string;
  unique_id?: string;
  username: string;
  years_of_experience?: CreateAspenJobDtoYearsOfExperience;
}

export type CreateExternalJobDtoYearsOfExperience =
  (typeof CreateExternalJobDtoYearsOfExperience)[keyof typeof CreateExternalJobDtoYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateExternalJobDtoYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type CreateExternalJobDtoSalaryCurrency =
  (typeof CreateExternalJobDtoSalaryCurrency)[keyof typeof CreateExternalJobDtoSalaryCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateExternalJobDtoSalaryCurrency = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  FOK: "FOK",
  GBP: "GBP",
  GEL: "GEL",
  GGP: "GGP",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  IMP: "IMP",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JEP: "JEP",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KID: "KID",
  KMF: "KMF",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TVD: "TVD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XCD: "XCD",
  XOF: "XOF",
  XPF: "XPF",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
} as const;

export type CreateExternalJobDtoJobCountry =
  (typeof CreateExternalJobDtoJobCountry)[keyof typeof CreateExternalJobDtoJobCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateExternalJobDtoJobCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export type CreateExternalJobDtoCommand =
  (typeof CreateExternalJobDtoCommand)[keyof typeof CreateExternalJobDtoCommand];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateExternalJobDtoCommand = {
  add: "add",
  delete: "delete",
  replace: "replace",
} as const;

export interface CreateExternalJobDto {
  application_url?: string;
  command: CreateExternalJobDtoCommand;
  company_name?: string;
  contact_email?: string;
  contact_name?: string;
  contact_telephone?: string;
  contact_url?: string;
  days_to_advertise?: string;
  job_area?: string;
  job_country?: CreateExternalJobDtoJobCountry;
  job_description: string;
  job_duration?: string;
  job_id?: string;
  job_industry?: string;
  job_level?: string;
  job_location: string;
  job_reference?: string;
  job_skills?: string;
  job_startdate?: string;
  job_title: string;
  job_type?: string;
  password: string;
  position?: string;
  salary?: string;
  salary_benefits?: string;
  salary_currency?: CreateExternalJobDtoSalaryCurrency;
  salary_from?: string;
  salary_per?: string;
  salary_to?: string;
  unique_id?: string;
  username: string;
  years_of_experience?: CreateExternalJobDtoYearsOfExperience;
}

export interface GeneralResponse {
  message: string;
  success: boolean;
}

export type ContactUsDtoReason =
  (typeof ContactUsDtoReason)[keyof typeof ContactUsDtoReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactUsDtoReason = {
  Question: "Question",
  Complain: "Complain",
  Share_a_story_or_event: "Share a story or event",
  Trouble_signing_in_or_logging_in: "Trouble signing in or logging in",
} as const;

export interface ContactUsDto {
  email: string;
  /** @maxLength 255 */
  firstName: string;
  /** @maxLength 255 */
  lastName: string;
  message: string;
  reason: ContactUsDtoReason;
}

export type AcademyEntityInterestedBy =
  (typeof AcademyEntityInterestedBy)[keyof typeof AcademyEntityInterestedBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyEntityInterestedBy = {
  "E-Learning_Courses": "E-Learning Courses",
  In_Person_or_Virtual_Training: "In Person or Virtual Training",
} as const;

export type AcademyEntityCountry =
  (typeof AcademyEntityCountry)[keyof typeof AcademyEntityCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcademyEntityCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface AcademyEntity {
  country?: AcademyEntityCountry;
  createdAt: string;
  email: string;
  id: number;
  interested_by?: AcademyEntityInterestedBy;
  job_title?: string;
  name: string;
  number_licenses: string;
  organization: string;
  phone_number?: string;
}

export type CreateAcademyDtoInterestedBy =
  (typeof CreateAcademyDtoInterestedBy)[keyof typeof CreateAcademyDtoInterestedBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAcademyDtoInterestedBy = {
  "E-Learning_Courses": "E-Learning Courses",
  In_Person_or_Virtual_Training: "In Person or Virtual Training",
} as const;

export type CreateAcademyDtoCountry =
  (typeof CreateAcademyDtoCountry)[keyof typeof CreateAcademyDtoCountry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateAcademyDtoCountry = {
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  CV: "CV",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  IC: "IC",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KR: "KR",
  KP: "KP",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MK: "MK",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  MKD: "MKD",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SS: "SS",
  SR: "SR",
  SJ: "SJ",
  SZ: "SZ",
  SE: "SE",
  CH: "CH",
  SX: "SX",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;

export interface CreateAcademyDto {
  country?: CreateAcademyDtoCountry;
  /** @maxLength 255 */
  email: string;
  interested_by?: CreateAcademyDtoInterestedBy;
  /** @maxLength 255 */
  job_title?: string;
  /** @maxLength 255 */
  name: string;
  /** @maxLength 255 */
  number_licenses: string;
  /** @maxLength 255 */
  organization: string;
  /** @maxLength 255 */
  phone_number?: string;
}

export interface HomePageResponse {
  friendlyOrganizations: OrganizationResponseEntity[];
  latestNews: WpPostEntity[];
  upcomingEvents: WpPostEntity[];
}

/**
 * @nullable
 */
export type OrganizationResponseEntityDefaultCountry = {
  [key: string]: any;
} | null;

/**
 * @nullable
 */
export type OrganizationResponseEntityAccountManager = UserEntity | null;

export interface OrganizationResponseEntity {
  /** @nullable */
  account_manager?: OrganizationResponseEntityAccountManager;
  allow_graduate_jobs_in_xml: boolean;
  alternate_names?: string[];
  amount_of_employees: string;
  apply_email?: string;
  area: AreaEntity;
  bottom_link_background_color?: string;
  bottom_link_text?: string;
  bottom_link_text_color?: string;
  bottom_link_url?: string;
  custom_job_description?: string;
  custom_job_position?: string;
  /** @nullable */
  default_country?: OrganizationResponseEntityDefaultCountry;
  facebook_url?: string;
  header_link_background_color?: string;
  header_link_text?: string;
  header_link_text_color?: string;
  header_link_url?: string;
  id: number;
  industry: IndustryEntity;
  instagram_url?: string;
  is_apply_via_email: boolean;
  is_featured: boolean;
  is_location_disabled: boolean;
  is_organization_of_the_week: boolean;
  jobs?: JobEntity[];
  jobs_remaining: number;
  linkedin_url?: string;
  membership: MembershipEntity;
  meta_description: string;
  meta_keywords: string;
  meta_title: string;
  name: string;
  organizationAdmins: OrganizationAdminEntity[];
  organizationImage: OrganizationImageEntity[];
  organizationUserRoles: OrganizationUserRoleEntity[];
  owner: number;
  pinterest_url?: string;
  promotion_name?: string;
  promotion_tags?: string[];
  /** @nullable */
  published_at?: string | null;
  rating: number;
  reviews?: OrganizationReviewEntity[];
  slug: string;
  total_jobs: number;
  total_reviews: number;
  twitter_url?: string;
  utm_code?: string;
  youtube_url?: string;
}

export type UserEntityType =
  (typeof UserEntityType)[keyof typeof UserEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEntityType = {
  student: "student",
  graduate: "graduate",
  professional: "professional",
  admin: "admin",
} as const;

export type UserEntityPronoun =
  (typeof UserEntityPronoun)[keyof typeof UserEntityPronoun];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEntityPronoun = {
  None: "None",
  "She/Her": "She/Her",
  "He/Him": "He/Him",
  "They/Them": "They/Them",
  Other: "Other",
} as const;

export interface UserEntity {
  adminUserRole: AdminUserRoleEntity;
  connections: ConnectionEntity;
  createdAt: string;
  email: string;
  first_name: string;
  id: number;
  is_banned: boolean;
  is_chat_blocked: boolean;
  is_private: boolean;
  last_name: string;
  notificationTokens: UserNotificationTokenEntity[];
  online: boolean;
  organizationAdmin: OrganizationAdminEntity;
  organizationRoles: OrganizationUserRoleEntity[];
  password: string;
  pronoun?: UserEntityPronoun;
  pronoun_other?: string;
  slug: string;
  timezone?: string;
  type: UserEntityType;
  userSetting: UserSettingsEntity;
  userTaxonomy: UserTaxonomyEntity;
  uuid: string;
  /** @nullable */
  verifiedAt: string | null;
}

export type UserNotificationTokenEntityDeviceInfo = { [key: string]: any };

export interface UserNotificationTokenEntity {
  deviceInfo?: UserNotificationTokenEntityDeviceInfo;
  deviceName?: string;
  id: number;
  token: string;
  user: UserEntity;
}

export type AdminRoleEntityRole =
  (typeof AdminRoleEntityRole)[keyof typeof AdminRoleEntityRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminRoleEntityRole = {
  Content_Editor: "Content Editor",
  "Marketing/Sales": "Marketing/Sales",
  Impersonation: "Impersonation",
  Account_Manager: "Account Manager",
  Admin: "Admin",
  Super_Admin: "Super Admin",
} as const;

export interface AdminRoleEntity {
  id: number;
  role: AdminRoleEntityRole;
}

export interface AdminUserRoleEntity {
  id: number;
  role: AdminRoleEntity;
  user: UserEntity;
}

export type ConnectionEntityStatus =
  (typeof ConnectionEntityStatus)[keyof typeof ConnectionEntityStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectionEntityStatus = {
  active: "active",
  pending: "pending",
  request: "request",
} as const;

export interface ConnectionEntity {
  connected_user: UserEntity;
  id: number;
  status: ConnectionEntityStatus;
  user: UserEntity;
}

export interface UserSettingsEntity {
  id: number;
  is_notify_new_connection: boolean;
  is_notify_profile_visit: boolean;
  is_subscribed_to_newsletter: boolean;
  show_on_homepage: boolean;
  user: UserEntity;
}

export type UserTaxonomyEntityYearsOfExperience =
  (typeof UserTaxonomyEntityYearsOfExperience)[keyof typeof UserTaxonomyEntityYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTaxonomyEntityYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type UserTaxonomyEntitySeniority =
  (typeof UserTaxonomyEntitySeniority)[keyof typeof UserTaxonomyEntitySeniority];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTaxonomyEntitySeniority = {
  Entry_Level: "Entry Level",
  Associate: "Associate",
  Manager: "Manager",
  Senior_Manager: "Senior Manager",
  Director: "Director",
  Vice_President: "Vice President",
  Executive: "Executive",
  "C-Level": "C-Level",
  Board_Member: "Board Member",
} as const;

/**
 * @nullable
 */
export type UserTaxonomyEntityPendingOrganization = OrganizationEntity | null;

/**
 * @nullable
 */
export type UserTaxonomyEntityPdf = AssetEntity | null;

/**
 * @nullable
 */
export type UserTaxonomyEntityOrganization = OrganizationEntity | null;

export type UserTaxonomyEntityDesiredTypeOfContactItem =
  (typeof UserTaxonomyEntityDesiredTypeOfContactItem)[keyof typeof UserTaxonomyEntityDesiredTypeOfContactItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTaxonomyEntityDesiredTypeOfContactItem = {
  Chat: "Chat",
  Phone_call: "Phone call",
  Skype: "Skype",
  Lunch: "Lunch",
  Coffee: "Coffee",
  Video: "Video",
} as const;

export interface UserTaxonomyEntity {
  area: AreaEntity;
  contact_email?: string;
  contact_phone?: string;
  contact_video_details?: string;
  country_ambassador_country: string;
  degree?: string;
  desired_type_of_contact?: UserTaxonomyEntityDesiredTypeOfContactItem[];
  expected_graduation_date?: string;
  expected_mark?: string;
  id: number;
  image: AssetEntity;
  industry: IndustryEntity;
  is_become_professional_notified: boolean;
  is_country_ambassador: boolean;
  is_mental_health_mentor: boolean;
  is_mentee: boolean;
  is_mentor?: boolean;
  is_speaker: boolean;
  job_title?: string;
  languages?: string;
  main_interests?: string;
  mentee_hours_per_week?: number;
  mentee_speciality?: string;
  mentor_hours_per_week?: number;
  mentor_speciality?: string;
  /** @nullable */
  organization?: UserTaxonomyEntityOrganization;
  organization_name?: string;
  /** @nullable */
  pdf: UserTaxonomyEntityPdf;
  /** @nullable */
  pendingOrganization?: UserTaxonomyEntityPendingOrganization;
  personal_message?: string;
  portfolio_website?: string;
  school?: string;
  school_website?: string;
  seniority?: UserTaxonomyEntitySeniority;
  /** @nullable */
  twoFactorPhoneNumber?: string | null;
  user: UserEntity;
  video_cv_link?: string;
  volunteer_work?: string;
  years_of_experience?: UserTaxonomyEntityYearsOfExperience;
}

/**
 * @nullable
 */
export type OrganizationEntityDefaultCountry = { [key: string]: any } | null;

/**
 * @nullable
 */
export type OrganizationEntityAccountManager = UserEntity | null;

export interface OrganizationReviewEntity {
  createdAt: string;
  id: number;
  is_anonymous_to_organizations: boolean;
  organization: OrganizationEntity;
  rating?: number;
  review?: string;
  user: UserEntity;
}

export type JobEntityYearsOfExperience =
  (typeof JobEntityYearsOfExperience)[keyof typeof JobEntityYearsOfExperience];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobEntityYearsOfExperience = {
  "0-1_years": "0-1 years",
  "1-2_years": "1-2 years",
  "2-5_years": "2-5 years",
  more_than_5_years: "more than 5 years",
} as const;

export type JobEntityTypeOfContract =
  (typeof JobEntityTypeOfContract)[keyof typeof JobEntityTypeOfContract];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobEntityTypeOfContract = {
  "Full-time": "Full-time",
  Contract: "Contract",
  "Part_time/Temporary": "Part time/Temporary",
} as const;

/**
 * @nullable
 */
export type JobEntityType =
  | (typeof JobEntityType)[keyof typeof JobEntityType]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobEntityType = {
  remote: "remote",
  onsite: "onsite",
  hybrid: "hybrid",
} as const;

export type JobEntityTimezone =
  (typeof JobEntityTimezone)[keyof typeof JobEntityTimezone];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobEntityTimezone = {
  GMT: "GMT",
  PST: "PST",
} as const;

export type JobEntitySalaryType =
  (typeof JobEntitySalaryType)[keyof typeof JobEntitySalaryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobEntitySalaryType = {
  daily: "daily",
  weekly: "weekly",
  bi_weekly: "bi_weekly",
  monthly: "monthly",
  annually: "annually",
} as const;

export type JobEntitySalaryCurrency =
  (typeof JobEntitySalaryCurrency)[keyof typeof JobEntitySalaryCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobEntitySalaryCurrency = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  CRC: "CRC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  FOK: "FOK",
  GBP: "GBP",
  GEL: "GEL",
  GGP: "GGP",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HRK: "HRK",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  IMP: "IMP",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JEP: "JEP",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KID: "KID",
  KMF: "KMF",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLL: "SLL",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TVD: "TVD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  UYU: "UYU",
  UZS: "UZS",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XCD: "XCD",
  XOF: "XOF",
  XPF: "XPF",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL",
} as const;

export type JobEntityPosition =
  (typeof JobEntityPosition)[keyof typeof JobEntityPosition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobEntityPosition = {
  internship: "internship",
  entry_level: "entry_level",
  experienced_professional: "experienced_professional",
} as const;

export type JobEntityLevel =
  (typeof JobEntityLevel)[keyof typeof JobEntityLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobEntityLevel = {
  Graduates: "Graduates",
  Professionals: "Professionals",
} as const;

export interface UserSavedJobEntity {
  id: number;
  job: JobEntity;
  user: UserEntity;
}

export interface JobEntity {
  apply_url?: string;
  area: AreaEntity;
  createdAt: string;
  date_expired?: string;
  deleteCounter: number;
  deletedAt: string;
  description?: string;
  external_reference_id?: string;
  featured_at?: string;
  id: number;
  industry: IndustryEntity;
  is_auto_featured: boolean;
  is_multiple_candidates: boolean;
  level: JobEntityLevel;
  made_manual_at?: string;
  organization: OrganizationEntity;
  position: JobEntityPosition;
  salary_currency: JobEntitySalaryCurrency;
  salary_from: number;
  salary_to: number;
  salary_type: JobEntitySalaryType;
  slug: string;
  source?: string;
  source_internal_id?: string;
  timezone: JobEntityTimezone;
  title: string;
  /** @nullable */
  type: JobEntityType;
  type_of_contract: JobEntityTypeOfContract;
  user: UserEntity;
  userSavedJobs?: UserSavedJobEntity[];
  years_of_experience: JobEntityYearsOfExperience;
}

export interface OrganizationImageEntity {
  id: number;
  image: AssetEntity;
  organization: OrganizationEntity;
}

export interface OrganizationEntity {
  /** @nullable */
  account_manager?: OrganizationEntityAccountManager;
  allow_graduate_jobs_in_xml: boolean;
  alternate_names?: string[];
  amount_of_employees: string;
  apply_email?: string;
  area: AreaEntity;
  bottom_link_background_color?: string;
  bottom_link_text?: string;
  bottom_link_text_color?: string;
  bottom_link_url?: string;
  custom_job_description?: string;
  custom_job_position?: string;
  /** @nullable */
  default_country?: OrganizationEntityDefaultCountry;
  facebook_url?: string;
  header_link_background_color?: string;
  header_link_text?: string;
  header_link_text_color?: string;
  header_link_url?: string;
  id: number;
  industry: IndustryEntity;
  instagram_url?: string;
  is_apply_via_email: boolean;
  is_featured: boolean;
  is_location_disabled: boolean;
  is_organization_of_the_week: boolean;
  jobs?: JobEntity[];
  jobs_remaining: number;
  linkedin_url?: string;
  membership: MembershipEntity;
  meta_description: string;
  meta_keywords: string;
  meta_title: string;
  name: string;
  organizationAdmins: OrganizationAdminEntity[];
  organizationImage: OrganizationImageEntity[];
  organizationUserRoles: OrganizationUserRoleEntity[];
  owner: number;
  pinterest_url?: string;
  promotion_name?: string;
  promotion_tags?: string[];
  /** @nullable */
  published_at?: string | null;
  reviews?: OrganizationReviewEntity[];
  slug: string;
  twitter_url?: string;
  utm_code?: string;
  youtube_url?: string;
}

export type AssetEntityType =
  (typeof AssetEntityType)[keyof typeof AssetEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetEntityType = {
  banner: "banner",
  logo: "logo",
  resume: "resume",
  profile: "profile",
  message_attachment: "message_attachment",
  corporate_partners: "corporate_partners",
  inclusive_partners: "inclusive_partners",
  community_partners: "community_partners",
  media_partners: "media_partners",
  brochure: "brochure",
  organization_shared_files: "organization_shared_files",
  organization_gallery_images: "organization_gallery_images",
} as const;

export type AssetEntitySource =
  (typeof AssetEntitySource)[keyof typeof AssetEntitySource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetEntitySource = {
  cloudinary: "cloudinary",
  local: "local",
} as const;

export type AssetEntityFileType =
  (typeof AssetEntityFileType)[keyof typeof AssetEntityFileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetEntityFileType = {
  "image/jpeg": "image/jpeg",
  "image/png": "image/png",
  "image/gif": "image/gif",
  "image/svg+xml": "image/svg+xml",
  "image/webp": "image/webp",
  "video/mp4": "video/mp4",
  "video/quicktime": "video/quicktime",
  "application/pdf": "application/pdf",
  "application/msword": "application/msword",
  "application/vndopenxmlformats-officedocumentwordprocessingmldocument":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vndms-excel": "application/vnd.ms-excel",
  "application/vndopenxmlformats-officedocumentspreadsheetmlsheet":
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vndms-powerpoint": "application/vnd.ms-powerpoint",
  "application/vndopenxmlformats-officedocumentpresentationmlpresentation":
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/csv": "text/csv",
  "audio/mpeg": "audio/mpeg",
} as const;

export type AssetEntityEntityType =
  (typeof AssetEntityEntityType)[keyof typeof AssetEntityEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetEntityEntityType = {
  organizations: "organizations",
  jobs: "jobs",
  users: "users",
  analytics: "analytics",
  conversation_messages: "conversation_messages",
  workpride: "workpride",
  organization_shared_files: "organization_shared_files",
} as const;

export interface AssetEntity {
  entity_id: number;
  entity_type: AssetEntityEntityType;
  fileType?: AssetEntityFileType;
  id: number;
  name: string;
  original_name?: string;
  public_id: string;
  source: AssetEntitySource;
  type: AssetEntityType;
}

export type OrganizationRoleEntityRole =
  (typeof OrganizationRoleEntityRole)[keyof typeof OrganizationRoleEntityRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationRoleEntityRole = {
  Manage_Jobs: "Manage Jobs",
  Manage_Events: "Manage Events",
  Manage_Articles: "Manage Articles",
  Manage_Employees: "Manage Employees",
  Manage_Account_Details: "Manage Account Details",
  View_Dashboard_Statistics: "View Dashboard Statistics",
  View_CSM: "View CSM",
  Invite_Employees: "Invite Employees",
} as const;

export interface OrganizationRoleEntity {
  id: number;
  role: OrganizationRoleEntityRole;
}

export interface OrganizationUserRoleEntity {
  id: number;
  organization: OrganizationEntity;
  role: OrganizationRoleEntity;
  user: UserEntity;
}

export interface OrganizationAdminEntity {
  id: number;
  organization: OrganizationEntity;
  user: UserEntity;
}

export interface MembershipEntity {
  id: number;
  name: string;
}

export interface IndustryEntity {
  id: number;
  name: string;
}

export interface AreaEntity {
  id: number;
  name: string;
}

export interface WpPostEntity {
  [key: string]: any;
}

export const appGetHello = (signal?: AbortSignal) => {
  return customClient<string>({ url: `/`, method: "GET", signal });
};

export const getAppGetHelloQueryKey = () => {
  return [`/`] as const;
};

export const getAppGetHelloQueryOptions = <
  TData = Awaited<ReturnType<typeof appGetHello>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetHello>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAppGetHelloQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appGetHello>>> = ({
    signal,
  }) => appGetHello(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof appGetHello>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppGetHelloQueryResult = NonNullable<
  Awaited<ReturnType<typeof appGetHello>>
>;
export type AppGetHelloQueryError = unknown;

export const useAppGetHello = <
  TData = Awaited<ReturnType<typeof appGetHello>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetHello>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppGetHelloQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const appGetHomePageData = (signal?: AbortSignal) => {
  return customClient<HomePageResponse>({
    url: `/home`,
    method: "GET",
    signal,
  });
};

export const getAppGetHomePageDataQueryKey = () => {
  return [`/home`] as const;
};

export const getAppGetHomePageDataQueryOptions = <
  TData = Awaited<ReturnType<typeof appGetHomePageData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetHomePageData>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAppGetHomePageDataQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof appGetHomePageData>>
  > = ({ signal }) => appGetHomePageData(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof appGetHomePageData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppGetHomePageDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof appGetHomePageData>>
>;
export type AppGetHomePageDataQueryError = unknown;

export const useAppGetHomePageData = <
  TData = Awaited<ReturnType<typeof appGetHomePageData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetHomePageData>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppGetHomePageDataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const appCreateAcademy = (createAcademyDto: CreateAcademyDto) => {
  return customClient<AcademyEntity>({
    url: `/academy`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createAcademyDto,
  });
};

export const getAppCreateAcademyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appCreateAcademy>>,
    TError,
    { data: CreateAcademyDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof appCreateAcademy>>,
  TError,
  { data: CreateAcademyDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof appCreateAcademy>>,
    { data: CreateAcademyDto }
  > = (props) => {
    const { data } = props ?? {};

    return appCreateAcademy(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AppCreateAcademyMutationResult = NonNullable<
  Awaited<ReturnType<typeof appCreateAcademy>>
>;
export type AppCreateAcademyMutationBody = CreateAcademyDto;
export type AppCreateAcademyMutationError = unknown;

export const useAppCreateAcademy = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appCreateAcademy>>,
    TError,
    { data: CreateAcademyDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof appCreateAcademy>>,
  TError,
  { data: CreateAcademyDto },
  TContext
> => {
  const mutationOptions = getAppCreateAcademyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const appContactUs = (contactUsDto: ContactUsDto) => {
  return customClient<GeneralResponse>({
    url: `/contact-us`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: contactUsDto,
  });
};

export const getAppContactUsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appContactUs>>,
    TError,
    { data: ContactUsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof appContactUs>>,
  TError,
  { data: ContactUsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof appContactUs>>,
    { data: ContactUsDto }
  > = (props) => {
    const { data } = props ?? {};

    return appContactUs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AppContactUsMutationResult = NonNullable<
  Awaited<ReturnType<typeof appContactUs>>
>;
export type AppContactUsMutationBody = ContactUsDto;
export type AppContactUsMutationError = unknown;

export const useAppContactUs = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appContactUs>>,
    TError,
    { data: ContactUsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof appContactUs>>,
  TError,
  { data: ContactUsDto },
  TContext
> => {
  const mutationOptions = getAppContactUsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const appGetJobs = (signal?: AbortSignal) => {
  return customClient<void>({ url: `/en/feeds/jobs`, method: "GET", signal });
};

export const getAppGetJobsQueryKey = () => {
  return [`/en/feeds/jobs`] as const;
};

export const getAppGetJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof appGetJobs>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetJobs>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAppGetJobsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appGetJobs>>> = ({
    signal,
  }) => appGetJobs(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof appGetJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppGetJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof appGetJobs>>
>;
export type AppGetJobsQueryError = unknown;

export const useAppGetJobs = <
  TData = Awaited<ReturnType<typeof appGetJobs>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetJobs>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppGetJobsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const appGetGraduatesJobs = (signal?: AbortSignal) => {
  return customClient<void>({
    url: `/en/feeds/jobs/graduates`,
    method: "GET",
    signal,
  });
};

export const getAppGetGraduatesJobsQueryKey = () => {
  return [`/en/feeds/jobs/graduates`] as const;
};

export const getAppGetGraduatesJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof appGetGraduatesJobs>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetGraduatesJobs>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAppGetGraduatesJobsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof appGetGraduatesJobs>>
  > = ({ signal }) => appGetGraduatesJobs(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof appGetGraduatesJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppGetGraduatesJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof appGetGraduatesJobs>>
>;
export type AppGetGraduatesJobsQueryError = unknown;

export const useAppGetGraduatesJobs = <
  TData = Awaited<ReturnType<typeof appGetGraduatesJobs>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetGraduatesJobs>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppGetGraduatesJobsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const appGetJobsForLinkedin = (signal?: AbortSignal) => {
  return customClient<void>({
    url: `/en/feeds/jobs/linkedin`,
    method: "GET",
    signal,
  });
};

export const getAppGetJobsForLinkedinQueryKey = () => {
  return [`/en/feeds/jobs/linkedin`] as const;
};

export const getAppGetJobsForLinkedinQueryOptions = <
  TData = Awaited<ReturnType<typeof appGetJobsForLinkedin>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetJobsForLinkedin>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAppGetJobsForLinkedinQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof appGetJobsForLinkedin>>
  > = ({ signal }) => appGetJobsForLinkedin(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof appGetJobsForLinkedin>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppGetJobsForLinkedinQueryResult = NonNullable<
  Awaited<ReturnType<typeof appGetJobsForLinkedin>>
>;
export type AppGetJobsForLinkedinQueryError = unknown;

export const useAppGetJobsForLinkedin = <
  TData = Awaited<ReturnType<typeof appGetJobsForLinkedin>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetJobsForLinkedin>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppGetJobsForLinkedinQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const appGetEvents = (signal?: AbortSignal) => {
  return customClient<void>({ url: `/en/feeds/events`, method: "GET", signal });
};

export const getAppGetEventsQueryKey = () => {
  return [`/en/feeds/events`] as const;
};

export const getAppGetEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof appGetEvents>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetEvents>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAppGetEventsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appGetEvents>>> = ({
    signal,
  }) => appGetEvents(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof appGetEvents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AppGetEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof appGetEvents>>
>;
export type AppGetEventsQueryError = unknown;

export const useAppGetEvents = <
  TData = Awaited<ReturnType<typeof appGetEvents>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof appGetEvents>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppGetEventsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const appCreateJobFromExternalSource = (
  createExternalJobDto: CreateExternalJobDto,
) => {
  return customClient<void>({
    url: `/broadbean/event`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createExternalJobDto,
  });
};

export const getAppCreateJobFromExternalSourceMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appCreateJobFromExternalSource>>,
    TError,
    { data: CreateExternalJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof appCreateJobFromExternalSource>>,
  TError,
  { data: CreateExternalJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof appCreateJobFromExternalSource>>,
    { data: CreateExternalJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return appCreateJobFromExternalSource(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AppCreateJobFromExternalSourceMutationResult = NonNullable<
  Awaited<ReturnType<typeof appCreateJobFromExternalSource>>
>;
export type AppCreateJobFromExternalSourceMutationBody = CreateExternalJobDto;
export type AppCreateJobFromExternalSourceMutationError = unknown;

export const useAppCreateJobFromExternalSource = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appCreateJobFromExternalSource>>,
    TError,
    { data: CreateExternalJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof appCreateJobFromExternalSource>>,
  TError,
  { data: CreateExternalJobDto },
  TContext
> => {
  const mutationOptions =
    getAppCreateJobFromExternalSourceMutationOptions(options);

  return useMutation(mutationOptions);
};

export const appCreateJobFromAspenSource = (
  createAspenJobDto: CreateAspenJobDto,
) => {
  return customClient<void>({
    url: `/jobs/event`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createAspenJobDto,
  });
};

export const getAppCreateJobFromAspenSourceMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appCreateJobFromAspenSource>>,
    TError,
    { data: CreateAspenJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof appCreateJobFromAspenSource>>,
  TError,
  { data: CreateAspenJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof appCreateJobFromAspenSource>>,
    { data: CreateAspenJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return appCreateJobFromAspenSource(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AppCreateJobFromAspenSourceMutationResult = NonNullable<
  Awaited<ReturnType<typeof appCreateJobFromAspenSource>>
>;
export type AppCreateJobFromAspenSourceMutationBody = CreateAspenJobDto;
export type AppCreateJobFromAspenSourceMutationError = unknown;

export const useAppCreateJobFromAspenSource = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appCreateJobFromAspenSource>>,
    TError,
    { data: CreateAspenJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof appCreateJobFromAspenSource>>,
  TError,
  { data: CreateAspenJobDto },
  TContext
> => {
  const mutationOptions =
    getAppCreateJobFromAspenSourceMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authLogin = (authLoginDto: AuthLoginDto) => {
  return customClient<LoginResponse>({
    url: `/auth/login`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authLoginDto,
  });
};

export const getAuthLoginMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLogin>>,
    TError,
    { data: AuthLoginDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authLogin>>,
  TError,
  { data: AuthLoginDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authLogin>>,
    { data: AuthLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return authLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof authLogin>>
>;
export type AuthLoginMutationBody = AuthLoginDto;
export type AuthLoginMutationError = unknown;

export const useAuthLogin = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLogin>>,
    TError,
    { data: AuthLoginDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authLogin>>,
  TError,
  { data: AuthLoginDto },
  TContext
> => {
  const mutationOptions = getAuthLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authVerifyTwoFactorCode = (
  twoFactorLoginDto: TwoFactorLoginDto,
) => {
  return customClient<LoginResponse>({
    url: `/auth/two-factor/verify`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: twoFactorLoginDto,
  });
};

export const getAuthVerifyTwoFactorCodeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authVerifyTwoFactorCode>>,
    TError,
    { data: TwoFactorLoginDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authVerifyTwoFactorCode>>,
  TError,
  { data: TwoFactorLoginDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authVerifyTwoFactorCode>>,
    { data: TwoFactorLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return authVerifyTwoFactorCode(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthVerifyTwoFactorCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof authVerifyTwoFactorCode>>
>;
export type AuthVerifyTwoFactorCodeMutationBody = TwoFactorLoginDto;
export type AuthVerifyTwoFactorCodeMutationError = unknown;

export const useAuthVerifyTwoFactorCode = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authVerifyTwoFactorCode>>,
    TError,
    { data: TwoFactorLoginDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authVerifyTwoFactorCode>>,
  TError,
  { data: TwoFactorLoginDto },
  TContext
> => {
  const mutationOptions = getAuthVerifyTwoFactorCodeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authResendTwoFactorCode = (
  resendTwoFactorLoginDto: ResendTwoFactorLoginDto,
) => {
  return customClient<TwoFactorLoginResponse>({
    url: `/auth/two-factor/resend`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: resendTwoFactorLoginDto,
  });
};

export const getAuthResendTwoFactorCodeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authResendTwoFactorCode>>,
    TError,
    { data: ResendTwoFactorLoginDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authResendTwoFactorCode>>,
  TError,
  { data: ResendTwoFactorLoginDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authResendTwoFactorCode>>,
    { data: ResendTwoFactorLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return authResendTwoFactorCode(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthResendTwoFactorCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof authResendTwoFactorCode>>
>;
export type AuthResendTwoFactorCodeMutationBody = ResendTwoFactorLoginDto;
export type AuthResendTwoFactorCodeMutationError = unknown;

export const useAuthResendTwoFactorCode = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authResendTwoFactorCode>>,
    TError,
    { data: ResendTwoFactorLoginDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authResendTwoFactorCode>>,
  TError,
  { data: ResendTwoFactorLoginDto },
  TContext
> => {
  const mutationOptions = getAuthResendTwoFactorCodeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authRefreshToken = (
  refreshTokenRequestDto: RefreshTokenRequestDto,
) => {
  return customClient<AuthRefreshToken201>({
    url: `/auth/refresh-token`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: refreshTokenRequestDto,
  });
};

export const getAuthRefreshTokenMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authRefreshToken>>,
    TError,
    { data: RefreshTokenRequestDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authRefreshToken>>,
  TError,
  { data: RefreshTokenRequestDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authRefreshToken>>,
    { data: RefreshTokenRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return authRefreshToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthRefreshTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof authRefreshToken>>
>;
export type AuthRefreshTokenMutationBody = RefreshTokenRequestDto;
export type AuthRefreshTokenMutationError = unknown;

export const useAuthRefreshToken = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authRefreshToken>>,
    TError,
    { data: RefreshTokenRequestDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authRefreshToken>>,
  TError,
  { data: RefreshTokenRequestDto },
  TContext
> => {
  const mutationOptions = getAuthRefreshTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authRegister = (authRegisterDto: AuthRegisterDto) => {
  return customClient<UserEntity>({
    url: `/auth/register`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authRegisterDto,
  });
};

export const getAuthRegisterMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authRegister>>,
    TError,
    { data: AuthRegisterDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authRegister>>,
  TError,
  { data: AuthRegisterDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authRegister>>,
    { data: AuthRegisterDto }
  > = (props) => {
    const { data } = props ?? {};

    return authRegister(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthRegisterMutationResult = NonNullable<
  Awaited<ReturnType<typeof authRegister>>
>;
export type AuthRegisterMutationBody = AuthRegisterDto;
export type AuthRegisterMutationError = unknown;

export const useAuthRegister = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authRegister>>,
    TError,
    { data: AuthRegisterDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authRegister>>,
  TError,
  { data: AuthRegisterDto },
  TContext
> => {
  const mutationOptions = getAuthRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authSignInWithLinkedin = (oAuthLinkedInDto: OAuthLinkedInDto) => {
  return customClient<LoginResponse>({
    url: `/auth/oauth/linkedin`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: oAuthLinkedInDto,
  });
};

export const getAuthSignInWithLinkedinMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSignInWithLinkedin>>,
    TError,
    { data: OAuthLinkedInDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authSignInWithLinkedin>>,
  TError,
  { data: OAuthLinkedInDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authSignInWithLinkedin>>,
    { data: OAuthLinkedInDto }
  > = (props) => {
    const { data } = props ?? {};

    return authSignInWithLinkedin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthSignInWithLinkedinMutationResult = NonNullable<
  Awaited<ReturnType<typeof authSignInWithLinkedin>>
>;
export type AuthSignInWithLinkedinMutationBody = OAuthLinkedInDto;
export type AuthSignInWithLinkedinMutationError = unknown;

export const useAuthSignInWithLinkedin = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSignInWithLinkedin>>,
    TError,
    { data: OAuthLinkedInDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authSignInWithLinkedin>>,
  TError,
  { data: OAuthLinkedInDto },
  TContext
> => {
  const mutationOptions = getAuthSignInWithLinkedinMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authSignInWithGoogle = (oAuthGoogleDto: OAuthGoogleDto) => {
  return customClient<LoginResponse>({
    url: `/auth/oauth/google`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: oAuthGoogleDto,
  });
};

export const getAuthSignInWithGoogleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSignInWithGoogle>>,
    TError,
    { data: OAuthGoogleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authSignInWithGoogle>>,
  TError,
  { data: OAuthGoogleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authSignInWithGoogle>>,
    { data: OAuthGoogleDto }
  > = (props) => {
    const { data } = props ?? {};

    return authSignInWithGoogle(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthSignInWithGoogleMutationResult = NonNullable<
  Awaited<ReturnType<typeof authSignInWithGoogle>>
>;
export type AuthSignInWithGoogleMutationBody = OAuthGoogleDto;
export type AuthSignInWithGoogleMutationError = unknown;

export const useAuthSignInWithGoogle = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSignInWithGoogle>>,
    TError,
    { data: OAuthGoogleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authSignInWithGoogle>>,
  TError,
  { data: OAuthGoogleDto },
  TContext
> => {
  const mutationOptions = getAuthSignInWithGoogleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authSignInWithApple = (oAuthAppleDto: OAuthAppleDto) => {
  return customClient<LoginResponse>({
    url: `/auth/oauth/apple`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: oAuthAppleDto,
  });
};

export const getAuthSignInWithAppleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSignInWithApple>>,
    TError,
    { data: OAuthAppleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authSignInWithApple>>,
  TError,
  { data: OAuthAppleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authSignInWithApple>>,
    { data: OAuthAppleDto }
  > = (props) => {
    const { data } = props ?? {};

    return authSignInWithApple(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthSignInWithAppleMutationResult = NonNullable<
  Awaited<ReturnType<typeof authSignInWithApple>>
>;
export type AuthSignInWithAppleMutationBody = OAuthAppleDto;
export type AuthSignInWithAppleMutationError = unknown;

export const useAuthSignInWithApple = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSignInWithApple>>,
    TError,
    { data: OAuthAppleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authSignInWithApple>>,
  TError,
  { data: OAuthAppleDto },
  TContext
> => {
  const mutationOptions = getAuthSignInWithAppleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authSignInWithAppleRedirect = (oAuthAppleDto: OAuthAppleDto) => {
  return customClient<void>({
    url: `/auth/oauth/apple/redirect`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: oAuthAppleDto,
  });
};

export const getAuthSignInWithAppleRedirectMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSignInWithAppleRedirect>>,
    TError,
    { data: OAuthAppleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authSignInWithAppleRedirect>>,
  TError,
  { data: OAuthAppleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authSignInWithAppleRedirect>>,
    { data: OAuthAppleDto }
  > = (props) => {
    const { data } = props ?? {};

    return authSignInWithAppleRedirect(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthSignInWithAppleRedirectMutationResult = NonNullable<
  Awaited<ReturnType<typeof authSignInWithAppleRedirect>>
>;
export type AuthSignInWithAppleRedirectMutationBody = OAuthAppleDto;
export type AuthSignInWithAppleRedirectMutationError = unknown;

export const useAuthSignInWithAppleRedirect = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authSignInWithAppleRedirect>>,
    TError,
    { data: OAuthAppleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authSignInWithAppleRedirect>>,
  TError,
  { data: OAuthAppleDto },
  TContext
> => {
  const mutationOptions =
    getAuthSignInWithAppleRedirectMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authImpersonateUser = (token: string) => {
  return customClient<LoginResponse>({
    url: `/auth/impersonate/${token}`,
    method: "POST",
  });
};

export const getAuthImpersonateUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authImpersonateUser>>,
    TError,
    { token: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authImpersonateUser>>,
  TError,
  { token: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authImpersonateUser>>,
    { token: string }
  > = (props) => {
    const { token } = props ?? {};

    return authImpersonateUser(token);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthImpersonateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof authImpersonateUser>>
>;

export type AuthImpersonateUserMutationError = unknown;

export const useAuthImpersonateUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authImpersonateUser>>,
    TError,
    { token: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authImpersonateUser>>,
  TError,
  { token: string },
  TContext
> => {
  const mutationOptions = getAuthImpersonateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authForgotPassword = (
  authForgotPasswordDto: AuthForgotPasswordDto,
) => {
  return customClient<void>({
    url: `/auth/forgot-password`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authForgotPasswordDto,
  });
};

export const getAuthForgotPasswordMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authForgotPassword>>,
    TError,
    { data: AuthForgotPasswordDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authForgotPassword>>,
  TError,
  { data: AuthForgotPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authForgotPassword>>,
    { data: AuthForgotPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return authForgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authForgotPassword>>
>;
export type AuthForgotPasswordMutationBody = AuthForgotPasswordDto;
export type AuthForgotPasswordMutationError = unknown;

export const useAuthForgotPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authForgotPassword>>,
    TError,
    { data: AuthForgotPasswordDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authForgotPassword>>,
  TError,
  { data: AuthForgotPasswordDto },
  TContext
> => {
  const mutationOptions = getAuthForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authVerifyToken = (authVerifyTokenDto: AuthVerifyTokenDto) => {
  return customClient<UserTokenEntity>({
    url: `/auth/verify-token`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authVerifyTokenDto,
  });
};

export const getAuthVerifyTokenMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authVerifyToken>>,
    TError,
    { data: AuthVerifyTokenDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authVerifyToken>>,
  TError,
  { data: AuthVerifyTokenDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authVerifyToken>>,
    { data: AuthVerifyTokenDto }
  > = (props) => {
    const { data } = props ?? {};

    return authVerifyToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthVerifyTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof authVerifyToken>>
>;
export type AuthVerifyTokenMutationBody = AuthVerifyTokenDto;
export type AuthVerifyTokenMutationError = unknown;

export const useAuthVerifyToken = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authVerifyToken>>,
    TError,
    { data: AuthVerifyTokenDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authVerifyToken>>,
  TError,
  { data: AuthVerifyTokenDto },
  TContext
> => {
  const mutationOptions = getAuthVerifyTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authResetPassword = (
  authResetPasswordDto: AuthResetPasswordDto,
) => {
  return customClient<UserEntity>({
    url: `/auth/reset-password`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authResetPasswordDto,
  });
};

export const getAuthResetPasswordMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authResetPassword>>,
    TError,
    { data: AuthResetPasswordDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authResetPassword>>,
  TError,
  { data: AuthResetPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authResetPassword>>,
    { data: AuthResetPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return authResetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authResetPassword>>
>;
export type AuthResetPasswordMutationBody = AuthResetPasswordDto;
export type AuthResetPasswordMutationError = unknown;

export const useAuthResetPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authResetPassword>>,
    TError,
    { data: AuthResetPasswordDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authResetPassword>>,
  TError,
  { data: AuthResetPasswordDto },
  TContext
> => {
  const mutationOptions = getAuthResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authVerifyAccount = (token: unknown, signal?: AbortSignal) => {
  return customClient<GeneralResponse>({
    url: `/auth/verify-account/${token}`,
    method: "GET",
    signal,
  });
};

export const getAuthVerifyAccountQueryKey = (token: unknown) => {
  return [`/auth/verify-account/${token}`] as const;
};

export const getAuthVerifyAccountQueryOptions = <
  TData = Awaited<ReturnType<typeof authVerifyAccount>>,
  TError = unknown,
>(
  token: unknown,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authVerifyAccount>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthVerifyAccountQueryKey(token);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authVerifyAccount>>
  > = ({ signal }) => authVerifyAccount(token, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!token,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof authVerifyAccount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthVerifyAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof authVerifyAccount>>
>;
export type AuthVerifyAccountQueryError = unknown;

export const useAuthVerifyAccount = <
  TData = Awaited<ReturnType<typeof authVerifyAccount>>,
  TError = unknown,
>(
  token: unknown,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authVerifyAccount>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthVerifyAccountQueryOptions(token, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const authResendVerifyEmail = () => {
  return customClient<void>({
    url: `/auth/resend-verify-email`,
    method: "POST",
  });
};

export const getAuthResendVerifyEmailMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authResendVerifyEmail>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authResendVerifyEmail>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authResendVerifyEmail>>,
    void
  > = () => {
    return authResendVerifyEmail();
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthResendVerifyEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof authResendVerifyEmail>>
>;

export type AuthResendVerifyEmailMutationError = unknown;

export const useAuthResendVerifyEmail = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authResendVerifyEmail>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authResendVerifyEmail>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAuthResendVerifyEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userUpdateUserElasticSearch = (signal?: AbortSignal) => {
  return customClient<void>({ url: `/user/es/update`, method: "GET", signal });
};

export const getUserUpdateUserElasticSearchQueryKey = () => {
  return [`/user/es/update`] as const;
};

export const getUserUpdateUserElasticSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof userUpdateUserElasticSearch>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userUpdateUserElasticSearch>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserUpdateUserElasticSearchQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userUpdateUserElasticSearch>>
  > = ({ signal }) => userUpdateUserElasticSearch(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userUpdateUserElasticSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserUpdateUserElasticSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof userUpdateUserElasticSearch>>
>;
export type UserUpdateUserElasticSearchQueryError = unknown;

export const useUserUpdateUserElasticSearch = <
  TData = Awaited<ReturnType<typeof userUpdateUserElasticSearch>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userUpdateUserElasticSearch>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserUpdateUserElasticSearchQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userGetUserWpMetaData = (
  params: UserGetUserWpMetaDataParams,
  signal?: AbortSignal,
) => {
  return customClient<UserEntity[]>({
    url: `/user/wp/metadata`,
    method: "GET",
    params,
    signal,
  });
};

export const getUserGetUserWpMetaDataQueryKey = (
  params: UserGetUserWpMetaDataParams,
) => {
  return [`/user/wp/metadata`, ...(params ? [params] : [])] as const;
};

export const getUserGetUserWpMetaDataQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetUserWpMetaData>>,
  TError = unknown,
>(
  params: UserGetUserWpMetaDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetUserWpMetaData>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetUserWpMetaDataQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetUserWpMetaData>>
  > = ({ signal }) => userGetUserWpMetaData(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetUserWpMetaData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetUserWpMetaDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetUserWpMetaData>>
>;
export type UserGetUserWpMetaDataQueryError = unknown;

export const useUserGetUserWpMetaData = <
  TData = Awaited<ReturnType<typeof userGetUserWpMetaData>>,
  TError = unknown,
>(
  params: UserGetUserWpMetaDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetUserWpMetaData>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetUserWpMetaDataQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userGetMemberProfile = (
  userIdOrSlug: string,
  signal?: AbortSignal,
) => {
  return customClient<UserResponseEntity>({
    url: `/user/member-profile/${userIdOrSlug}`,
    method: "GET",
    signal,
  });
};

export const getUserGetMemberProfileQueryKey = (userIdOrSlug: string) => {
  return [`/user/member-profile/${userIdOrSlug}`] as const;
};

export const getUserGetMemberProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetMemberProfile>>,
  TError = unknown,
>(
  userIdOrSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetMemberProfile>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetMemberProfileQueryKey(userIdOrSlug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetMemberProfile>>
  > = ({ signal }) => userGetMemberProfile(userIdOrSlug, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userIdOrSlug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetMemberProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetMemberProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetMemberProfile>>
>;
export type UserGetMemberProfileQueryError = unknown;

export const useUserGetMemberProfile = <
  TData = Awaited<ReturnType<typeof userGetMemberProfile>>,
  TError = unknown,
>(
  userIdOrSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetMemberProfile>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetMemberProfileQueryOptions(
    userIdOrSlug,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userFetchUsers = (fetchUsersDto: FetchUsersDto) => {
  return customClient<UserEntity[]>({
    url: `/user/fetch-users`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: fetchUsersDto,
  });
};

export const getUserFetchUsersMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userFetchUsers>>,
    TError,
    { data: FetchUsersDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userFetchUsers>>,
  TError,
  { data: FetchUsersDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userFetchUsers>>,
    { data: FetchUsersDto }
  > = (props) => {
    const { data } = props ?? {};

    return userFetchUsers(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserFetchUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof userFetchUsers>>
>;
export type UserFetchUsersMutationBody = FetchUsersDto;
export type UserFetchUsersMutationError = unknown;

export const useUserFetchUsers = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userFetchUsers>>,
    TError,
    { data: FetchUsersDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userFetchUsers>>,
  TError,
  { data: FetchUsersDto },
  TContext
> => {
  const mutationOptions = getUserFetchUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userGetUserProfile = () => {
  return customClient<LoginResponse>({ url: `/user/profile`, method: "POST" });
};

export const getUserGetUserProfileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userGetUserProfile>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userGetUserProfile>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userGetUserProfile>>,
    void
  > = () => {
    return userGetUserProfile();
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetUserProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof userGetUserProfile>>
>;

export type UserGetUserProfileMutationError = unknown;

export const useUserGetUserProfile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userGetUserProfile>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userGetUserProfile>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getUserGetUserProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userGetProfile = (signal?: AbortSignal) => {
  return customClient<UserResponseEntity>({
    url: `/user/profile`,
    method: "GET",
    signal,
  });
};

export const getUserGetProfileQueryKey = () => {
  return [`/user/profile`] as const;
};

export const getUserGetProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetProfile>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetProfile>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userGetProfile>>> = ({
    signal,
  }) => userGetProfile(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetProfile>>
>;
export type UserGetProfileQueryError = unknown;

export const useUserGetProfile = <
  TData = Awaited<ReturnType<typeof userGetProfile>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetProfile>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userUpdateProfile = (
  userUpdateProfileDto: UserUpdateProfileDto,
) => {
  return customClient<UserResponseEntity>({
    url: `/user/profile`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: userUpdateProfileDto,
  });
};

export const getUserUpdateProfileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userUpdateProfile>>,
    TError,
    { data: UserUpdateProfileDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userUpdateProfile>>,
  TError,
  { data: UserUpdateProfileDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userUpdateProfile>>,
    { data: UserUpdateProfileDto }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateProfile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserUpdateProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof userUpdateProfile>>
>;
export type UserUpdateProfileMutationBody = UserUpdateProfileDto;
export type UserUpdateProfileMutationError = unknown;

export const useUserUpdateProfile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userUpdateProfile>>,
    TError,
    { data: UserUpdateProfileDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userUpdateProfile>>,
  TError,
  { data: UserUpdateProfileDto },
  TContext
> => {
  const mutationOptions = getUserUpdateProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userUpdateUserSettings = (userSettingsDto: UserSettingsDto) => {
  return customClient<UserSettingsEntity>({
    url: `/user/settings`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: userSettingsDto,
  });
};

export const getUserUpdateUserSettingsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userUpdateUserSettings>>,
    TError,
    { data: UserSettingsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userUpdateUserSettings>>,
  TError,
  { data: UserSettingsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userUpdateUserSettings>>,
    { data: UserSettingsDto }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateUserSettings(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserUpdateUserSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof userUpdateUserSettings>>
>;
export type UserUpdateUserSettingsMutationBody = UserSettingsDto;
export type UserUpdateUserSettingsMutationError = unknown;

export const useUserUpdateUserSettings = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userUpdateUserSettings>>,
    TError,
    { data: UserSettingsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userUpdateUserSettings>>,
  TError,
  { data: UserSettingsDto },
  TContext
> => {
  const mutationOptions = getUserUpdateUserSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userSetupUser = (userSetupDto: UserSetupDto) => {
  return customClient<UserEntity>({
    url: `/user/setup`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: userSetupDto,
  });
};

export const getUserSetupUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSetupUser>>,
    TError,
    { data: UserSetupDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userSetupUser>>,
  TError,
  { data: UserSetupDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userSetupUser>>,
    { data: UserSetupDto }
  > = (props) => {
    const { data } = props ?? {};

    return userSetupUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserSetupUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof userSetupUser>>
>;
export type UserSetupUserMutationBody = UserSetupDto;
export type UserSetupUserMutationError = unknown;

export const useUserSetupUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSetupUser>>,
    TError,
    { data: UserSetupDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userSetupUser>>,
  TError,
  { data: UserSetupDto },
  TContext
> => {
  const mutationOptions = getUserSetupUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userGetDashboard = (signal?: AbortSignal) => {
  return customClient<DashboardResponse>({
    url: `/user/dashboard`,
    method: "GET",
    signal,
  });
};

export const getUserGetDashboardQueryKey = () => {
  return [`/user/dashboard`] as const;
};

export const getUserGetDashboardQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetDashboard>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetDashboard>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetDashboardQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetDashboard>>
  > = ({ signal }) => userGetDashboard(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetDashboard>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetDashboardQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetDashboard>>
>;
export type UserGetDashboardQueryError = unknown;

export const useUserGetDashboard = <
  TData = Awaited<ReturnType<typeof userGetDashboard>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetDashboard>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetDashboardQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userSearchConnections = (
  searchConnectionsDto: SearchConnectionsDto,
) => {
  return customClient<UserEntity[]>({
    url: `/user/connections/search`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchConnectionsDto,
  });
};

export const getUserSearchConnectionsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSearchConnections>>,
    TError,
    { data: SearchConnectionsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userSearchConnections>>,
  TError,
  { data: SearchConnectionsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userSearchConnections>>,
    { data: SearchConnectionsDto }
  > = (props) => {
    const { data } = props ?? {};

    return userSearchConnections(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserSearchConnectionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof userSearchConnections>>
>;
export type UserSearchConnectionsMutationBody = SearchConnectionsDto;
export type UserSearchConnectionsMutationError = unknown;

export const useUserSearchConnections = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSearchConnections>>,
    TError,
    { data: SearchConnectionsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userSearchConnections>>,
  TError,
  { data: SearchConnectionsDto },
  TContext
> => {
  const mutationOptions = getUserSearchConnectionsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userGetMyConnections = (connectionsDto: ConnectionsDto) => {
  return customClient<ConnectionResponse>({
    url: `/user/connections`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: connectionsDto,
  });
};

export const getUserGetMyConnectionsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userGetMyConnections>>,
    TError,
    { data: ConnectionsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userGetMyConnections>>,
  TError,
  { data: ConnectionsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userGetMyConnections>>,
    { data: ConnectionsDto }
  > = (props) => {
    const { data } = props ?? {};

    return userGetMyConnections(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetMyConnectionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof userGetMyConnections>>
>;
export type UserGetMyConnectionsMutationBody = ConnectionsDto;
export type UserGetMyConnectionsMutationError = unknown;

export const useUserGetMyConnections = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userGetMyConnections>>,
    TError,
    { data: ConnectionsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userGetMyConnections>>,
  TError,
  { data: ConnectionsDto },
  TContext
> => {
  const mutationOptions = getUserGetMyConnectionsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userGetMyConnectionsCounts = (signal?: AbortSignal) => {
  return customClient<ConnectionCountResponse>({
    url: `/user/connections/counts`,
    method: "GET",
    signal,
  });
};

export const getUserGetMyConnectionsCountsQueryKey = () => {
  return [`/user/connections/counts`] as const;
};

export const getUserGetMyConnectionsCountsQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetMyConnectionsCounts>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyConnectionsCounts>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetMyConnectionsCountsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetMyConnectionsCounts>>
  > = ({ signal }) => userGetMyConnectionsCounts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyConnectionsCounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetMyConnectionsCountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetMyConnectionsCounts>>
>;
export type UserGetMyConnectionsCountsQueryError = unknown;

export const useUserGetMyConnectionsCounts = <
  TData = Awaited<ReturnType<typeof userGetMyConnectionsCounts>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyConnectionsCounts>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetMyConnectionsCountsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userGetMyActiveConnections = (
  page: number,
  pageSize: number,
  signal?: AbortSignal,
) => {
  return customClient<ConnectionResponse>({
    url: `/user/connections/active/${page}/${pageSize}`,
    method: "GET",
    signal,
  });
};

export const getUserGetMyActiveConnectionsQueryKey = (
  page: number,
  pageSize: number,
) => {
  return [`/user/connections/active/${page}/${pageSize}`] as const;
};

export const getUserGetMyActiveConnectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetMyActiveConnections>>,
  TError = unknown,
>(
  page: number,
  pageSize: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetMyActiveConnections>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserGetMyActiveConnectionsQueryKey(page, pageSize);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetMyActiveConnections>>
  > = ({ signal }) => userGetMyActiveConnections(page, pageSize, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(page && pageSize),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyActiveConnections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetMyActiveConnectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetMyActiveConnections>>
>;
export type UserGetMyActiveConnectionsQueryError = unknown;

export const useUserGetMyActiveConnections = <
  TData = Awaited<ReturnType<typeof userGetMyActiveConnections>>,
  TError = unknown,
>(
  page: number,
  pageSize: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetMyActiveConnections>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetMyActiveConnectionsQueryOptions(
    page,
    pageSize,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userGetMyPendingConnections = (signal?: AbortSignal) => {
  return customClient<ConnectionResponse>({
    url: `/user/connections/pending`,
    method: "GET",
    signal,
  });
};

export const getUserGetMyPendingConnectionsQueryKey = () => {
  return [`/user/connections/pending`] as const;
};

export const getUserGetMyPendingConnectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetMyPendingConnections>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyPendingConnections>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetMyPendingConnectionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetMyPendingConnections>>
  > = ({ signal }) => userGetMyPendingConnections(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyPendingConnections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetMyPendingConnectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetMyPendingConnections>>
>;
export type UserGetMyPendingConnectionsQueryError = unknown;

export const useUserGetMyPendingConnections = <
  TData = Awaited<ReturnType<typeof userGetMyPendingConnections>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyPendingConnections>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetMyPendingConnectionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userGetMyRequestedConnections = (signal?: AbortSignal) => {
  return customClient<ConnectionResponse>({
    url: `/user/connections/requested`,
    method: "GET",
    signal,
  });
};

export const getUserGetMyRequestedConnectionsQueryKey = () => {
  return [`/user/connections/requested`] as const;
};

export const getUserGetMyRequestedConnectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetMyRequestedConnections>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyRequestedConnections>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetMyRequestedConnectionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetMyRequestedConnections>>
  > = ({ signal }) => userGetMyRequestedConnections(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyRequestedConnections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetMyRequestedConnectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetMyRequestedConnections>>
>;
export type UserGetMyRequestedConnectionsQueryError = unknown;

export const useUserGetMyRequestedConnections = <
  TData = Awaited<ReturnType<typeof userGetMyRequestedConnections>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetMyRequestedConnections>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetMyRequestedConnectionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userGetBlockedUsers = (blockedUserDto: BlockedUserDto) => {
  return customClient<BlockedUserResponse>({
    url: `/user/blocked/list`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: blockedUserDto,
  });
};

export const getUserGetBlockedUsersMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userGetBlockedUsers>>,
    TError,
    { data: BlockedUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userGetBlockedUsers>>,
  TError,
  { data: BlockedUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userGetBlockedUsers>>,
    { data: BlockedUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return userGetBlockedUsers(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetBlockedUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof userGetBlockedUsers>>
>;
export type UserGetBlockedUsersMutationBody = BlockedUserDto;
export type UserGetBlockedUsersMutationError = unknown;

export const useUserGetBlockedUsers = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userGetBlockedUsers>>,
    TError,
    { data: BlockedUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userGetBlockedUsers>>,
  TError,
  { data: BlockedUserDto },
  TContext
> => {
  const mutationOptions = getUserGetBlockedUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userGetSuggestedPeople = (
  noOfSuggestedUsers: number,
  signal?: AbortSignal,
) => {
  return customClient<UserResponseEntity[]>({
    url: `/user/connections/suggested/${noOfSuggestedUsers}`,
    method: "GET",
    signal,
  });
};

export const getUserGetSuggestedPeopleQueryKey = (
  noOfSuggestedUsers: number,
) => {
  return [`/user/connections/suggested/${noOfSuggestedUsers}`] as const;
};

export const getUserGetSuggestedPeopleQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetSuggestedPeople>>,
  TError = unknown,
>(
  noOfSuggestedUsers: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetSuggestedPeople>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserGetSuggestedPeopleQueryKey(noOfSuggestedUsers);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetSuggestedPeople>>
  > = ({ signal }) => userGetSuggestedPeople(noOfSuggestedUsers, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!noOfSuggestedUsers,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetSuggestedPeople>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetSuggestedPeopleQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetSuggestedPeople>>
>;
export type UserGetSuggestedPeopleQueryError = unknown;

export const useUserGetSuggestedPeople = <
  TData = Awaited<ReturnType<typeof userGetSuggestedPeople>>,
  TError = unknown,
>(
  noOfSuggestedUsers: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetSuggestedPeople>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetSuggestedPeopleQueryOptions(
    noOfSuggestedUsers,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userChangePassword = (
  userChangePasswordDto: UserChangePasswordDto,
) => {
  return customClient<UserEntity>({
    url: `/user/change-password`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: userChangePasswordDto,
  });
};

export const getUserChangePasswordMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userChangePassword>>,
    TError,
    { data: UserChangePasswordDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userChangePassword>>,
  TError,
  { data: UserChangePasswordDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userChangePassword>>,
    { data: UserChangePasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return userChangePassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof userChangePassword>>
>;
export type UserChangePasswordMutationBody = UserChangePasswordDto;
export type UserChangePasswordMutationError = unknown;

export const useUserChangePassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userChangePassword>>,
    TError,
    { data: UserChangePasswordDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userChangePassword>>,
  TError,
  { data: UserChangePasswordDto },
  TContext
> => {
  const mutationOptions = getUserChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userSearchUsers = (searchUserDto: SearchUserDto) => {
  return customClient<PaginatedUsers>({
    url: `/user/search`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchUserDto,
  });
};

export const getUserSearchUsersMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSearchUsers>>,
    TError,
    { data: SearchUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userSearchUsers>>,
  TError,
  { data: SearchUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userSearchUsers>>,
    { data: SearchUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return userSearchUsers(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserSearchUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof userSearchUsers>>
>;
export type UserSearchUsersMutationBody = SearchUserDto;
export type UserSearchUsersMutationError = unknown;

export const useUserSearchUsers = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSearchUsers>>,
    TError,
    { data: SearchUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userSearchUsers>>,
  TError,
  { data: SearchUserDto },
  TContext
> => {
  const mutationOptions = getUserSearchUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userDeactivateUser = (params: UserDeactivateUserParams) => {
  return customClient<string>({
    url: `/user/deactivate`,
    method: "DELETE",
    params,
  });
};

export const getUserDeactivateUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userDeactivateUser>>,
    TError,
    { params: UserDeactivateUserParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userDeactivateUser>>,
  TError,
  { params: UserDeactivateUserParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userDeactivateUser>>,
    { params: UserDeactivateUserParams }
  > = (props) => {
    const { params } = props ?? {};

    return userDeactivateUser(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserDeactivateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof userDeactivateUser>>
>;

export type UserDeactivateUserMutationError = unknown;

export const useUserDeactivateUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userDeactivateUser>>,
    TError,
    { params: UserDeactivateUserParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userDeactivateUser>>,
  TError,
  { params: UserDeactivateUserParams },
  TContext
> => {
  const mutationOptions = getUserDeactivateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userRequestConnection = (userId: number) => {
  return customClient<string>({
    url: `/user/connection/request/${userId}`,
    method: "POST",
  });
};

export const getUserRequestConnectionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userRequestConnection>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userRequestConnection>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userRequestConnection>>,
    { userId: number }
  > = (props) => {
    const { userId } = props ?? {};

    return userRequestConnection(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserRequestConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof userRequestConnection>>
>;

export type UserRequestConnectionMutationError = unknown;

export const useUserRequestConnection = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userRequestConnection>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userRequestConnection>>,
  TError,
  { userId: number },
  TContext
> => {
  const mutationOptions = getUserRequestConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userResendRequestConnection = (userId: number) => {
  return customClient<string>({
    url: `/user/connection/resend/request/${userId}`,
    method: "POST",
  });
};

export const getUserResendRequestConnectionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userResendRequestConnection>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userResendRequestConnection>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userResendRequestConnection>>,
    { userId: number }
  > = (props) => {
    const { userId } = props ?? {};

    return userResendRequestConnection(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserResendRequestConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof userResendRequestConnection>>
>;

export type UserResendRequestConnectionMutationError = unknown;

export const useUserResendRequestConnection = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userResendRequestConnection>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userResendRequestConnection>>,
  TError,
  { userId: number },
  TContext
> => {
  const mutationOptions =
    getUserResendRequestConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userAcceptConnection = (requestedUserId: number) => {
  return customClient<string>({
    url: `/user/connection/accept/${requestedUserId}`,
    method: "POST",
  });
};

export const getUserAcceptConnectionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userAcceptConnection>>,
    TError,
    { requestedUserId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userAcceptConnection>>,
  TError,
  { requestedUserId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userAcceptConnection>>,
    { requestedUserId: number }
  > = (props) => {
    const { requestedUserId } = props ?? {};

    return userAcceptConnection(requestedUserId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserAcceptConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof userAcceptConnection>>
>;

export type UserAcceptConnectionMutationError = unknown;

export const useUserAcceptConnection = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userAcceptConnection>>,
    TError,
    { requestedUserId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userAcceptConnection>>,
  TError,
  { requestedUserId: number },
  TContext
> => {
  const mutationOptions = getUserAcceptConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userCancelConnection = (connectionUserId: number) => {
  return customClient<string>({
    url: `/user/connection/cancel/${connectionUserId}`,
    method: "DELETE",
  });
};

export const getUserCancelConnectionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userCancelConnection>>,
    TError,
    { connectionUserId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userCancelConnection>>,
  TError,
  { connectionUserId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userCancelConnection>>,
    { connectionUserId: number }
  > = (props) => {
    const { connectionUserId } = props ?? {};

    return userCancelConnection(connectionUserId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserCancelConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof userCancelConnection>>
>;

export type UserCancelConnectionMutationError = unknown;

export const useUserCancelConnection = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userCancelConnection>>,
    TError,
    { connectionUserId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userCancelConnection>>,
  TError,
  { connectionUserId: number },
  TContext
> => {
  const mutationOptions = getUserCancelConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userBlockUserToggle = (blockUserToggleDto: BlockUserToggleDto) => {
  return customClient<string>({
    url: `/user/block/toggle`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: blockUserToggleDto,
  });
};

export const getUserBlockUserToggleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userBlockUserToggle>>,
    TError,
    { data: BlockUserToggleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userBlockUserToggle>>,
  TError,
  { data: BlockUserToggleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userBlockUserToggle>>,
    { data: BlockUserToggleDto }
  > = (props) => {
    const { data } = props ?? {};

    return userBlockUserToggle(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserBlockUserToggleMutationResult = NonNullable<
  Awaited<ReturnType<typeof userBlockUserToggle>>
>;
export type UserBlockUserToggleMutationBody = BlockUserToggleDto;
export type UserBlockUserToggleMutationError = unknown;

export const useUserBlockUserToggle = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userBlockUserToggle>>,
    TError,
    { data: BlockUserToggleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userBlockUserToggle>>,
  TError,
  { data: BlockUserToggleDto },
  TContext
> => {
  const mutationOptions = getUserBlockUserToggleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userReportUser = (reportUserDto: ReportUserDto) => {
  return customClient<string>({
    url: `/user/report`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: reportUserDto,
  });
};

export const getUserReportUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userReportUser>>,
    TError,
    { data: ReportUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userReportUser>>,
  TError,
  { data: ReportUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userReportUser>>,
    { data: ReportUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return userReportUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserReportUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof userReportUser>>
>;
export type UserReportUserMutationBody = ReportUserDto;
export type UserReportUserMutationError = unknown;

export const useUserReportUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userReportUser>>,
    TError,
    { data: ReportUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userReportUser>>,
  TError,
  { data: ReportUserDto },
  TContext
> => {
  const mutationOptions = getUserReportUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userGetUserMetaData = (signal?: AbortSignal) => {
  return customClient<UserMetadata>({
    url: `/user/metadata`,
    method: "GET",
    signal,
  });
};

export const getUserGetUserMetaDataQueryKey = () => {
  return [`/user/metadata`] as const;
};

export const getUserGetUserMetaDataQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetUserMetaData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetUserMetaData>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetUserMetaDataQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetUserMetaData>>
  > = ({ signal }) => userGetUserMetaData(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetUserMetaData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetUserMetaDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetUserMetaData>>
>;
export type UserGetUserMetaDataQueryError = unknown;

export const useUserGetUserMetaData = <
  TData = Awaited<ReturnType<typeof userGetUserMetaData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userGetUserMetaData>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetUserMetaDataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userGetOrganizationRoles = (
  userSlug: string,
  signal?: AbortSignal,
) => {
  return customClient<OrganizationUserRoleResponse[]>({
    url: `/user/manage-permissions/${userSlug}`,
    method: "GET",
    signal,
  });
};

export const getUserGetOrganizationRolesQueryKey = (userSlug: string) => {
  return [`/user/manage-permissions/${userSlug}`] as const;
};

export const getUserGetOrganizationRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof userGetOrganizationRoles>>,
  TError = unknown,
>(
  userSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetOrganizationRoles>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetOrganizationRolesQueryKey(userSlug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userGetOrganizationRoles>>
  > = ({ signal }) => userGetOrganizationRoles(userSlug, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userSlug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userGetOrganizationRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetOrganizationRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGetOrganizationRoles>>
>;
export type UserGetOrganizationRolesQueryError = unknown;

export const useUserGetOrganizationRoles = <
  TData = Awaited<ReturnType<typeof userGetOrganizationRoles>>,
  TError = unknown,
>(
  userSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userGetOrganizationRoles>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserGetOrganizationRolesQueryOptions(
    userSlug,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const userUpdateOrganizationRoles = (
  createOrganizationUserRoleDto: CreateOrganizationUserRoleDto,
) => {
  return customClient<OrganizationUserRoleResponse[]>({
    url: `/user/manage-permissions`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createOrganizationUserRoleDto,
  });
};

export const getUserUpdateOrganizationRolesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userUpdateOrganizationRoles>>,
    TError,
    { data: CreateOrganizationUserRoleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userUpdateOrganizationRoles>>,
  TError,
  { data: CreateOrganizationUserRoleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userUpdateOrganizationRoles>>,
    { data: CreateOrganizationUserRoleDto }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateOrganizationRoles(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserUpdateOrganizationRolesMutationResult = NonNullable<
  Awaited<ReturnType<typeof userUpdateOrganizationRoles>>
>;
export type UserUpdateOrganizationRolesMutationBody =
  CreateOrganizationUserRoleDto;
export type UserUpdateOrganizationRolesMutationError = unknown;

export const useUserUpdateOrganizationRoles = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userUpdateOrganizationRoles>>,
    TError,
    { data: CreateOrganizationUserRoleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userUpdateOrganizationRoles>>,
  TError,
  { data: CreateOrganizationUserRoleDto },
  TContext
> => {
  const mutationOptions =
    getUserUpdateOrganizationRolesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userRemoveEmployee = (userSlug: string) => {
  return customClient<void>({
    url: `/user/remove-from-organization/${userSlug}`,
    method: "DELETE",
  });
};

export const getUserRemoveEmployeeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userRemoveEmployee>>,
    TError,
    { userSlug: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userRemoveEmployee>>,
  TError,
  { userSlug: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userRemoveEmployee>>,
    { userSlug: string }
  > = (props) => {
    const { userSlug } = props ?? {};

    return userRemoveEmployee(userSlug);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserRemoveEmployeeMutationResult = NonNullable<
  Awaited<ReturnType<typeof userRemoveEmployee>>
>;

export type UserRemoveEmployeeMutationError = unknown;

export const useUserRemoveEmployee = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userRemoveEmployee>>,
    TError,
    { userSlug: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userRemoveEmployee>>,
  TError,
  { userSlug: string },
  TContext
> => {
  const mutationOptions = getUserRemoveEmployeeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userEnableTwoFactor = (twoFactorDto: TwoFactorDto) => {
  return customClient<GeneralResponse>({
    url: `/user/two-factor/enable`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: twoFactorDto,
  });
};

export const getUserEnableTwoFactorMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userEnableTwoFactor>>,
    TError,
    { data: TwoFactorDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userEnableTwoFactor>>,
  TError,
  { data: TwoFactorDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userEnableTwoFactor>>,
    { data: TwoFactorDto }
  > = (props) => {
    const { data } = props ?? {};

    return userEnableTwoFactor(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserEnableTwoFactorMutationResult = NonNullable<
  Awaited<ReturnType<typeof userEnableTwoFactor>>
>;
export type UserEnableTwoFactorMutationBody = TwoFactorDto;
export type UserEnableTwoFactorMutationError = unknown;

export const useUserEnableTwoFactor = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userEnableTwoFactor>>,
    TError,
    { data: TwoFactorDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userEnableTwoFactor>>,
  TError,
  { data: TwoFactorDto },
  TContext
> => {
  const mutationOptions = getUserEnableTwoFactorMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userVerifyTwoFactor = (twoFactorVerifyDto: TwoFactorVerifyDto) => {
  return customClient<GeneralResponse>({
    url: `/user/two-factor/verify`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: twoFactorVerifyDto,
  });
};

export const getUserVerifyTwoFactorMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userVerifyTwoFactor>>,
    TError,
    { data: TwoFactorVerifyDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userVerifyTwoFactor>>,
  TError,
  { data: TwoFactorVerifyDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userVerifyTwoFactor>>,
    { data: TwoFactorVerifyDto }
  > = (props) => {
    const { data } = props ?? {};

    return userVerifyTwoFactor(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserVerifyTwoFactorMutationResult = NonNullable<
  Awaited<ReturnType<typeof userVerifyTwoFactor>>
>;
export type UserVerifyTwoFactorMutationBody = TwoFactorVerifyDto;
export type UserVerifyTwoFactorMutationError = unknown;

export const useUserVerifyTwoFactor = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userVerifyTwoFactor>>,
    TError,
    { data: TwoFactorVerifyDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userVerifyTwoFactor>>,
  TError,
  { data: TwoFactorVerifyDto },
  TContext
> => {
  const mutationOptions = getUserVerifyTwoFactorMutationOptions(options);

  return useMutation(mutationOptions);
};

export const userDisableTwoFactor = () => {
  return customClient<GeneralResponse>({
    url: `/user/two-factor/disable`,
    method: "PATCH",
  });
};

export const getUserDisableTwoFactorMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userDisableTwoFactor>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userDisableTwoFactor>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userDisableTwoFactor>>,
    void
  > = () => {
    return userDisableTwoFactor();
  };

  return { mutationFn, ...mutationOptions };
};

export type UserDisableTwoFactorMutationResult = NonNullable<
  Awaited<ReturnType<typeof userDisableTwoFactor>>
>;

export type UserDisableTwoFactorMutationError = unknown;

export const useUserDisableTwoFactor = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userDisableTwoFactor>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof userDisableTwoFactor>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getUserDisableTwoFactorMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationCreateOrganization = (
  createOrganizationDto: CreateOrganizationDto,
) => {
  return customClient<OrganizationEntity>({
    url: `/organization`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createOrganizationDto,
  });
};

export const getOrganizationCreateOrganizationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateOrganization>>,
    TError,
    { data: CreateOrganizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationCreateOrganization>>,
  TError,
  { data: CreateOrganizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationCreateOrganization>>,
    { data: CreateOrganizationDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationCreateOrganization(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationCreateOrganizationMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationCreateOrganization>>
>;
export type OrganizationCreateOrganizationMutationBody = CreateOrganizationDto;
export type OrganizationCreateOrganizationMutationError = unknown;

export const useOrganizationCreateOrganization = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateOrganization>>,
    TError,
    { data: CreateOrganizationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationCreateOrganization>>,
  TError,
  { data: CreateOrganizationDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationCreateOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetOrganizationUserRoles = (signal?: AbortSignal) => {
  return customClient<OrganizationUserRoleResponse[]>({
    url: `/organization/user/roles`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetOrganizationUserRolesQueryKey = () => {
  return [`/organization/user/roles`] as const;
};

export const getOrganizationGetOrganizationUserRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationUserRoles>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationUserRoles>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetOrganizationUserRolesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetOrganizationUserRoles>>
  > = ({ signal }) => organizationGetOrganizationUserRoles(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationUserRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetOrganizationUserRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetOrganizationUserRoles>>
>;
export type OrganizationGetOrganizationUserRolesQueryError = unknown;

export const useOrganizationGetOrganizationUserRoles = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationUserRoles>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationUserRoles>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getOrganizationGetOrganizationUserRolesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationGetOrganizationProfile = (signal?: AbortSignal) => {
  return customClient<OrganizationProfileResponse>({
    url: `/organization/profile`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetOrganizationProfileQueryKey = () => {
  return [`/organization/profile`] as const;
};

export const getOrganizationGetOrganizationProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationProfile>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationProfile>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetOrganizationProfileQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetOrganizationProfile>>
  > = ({ signal }) => organizationGetOrganizationProfile(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetOrganizationProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetOrganizationProfile>>
>;
export type OrganizationGetOrganizationProfileQueryError = unknown;

export const useOrganizationGetOrganizationProfile = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationProfile>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationProfile>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getOrganizationGetOrganizationProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationUpdateOrganizationProfile = (
  organizationUpdateProfileDto: OrganizationUpdateProfileDto,
) => {
  return customClient<void>({
    url: `/organization/profile`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: organizationUpdateProfileDto,
  });
};

export const getOrganizationUpdateOrganizationProfileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationUpdateOrganizationProfile>>,
    TError,
    { data: OrganizationUpdateProfileDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationUpdateOrganizationProfile>>,
  TError,
  { data: OrganizationUpdateProfileDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationUpdateOrganizationProfile>>,
    { data: OrganizationUpdateProfileDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationUpdateOrganizationProfile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationUpdateOrganizationProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationUpdateOrganizationProfile>>
>;
export type OrganizationUpdateOrganizationProfileMutationBody =
  OrganizationUpdateProfileDto;
export type OrganizationUpdateOrganizationProfileMutationError = unknown;

export const useOrganizationUpdateOrganizationProfile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationUpdateOrganizationProfile>>,
    TError,
    { data: OrganizationUpdateProfileDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationUpdateOrganizationProfile>>,
  TError,
  { data: OrganizationUpdateProfileDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationUpdateOrganizationProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetOrganizationDashboard = (signal?: AbortSignal) => {
  return customClient<OrganizationDashboardResponse>({
    url: `/organization/dashboard`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetOrganizationDashboardQueryKey = () => {
  return [`/organization/dashboard`] as const;
};

export const getOrganizationGetOrganizationDashboardQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationDashboard>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationDashboard>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetOrganizationDashboardQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetOrganizationDashboard>>
  > = ({ signal }) => organizationGetOrganizationDashboard(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationDashboard>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetOrganizationDashboardQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetOrganizationDashboard>>
>;
export type OrganizationGetOrganizationDashboardQueryError = unknown;

export const useOrganizationGetOrganizationDashboard = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationDashboard>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationDashboard>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getOrganizationGetOrganizationDashboardQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationGetOrganizationExclusiveWebinars = (
  organizationExclusiveWebinarsDto: OrganizationExclusiveWebinarsDto,
) => {
  return customClient<WpPostsResponse>({
    url: `/organization/exclusive-webinars`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationExclusiveWebinarsDto,
  });
};

export const getOrganizationGetOrganizationExclusiveWebinarsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationExclusiveWebinars>>,
    TError,
    { data: OrganizationExclusiveWebinarsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationGetOrganizationExclusiveWebinars>>,
  TError,
  { data: OrganizationExclusiveWebinarsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationGetOrganizationExclusiveWebinars>>,
    { data: OrganizationExclusiveWebinarsDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationGetOrganizationExclusiveWebinars(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationGetOrganizationExclusiveWebinarsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof organizationGetOrganizationExclusiveWebinars>>
  >;
export type OrganizationGetOrganizationExclusiveWebinarsMutationBody =
  OrganizationExclusiveWebinarsDto;
export type OrganizationGetOrganizationExclusiveWebinarsMutationError = unknown;

export const useOrganizationGetOrganizationExclusiveWebinars = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationExclusiveWebinars>>,
    TError,
    { data: OrganizationExclusiveWebinarsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationGetOrganizationExclusiveWebinars>>,
  TError,
  { data: OrganizationExclusiveWebinarsDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationGetOrganizationExclusiveWebinarsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationOrganizationJobPerformanceDashboard = (
  organizationJobPerformanceDto: OrganizationJobPerformanceDto,
) => {
  return customClient<OrganizationJobPerformanceDashboardResponse>({
    url: `/organization/job-performance-metrics`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationJobPerformanceDto,
  });
};

export const getOrganizationOrganizationJobPerformanceDashboardMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof organizationOrganizationJobPerformanceDashboard>
      >,
      TError,
      { data: OrganizationJobPerformanceDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof organizationOrganizationJobPerformanceDashboard>>,
    TError,
    { data: OrganizationJobPerformanceDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof organizationOrganizationJobPerformanceDashboard>
      >,
      { data: OrganizationJobPerformanceDto }
    > = (props) => {
      const { data } = props ?? {};

      return organizationOrganizationJobPerformanceDashboard(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type OrganizationOrganizationJobPerformanceDashboardMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof organizationOrganizationJobPerformanceDashboard>>
  >;
export type OrganizationOrganizationJobPerformanceDashboardMutationBody =
  OrganizationJobPerformanceDto;
export type OrganizationOrganizationJobPerformanceDashboardMutationError =
  unknown;

export const useOrganizationOrganizationJobPerformanceDashboard = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationOrganizationJobPerformanceDashboard>>,
    TError,
    { data: OrganizationJobPerformanceDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationOrganizationJobPerformanceDashboard>>,
  TError,
  { data: OrganizationJobPerformanceDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationOrganizationJobPerformanceDashboardMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationOrganizationJobClicksByCountry = (
  organizationJobClicksByCountryDto: OrganizationJobClicksByCountryDto,
) => {
  return customClient<OrganizationJobClicksByCountryResponse>({
    url: `/organization/job-performance-metrics/clicks-by-country`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationJobClicksByCountryDto,
  });
};

export const getOrganizationOrganizationJobClicksByCountryMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationOrganizationJobClicksByCountry>>,
    TError,
    { data: OrganizationJobClicksByCountryDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationOrganizationJobClicksByCountry>>,
  TError,
  { data: OrganizationJobClicksByCountryDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationOrganizationJobClicksByCountry>>,
    { data: OrganizationJobClicksByCountryDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationOrganizationJobClicksByCountry(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationOrganizationJobClicksByCountryMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof organizationOrganizationJobClicksByCountry>>
  >;
export type OrganizationOrganizationJobClicksByCountryMutationBody =
  OrganizationJobClicksByCountryDto;
export type OrganizationOrganizationJobClicksByCountryMutationError = unknown;

export const useOrganizationOrganizationJobClicksByCountry = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationOrganizationJobClicksByCountry>>,
    TError,
    { data: OrganizationJobClicksByCountryDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationOrganizationJobClicksByCountry>>,
  TError,
  { data: OrganizationJobClicksByCountryDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationOrganizationJobClicksByCountryMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationOrganizationLegacyJobPerformanceDashboard = (
  organizationJobPerformanceDto: OrganizationJobPerformanceDto,
) => {
  return customClient<OrganizationJobPerformanceDashboardResponse>({
    url: `/organization/legacy/job-performance-metrics`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationJobPerformanceDto,
  });
};

export const getOrganizationOrganizationLegacyJobPerformanceDashboardMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof organizationOrganizationLegacyJobPerformanceDashboard>
      >,
      TError,
      { data: OrganizationJobPerformanceDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof organizationOrganizationLegacyJobPerformanceDashboard>
    >,
    TError,
    { data: OrganizationJobPerformanceDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof organizationOrganizationLegacyJobPerformanceDashboard>
      >,
      { data: OrganizationJobPerformanceDto }
    > = (props) => {
      const { data } = props ?? {};

      return organizationOrganizationLegacyJobPerformanceDashboard(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type OrganizationOrganizationLegacyJobPerformanceDashboardMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof organizationOrganizationLegacyJobPerformanceDashboard>
    >
  >;
export type OrganizationOrganizationLegacyJobPerformanceDashboardMutationBody =
  OrganizationJobPerformanceDto;
export type OrganizationOrganizationLegacyJobPerformanceDashboardMutationError =
  unknown;

export const useOrganizationOrganizationLegacyJobPerformanceDashboard = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof organizationOrganizationLegacyJobPerformanceDashboard>
    >,
    TError,
    { data: OrganizationJobPerformanceDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof organizationOrganizationLegacyJobPerformanceDashboard>
  >,
  TError,
  { data: OrganizationJobPerformanceDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationOrganizationLegacyJobPerformanceDashboardMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};

export const organizationOrganizationJobPerformanceReport = (
  organizationJobPerformanceReportDto: OrganizationJobPerformanceReportDto,
) => {
  return customClient<JobData[]>({
    url: `/organization/job-performance-metrics/download-report`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationJobPerformanceReportDto,
  });
};

export const getOrganizationOrganizationJobPerformanceReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationOrganizationJobPerformanceReport>>,
    TError,
    { data: OrganizationJobPerformanceReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationOrganizationJobPerformanceReport>>,
  TError,
  { data: OrganizationJobPerformanceReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationOrganizationJobPerformanceReport>>,
    { data: OrganizationJobPerformanceReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationOrganizationJobPerformanceReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationOrganizationJobPerformanceReportMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof organizationOrganizationJobPerformanceReport>>
  >;
export type OrganizationOrganizationJobPerformanceReportMutationBody =
  OrganizationJobPerformanceReportDto;
export type OrganizationOrganizationJobPerformanceReportMutationError = unknown;

export const useOrganizationOrganizationJobPerformanceReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationOrganizationJobPerformanceReport>>,
    TError,
    { data: OrganizationJobPerformanceReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationOrganizationJobPerformanceReport>>,
  TError,
  { data: OrganizationJobPerformanceReportDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationOrganizationJobPerformanceReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationUpdateOrganizationElasticSearch = (
  signal?: AbortSignal,
) => {
  return customClient<void>({
    url: `/organization/es/update`,
    method: "GET",
    signal,
  });
};

export const getOrganizationUpdateOrganizationElasticSearchQueryKey = () => {
  return [`/organization/es/update`] as const;
};

export const getOrganizationUpdateOrganizationElasticSearchQueryOptions = <
  TData = Awaited<
    ReturnType<typeof organizationUpdateOrganizationElasticSearch>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationUpdateOrganizationElasticSearch>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getOrganizationUpdateOrganizationElasticSearchQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationUpdateOrganizationElasticSearch>>
  > = ({ signal }) => organizationUpdateOrganizationElasticSearch(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationUpdateOrganizationElasticSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationUpdateOrganizationElasticSearchQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof organizationUpdateOrganizationElasticSearch>>
  >;
export type OrganizationUpdateOrganizationElasticSearchQueryError = unknown;

export const useOrganizationUpdateOrganizationElasticSearch = <
  TData = Awaited<
    ReturnType<typeof organizationUpdateOrganizationElasticSearch>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationUpdateOrganizationElasticSearch>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getOrganizationUpdateOrganizationElasticSearchQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationFindAllOrganizations = (
  searchOrganizationDto: SearchOrganizationDto,
) => {
  return customClient<OrganizationResponse>({
    url: `/organization/list`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchOrganizationDto,
  });
};

export const getOrganizationFindAllOrganizationsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationFindAllOrganizations>>,
    TError,
    { data: SearchOrganizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationFindAllOrganizations>>,
  TError,
  { data: SearchOrganizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationFindAllOrganizations>>,
    { data: SearchOrganizationDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationFindAllOrganizations(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationFindAllOrganizationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationFindAllOrganizations>>
>;
export type OrganizationFindAllOrganizationsMutationBody =
  SearchOrganizationDto;
export type OrganizationFindAllOrganizationsMutationError = unknown;

export const useOrganizationFindAllOrganizations = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationFindAllOrganizations>>,
    TError,
    { data: SearchOrganizationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationFindAllOrganizations>>,
  TError,
  { data: SearchOrganizationDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationFindAllOrganizationsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationFindAllOrganizationsWithBasicData = (
  searchOrganizationDto: SearchOrganizationDto,
) => {
  return customClient<OrganizationBasicDataResponse>({
    url: `/organization/basic/list`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchOrganizationDto,
  });
};

export const getOrganizationFindAllOrganizationsWithBasicDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationFindAllOrganizationsWithBasicData>>,
    TError,
    { data: SearchOrganizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationFindAllOrganizationsWithBasicData>>,
  TError,
  { data: SearchOrganizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationFindAllOrganizationsWithBasicData>>,
    { data: SearchOrganizationDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationFindAllOrganizationsWithBasicData(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationFindAllOrganizationsWithBasicDataMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof organizationFindAllOrganizationsWithBasicData>>
  >;
export type OrganizationFindAllOrganizationsWithBasicDataMutationBody =
  SearchOrganizationDto;
export type OrganizationFindAllOrganizationsWithBasicDataMutationError =
  unknown;

export const useOrganizationFindAllOrganizationsWithBasicData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationFindAllOrganizationsWithBasicData>>,
    TError,
    { data: SearchOrganizationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationFindAllOrganizationsWithBasicData>>,
  TError,
  { data: SearchOrganizationDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationFindAllOrganizationsWithBasicDataMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetOrganizationMetaData = (signal?: AbortSignal) => {
  return customClient<OrganizationMetadata>({
    url: `/organization/metadata`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetOrganizationMetaDataQueryKey = () => {
  return [`/organization/metadata`] as const;
};

export const getOrganizationGetOrganizationMetaDataQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationMetaData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationMetaData>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetOrganizationMetaDataQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetOrganizationMetaData>>
  > = ({ signal }) => organizationGetOrganizationMetaData(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationMetaData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetOrganizationMetaDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetOrganizationMetaData>>
>;
export type OrganizationGetOrganizationMetaDataQueryError = unknown;

export const useOrganizationGetOrganizationMetaData = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationMetaData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationMetaData>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getOrganizationGetOrganizationMetaDataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationGetOrganizationInfoPage = (
  idOrSlug: string,
  signal?: AbortSignal,
) => {
  return customClient<OrganizationGetOrganizationInfoPage200>({
    url: `/organization/wp/info/${idOrSlug}`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetOrganizationInfoPageQueryKey = (
  idOrSlug: string,
) => {
  return [`/organization/wp/info/${idOrSlug}`] as const;
};

export const getOrganizationGetOrganizationInfoPageQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationInfoPage>>,
  TError = unknown,
>(
  idOrSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organizationGetOrganizationInfoPage>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getOrganizationGetOrganizationInfoPageQueryKey(idOrSlug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetOrganizationInfoPage>>
  > = ({ signal }) => organizationGetOrganizationInfoPage(idOrSlug, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!idOrSlug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationInfoPage>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetOrganizationInfoPageQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetOrganizationInfoPage>>
>;
export type OrganizationGetOrganizationInfoPageQueryError = unknown;

export const useOrganizationGetOrganizationInfoPage = <
  TData = Awaited<ReturnType<typeof organizationGetOrganizationInfoPage>>,
  TError = unknown,
>(
  idOrSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organizationGetOrganizationInfoPage>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetOrganizationInfoPageQueryOptions(
    idOrSlug,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationGetNewsMetaData = (signal?: AbortSignal) => {
  return customClient<NewsMetadata>({
    url: `/organization/wp/news/metadata`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetNewsMetaDataQueryKey = () => {
  return [`/organization/wp/news/metadata`] as const;
};

export const getOrganizationGetNewsMetaDataQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetNewsMetaData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetNewsMetaData>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetNewsMetaDataQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetNewsMetaData>>
  > = ({ signal }) => organizationGetNewsMetaData(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetNewsMetaData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetNewsMetaDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetNewsMetaData>>
>;
export type OrganizationGetNewsMetaDataQueryError = unknown;

export const useOrganizationGetNewsMetaData = <
  TData = Awaited<ReturnType<typeof organizationGetNewsMetaData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetNewsMetaData>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetNewsMetaDataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationGetWorkPride = (signal?: AbortSignal) => {
  return customClient<WorkPrideResponse>({
    url: `/organization/workpride`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetWorkPrideQueryKey = () => {
  return [`/organization/workpride`] as const;
};

export const getOrganizationGetWorkPrideQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetWorkPride>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetWorkPride>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetWorkPrideQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetWorkPride>>
  > = ({ signal }) => organizationGetWorkPride(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetWorkPride>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetWorkPrideQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetWorkPride>>
>;
export type OrganizationGetWorkPrideQueryError = unknown;

export const useOrganizationGetWorkPride = <
  TData = Awaited<ReturnType<typeof organizationGetWorkPride>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetWorkPride>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetWorkPrideQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationGetWorkPrideBrochure = (
  createWorkPrideDto: CreateWorkPrideDto,
) => {
  return customClient<OrganizationGetWorkPrideBrochure201>({
    url: `/organization/workpride/brochure`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createWorkPrideDto,
  });
};

export const getOrganizationGetWorkPrideBrochureMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetWorkPrideBrochure>>,
    TError,
    { data: CreateWorkPrideDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationGetWorkPrideBrochure>>,
  TError,
  { data: CreateWorkPrideDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationGetWorkPrideBrochure>>,
    { data: CreateWorkPrideDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationGetWorkPrideBrochure(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationGetWorkPrideBrochureMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetWorkPrideBrochure>>
>;
export type OrganizationGetWorkPrideBrochureMutationBody = CreateWorkPrideDto;
export type OrganizationGetWorkPrideBrochureMutationError = unknown;

export const useOrganizationGetWorkPrideBrochure = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetWorkPrideBrochure>>,
    TError,
    { data: CreateWorkPrideDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationGetWorkPrideBrochure>>,
  TError,
  { data: CreateWorkPrideDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationGetWorkPrideBrochureMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationCreateNewsLead = (
  createNewsAttachmentLeadDto: CreateNewsAttachmentLeadDto,
) => {
  return customClient<string>({
    url: `/organization/news/lead`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createNewsAttachmentLeadDto,
  });
};

export const getOrganizationCreateNewsLeadMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateNewsLead>>,
    TError,
    { data: CreateNewsAttachmentLeadDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationCreateNewsLead>>,
  TError,
  { data: CreateNewsAttachmentLeadDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationCreateNewsLead>>,
    { data: CreateNewsAttachmentLeadDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationCreateNewsLead(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationCreateNewsLeadMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationCreateNewsLead>>
>;
export type OrganizationCreateNewsLeadMutationBody =
  CreateNewsAttachmentLeadDto;
export type OrganizationCreateNewsLeadMutationError = unknown;

export const useOrganizationCreateNewsLead = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateNewsLead>>,
    TError,
    { data: CreateNewsAttachmentLeadDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationCreateNewsLead>>,
  TError,
  { data: CreateNewsAttachmentLeadDto },
  TContext
> => {
  const mutationOptions = getOrganizationCreateNewsLeadMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationCreateWorkfairLead = (
  createWorkfairLeadDto: CreateWorkfairLeadDto,
) => {
  return customClient<WorkFairEntity>({
    url: `/organization/workfair/lead`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createWorkfairLeadDto,
  });
};

export const getOrganizationCreateWorkfairLeadMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateWorkfairLead>>,
    TError,
    { data: CreateWorkfairLeadDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationCreateWorkfairLead>>,
  TError,
  { data: CreateWorkfairLeadDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationCreateWorkfairLead>>,
    { data: CreateWorkfairLeadDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationCreateWorkfairLead(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationCreateWorkfairLeadMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationCreateWorkfairLead>>
>;
export type OrganizationCreateWorkfairLeadMutationBody = CreateWorkfairLeadDto;
export type OrganizationCreateWorkfairLeadMutationError = unknown;

export const useOrganizationCreateWorkfairLead = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateWorkfairLead>>,
    TError,
    { data: CreateWorkfairLeadDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationCreateWorkfairLead>>,
  TError,
  { data: CreateWorkfairLeadDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationCreateWorkfairLeadMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetNews = (newsWpPostsDto: NewsWpPostsDto) => {
  return customClient<WpPostsResponse>({
    url: `/organization/wp/news`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: newsWpPostsDto,
  });
};

export const getOrganizationGetNewsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetNews>>,
    TError,
    { data: NewsWpPostsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationGetNews>>,
  TError,
  { data: NewsWpPostsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationGetNews>>,
    { data: NewsWpPostsDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationGetNews(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationGetNewsMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetNews>>
>;
export type OrganizationGetNewsMutationBody = NewsWpPostsDto;
export type OrganizationGetNewsMutationError = unknown;

export const useOrganizationGetNews = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetNews>>,
    TError,
    { data: NewsWpPostsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationGetNews>>,
  TError,
  { data: NewsWpPostsDto },
  TContext
> => {
  const mutationOptions = getOrganizationGetNewsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetNewsWithBasicData = (
  newsWpPostsDto: NewsWpPostsDto,
) => {
  return customClient<WpPostsBasicDataResponse>({
    url: `/organization/wp/basic/news`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: newsWpPostsDto,
  });
};

export const getOrganizationGetNewsWithBasicDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetNewsWithBasicData>>,
    TError,
    { data: NewsWpPostsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationGetNewsWithBasicData>>,
  TError,
  { data: NewsWpPostsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationGetNewsWithBasicData>>,
    { data: NewsWpPostsDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationGetNewsWithBasicData(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationGetNewsWithBasicDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetNewsWithBasicData>>
>;
export type OrganizationGetNewsWithBasicDataMutationBody = NewsWpPostsDto;
export type OrganizationGetNewsWithBasicDataMutationError = unknown;

export const useOrganizationGetNewsWithBasicData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetNewsWithBasicData>>,
    TError,
    { data: NewsWpPostsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationGetNewsWithBasicData>>,
  TError,
  { data: NewsWpPostsDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationGetNewsWithBasicDataMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetWpOrganizations = (signal?: AbortSignal) => {
  return customClient<OrganizationEntity[]>({
    url: `/organization/wp/organizations`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetWpOrganizationsQueryKey = () => {
  return [`/organization/wp/organizations`] as const;
};

export const getOrganizationGetWpOrganizationsQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetWpOrganizations>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetWpOrganizations>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetWpOrganizationsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetWpOrganizations>>
  > = ({ signal }) => organizationGetWpOrganizations(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetWpOrganizations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetWpOrganizationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetWpOrganizations>>
>;
export type OrganizationGetWpOrganizationsQueryError = unknown;

export const useOrganizationGetWpOrganizations = <
  TData = Awaited<ReturnType<typeof organizationGetWpOrganizations>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetWpOrganizations>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetWpOrganizationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationGetRelatedNews = (
  idOrSlug: string,
  signal?: AbortSignal,
) => {
  return customClient<WpPostEntity[]>({
    url: `/organization/wp/news/related/${idOrSlug}`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetRelatedNewsQueryKey = (idOrSlug: string) => {
  return [`/organization/wp/news/related/${idOrSlug}`] as const;
};

export const getOrganizationGetRelatedNewsQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetRelatedNews>>,
  TError = unknown,
>(
  idOrSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organizationGetRelatedNews>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetRelatedNewsQueryKey(idOrSlug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetRelatedNews>>
  > = ({ signal }) => organizationGetRelatedNews(idOrSlug, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!idOrSlug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetRelatedNews>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetRelatedNewsQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetRelatedNews>>
>;
export type OrganizationGetRelatedNewsQueryError = unknown;

export const useOrganizationGetRelatedNews = <
  TData = Awaited<ReturnType<typeof organizationGetRelatedNews>>,
  TError = unknown,
>(
  idOrSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organizationGetRelatedNews>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetRelatedNewsQueryOptions(
    idOrSlug,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationGetEvents = (eventsWpPostsDto: EventsWpPostsDto) => {
  return customClient<WpPostsResponse>({
    url: `/organization/wp/events`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: eventsWpPostsDto,
  });
};

export const getOrganizationGetEventsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetEvents>>,
    TError,
    { data: EventsWpPostsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationGetEvents>>,
  TError,
  { data: EventsWpPostsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationGetEvents>>,
    { data: EventsWpPostsDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationGetEvents(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationGetEventsMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetEvents>>
>;
export type OrganizationGetEventsMutationBody = EventsWpPostsDto;
export type OrganizationGetEventsMutationError = unknown;

export const useOrganizationGetEvents = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetEvents>>,
    TError,
    { data: EventsWpPostsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationGetEvents>>,
  TError,
  { data: EventsWpPostsDto },
  TContext
> => {
  const mutationOptions = getOrganizationGetEventsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetEventsWithBasicData = (
  eventsWpPostsDto: EventsWpPostsDto,
) => {
  return customClient<WpPostsBasicDataResponse>({
    url: `/organization/wp/basic/events`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: eventsWpPostsDto,
  });
};

export const getOrganizationGetEventsWithBasicDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetEventsWithBasicData>>,
    TError,
    { data: EventsWpPostsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationGetEventsWithBasicData>>,
  TError,
  { data: EventsWpPostsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationGetEventsWithBasicData>>,
    { data: EventsWpPostsDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationGetEventsWithBasicData(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationGetEventsWithBasicDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetEventsWithBasicData>>
>;
export type OrganizationGetEventsWithBasicDataMutationBody = EventsWpPostsDto;
export type OrganizationGetEventsWithBasicDataMutationError = unknown;

export const useOrganizationGetEventsWithBasicData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetEventsWithBasicData>>,
    TError,
    { data: EventsWpPostsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationGetEventsWithBasicData>>,
  TError,
  { data: EventsWpPostsDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationGetEventsWithBasicDataMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetOrganizationPanels = (
  panelsWpPostsDto: PanelsWpPostsDto,
) => {
  return customClient<WpPostsResponse>({
    url: `/organization/wp/panels`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: panelsWpPostsDto,
  });
};

export const getOrganizationGetOrganizationPanelsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationPanels>>,
    TError,
    { data: PanelsWpPostsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationGetOrganizationPanels>>,
  TError,
  { data: PanelsWpPostsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationGetOrganizationPanels>>,
    { data: PanelsWpPostsDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationGetOrganizationPanels(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationGetOrganizationPanelsMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetOrganizationPanels>>
>;
export type OrganizationGetOrganizationPanelsMutationBody = PanelsWpPostsDto;
export type OrganizationGetOrganizationPanelsMutationError = unknown;

export const useOrganizationGetOrganizationPanels = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationGetOrganizationPanels>>,
    TError,
    { data: PanelsWpPostsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationGetOrganizationPanels>>,
  TError,
  { data: PanelsWpPostsDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationGetOrganizationPanelsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationGetWpPost = (
  postIdOrSlug: string,
  signal?: AbortSignal,
) => {
  return customClient<OrganizationGetWpPost200>({
    url: `/organization/wp/post/${postIdOrSlug}`,
    method: "GET",
    signal,
  });
};

export const getOrganizationGetWpPostQueryKey = (postIdOrSlug: string) => {
  return [`/organization/wp/post/${postIdOrSlug}`] as const;
};

export const getOrganizationGetWpPostQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationGetWpPost>>,
  TError = unknown,
>(
  postIdOrSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organizationGetWpPost>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationGetWpPostQueryKey(postIdOrSlug);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationGetWpPost>>
  > = ({ signal }) => organizationGetWpPost(postIdOrSlug, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postIdOrSlug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationGetWpPost>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationGetWpPostQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationGetWpPost>>
>;
export type OrganizationGetWpPostQueryError = unknown;

export const useOrganizationGetWpPost = <
  TData = Awaited<ReturnType<typeof organizationGetWpPost>>,
  TError = unknown,
>(
  postIdOrSlug: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organizationGetWpPost>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationGetWpPostQueryOptions(
    postIdOrSlug,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationFindOne = (
  idOrSlug: string,
  params?: OrganizationFindOneParams,
  signal?: AbortSignal,
) => {
  return customClient<Organization>({
    url: `/organization/${idOrSlug}`,
    method: "GET",
    params,
    signal,
  });
};

export const getOrganizationFindOneQueryKey = (
  idOrSlug: string,
  params?: OrganizationFindOneParams,
) => {
  return [`/organization/${idOrSlug}`, ...(params ? [params] : [])] as const;
};

export const getOrganizationFindOneQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationFindOne>>,
  TError = unknown,
>(
  idOrSlug: string,
  params?: OrganizationFindOneParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organizationFindOne>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationFindOneQueryKey(idOrSlug, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationFindOne>>
  > = ({ signal }) => organizationFindOne(idOrSlug, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!idOrSlug,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationFindOne>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationFindOne>>
>;
export type OrganizationFindOneQueryError = unknown;

export const useOrganizationFindOne = <
  TData = Awaited<ReturnType<typeof organizationFindOne>>,
  TError = unknown,
>(
  idOrSlug: string,
  params?: OrganizationFindOneParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof organizationFindOne>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationFindOneQueryOptions(
    idOrSlug,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationUpdateOrganization = (
  id: number,
  updateOrganizationDto: UpdateOrganizationDto,
) => {
  return customClient<OrganizationEntity>({
    url: `/organization/${id}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: updateOrganizationDto,
  });
};

export const getOrganizationUpdateOrganizationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationUpdateOrganization>>,
    TError,
    { id: number; data: UpdateOrganizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationUpdateOrganization>>,
  TError,
  { id: number; data: UpdateOrganizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationUpdateOrganization>>,
    { id: number; data: UpdateOrganizationDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return organizationUpdateOrganization(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationUpdateOrganizationMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationUpdateOrganization>>
>;
export type OrganizationUpdateOrganizationMutationBody = UpdateOrganizationDto;
export type OrganizationUpdateOrganizationMutationError = unknown;

export const useOrganizationUpdateOrganization = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationUpdateOrganization>>,
    TError,
    { id: number; data: UpdateOrganizationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationUpdateOrganization>>,
  TError,
  { id: number; data: UpdateOrganizationDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationUpdateOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationRemove = (id: number) => {
  return customClient<OrganizationEntity>({
    url: `/organization/${id}`,
    method: "DELETE",
  });
};

export const getOrganizationRemoveMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationRemove>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationRemove>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationRemove>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return organizationRemove(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationRemoveMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationRemove>>
>;

export type OrganizationRemoveMutationError = unknown;

export const useOrganizationRemove = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationRemove>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationRemove>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getOrganizationRemoveMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationRejectUserToJoinOrganization = (userSlug: string) => {
  return customClient<void>({
    url: `/organization/reject/${userSlug}`,
    method: "DELETE",
  });
};

export const getOrganizationRejectUserToJoinOrganizationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationRejectUserToJoinOrganization>>,
    TError,
    { userSlug: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationRejectUserToJoinOrganization>>,
  TError,
  { userSlug: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationRejectUserToJoinOrganization>>,
    { userSlug: string }
  > = (props) => {
    const { userSlug } = props ?? {};

    return organizationRejectUserToJoinOrganization(userSlug);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationRejectUserToJoinOrganizationMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof organizationRejectUserToJoinOrganization>>
  >;

export type OrganizationRejectUserToJoinOrganizationMutationError = unknown;

export const useOrganizationRejectUserToJoinOrganization = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationRejectUserToJoinOrganization>>,
    TError,
    { userSlug: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationRejectUserToJoinOrganization>>,
  TError,
  { userSlug: string },
  TContext
> => {
  const mutationOptions =
    getOrganizationRejectUserToJoinOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationAcceptUserToJoinOrganization = (userSlug: string) => {
  return customClient<void>({
    url: `/organization/accept/${userSlug}`,
    method: "POST",
  });
};

export const getOrganizationAcceptUserToJoinOrganizationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationAcceptUserToJoinOrganization>>,
    TError,
    { userSlug: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationAcceptUserToJoinOrganization>>,
  TError,
  { userSlug: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationAcceptUserToJoinOrganization>>,
    { userSlug: string }
  > = (props) => {
    const { userSlug } = props ?? {};

    return organizationAcceptUserToJoinOrganization(userSlug);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationAcceptUserToJoinOrganizationMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof organizationAcceptUserToJoinOrganization>>
  >;

export type OrganizationAcceptUserToJoinOrganizationMutationError = unknown;

export const useOrganizationAcceptUserToJoinOrganization = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationAcceptUserToJoinOrganization>>,
    TError,
    { userSlug: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationAcceptUserToJoinOrganization>>,
  TError,
  { userSlug: string },
  TContext
> => {
  const mutationOptions =
    getOrganizationAcceptUserToJoinOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationInviteEmployees = (
  inviteEmployeeDto: InviteEmployeeDto,
) => {
  return customClient<void>({
    url: `/organization/invite-employee`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: inviteEmployeeDto,
  });
};

export const getOrganizationInviteEmployeesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationInviteEmployees>>,
    TError,
    { data: InviteEmployeeDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationInviteEmployees>>,
  TError,
  { data: InviteEmployeeDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationInviteEmployees>>,
    { data: InviteEmployeeDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationInviteEmployees(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationInviteEmployeesMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationInviteEmployees>>
>;
export type OrganizationInviteEmployeesMutationBody = InviteEmployeeDto;
export type OrganizationInviteEmployeesMutationError = unknown;

export const useOrganizationInviteEmployees = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationInviteEmployees>>,
    TError,
    { data: InviteEmployeeDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationInviteEmployees>>,
  TError,
  { data: InviteEmployeeDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationInviteEmployeesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationResendEmployeeInvite = (inviteId: number) => {
  return customClient<void>({
    url: `/organization/invite-employee/resend/${inviteId}`,
    method: "PATCH",
  });
};

export const getOrganizationResendEmployeeInviteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationResendEmployeeInvite>>,
    TError,
    { inviteId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationResendEmployeeInvite>>,
  TError,
  { inviteId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationResendEmployeeInvite>>,
    { inviteId: number }
  > = (props) => {
    const { inviteId } = props ?? {};

    return organizationResendEmployeeInvite(inviteId);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationResendEmployeeInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationResendEmployeeInvite>>
>;

export type OrganizationResendEmployeeInviteMutationError = unknown;

export const useOrganizationResendEmployeeInvite = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationResendEmployeeInvite>>,
    TError,
    { inviteId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationResendEmployeeInvite>>,
  TError,
  { inviteId: number },
  TContext
> => {
  const mutationOptions =
    getOrganizationResendEmployeeInviteMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationDeleteEmployeeInvites = (
  deleteInviteEmployeeDto: DeleteInviteEmployeeDto,
) => {
  return customClient<void>({
    url: `/organization/invite-employee/delete`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: deleteInviteEmployeeDto,
  });
};

export const getOrganizationDeleteEmployeeInvitesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDeleteEmployeeInvites>>,
    TError,
    { data: DeleteInviteEmployeeDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationDeleteEmployeeInvites>>,
  TError,
  { data: DeleteInviteEmployeeDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationDeleteEmployeeInvites>>,
    { data: DeleteInviteEmployeeDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationDeleteEmployeeInvites(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationDeleteEmployeeInvitesMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationDeleteEmployeeInvites>>
>;
export type OrganizationDeleteEmployeeInvitesMutationBody =
  DeleteInviteEmployeeDto;
export type OrganizationDeleteEmployeeInvitesMutationError = unknown;

export const useOrganizationDeleteEmployeeInvites = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDeleteEmployeeInvites>>,
    TError,
    { data: DeleteInviteEmployeeDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationDeleteEmployeeInvites>>,
  TError,
  { data: DeleteInviteEmployeeDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationDeleteEmployeeInvitesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationEmployeeAcceptInvite = (inviteCode: string) => {
  return customClient<OrganizationUserRoleResponse[]>({
    url: `/organization/invite-employee/accept/${inviteCode}`,
    method: "PATCH",
  });
};

export const getOrganizationEmployeeAcceptInviteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationEmployeeAcceptInvite>>,
    TError,
    { inviteCode: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationEmployeeAcceptInvite>>,
  TError,
  { inviteCode: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationEmployeeAcceptInvite>>,
    { inviteCode: string }
  > = (props) => {
    const { inviteCode } = props ?? {};

    return organizationEmployeeAcceptInvite(inviteCode);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationEmployeeAcceptInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationEmployeeAcceptInvite>>
>;

export type OrganizationEmployeeAcceptInviteMutationError = unknown;

export const useOrganizationEmployeeAcceptInvite = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationEmployeeAcceptInvite>>,
    TError,
    { inviteCode: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationEmployeeAcceptInvite>>,
  TError,
  { inviteCode: string },
  TContext
> => {
  const mutationOptions =
    getOrganizationEmployeeAcceptInviteMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationEmployeeDeclineInvite = (inviteCode: string) => {
  return customClient<void>({
    url: `/organization/invite-employee/reject/${inviteCode}`,
    method: "PATCH",
  });
};

export const getOrganizationEmployeeDeclineInviteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationEmployeeDeclineInvite>>,
    TError,
    { inviteCode: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationEmployeeDeclineInvite>>,
  TError,
  { inviteCode: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationEmployeeDeclineInvite>>,
    { inviteCode: string }
  > = (props) => {
    const { inviteCode } = props ?? {};

    return organizationEmployeeDeclineInvite(inviteCode);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationEmployeeDeclineInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationEmployeeDeclineInvite>>
>;

export type OrganizationEmployeeDeclineInviteMutationError = unknown;

export const useOrganizationEmployeeDeclineInvite = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationEmployeeDeclineInvite>>,
    TError,
    { inviteCode: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationEmployeeDeclineInvite>>,
  TError,
  { inviteCode: string },
  TContext
> => {
  const mutationOptions =
    getOrganizationEmployeeDeclineInviteMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationManageEmployeeInvites = (
  manageInvitesDto: ManageInvitesDto,
) => {
  return customClient<OrganizationInviteResponse>({
    url: `/organization/invite-employee/manage`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: manageInvitesDto,
  });
};

export const getOrganizationManageEmployeeInvitesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationManageEmployeeInvites>>,
    TError,
    { data: ManageInvitesDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationManageEmployeeInvites>>,
  TError,
  { data: ManageInvitesDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationManageEmployeeInvites>>,
    { data: ManageInvitesDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationManageEmployeeInvites(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationManageEmployeeInvitesMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationManageEmployeeInvites>>
>;
export type OrganizationManageEmployeeInvitesMutationBody = ManageInvitesDto;
export type OrganizationManageEmployeeInvitesMutationError = unknown;

export const useOrganizationManageEmployeeInvites = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationManageEmployeeInvites>>,
    TError,
    { data: ManageInvitesDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationManageEmployeeInvites>>,
  TError,
  { data: ManageInvitesDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationManageEmployeeInvitesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationUpdatedEmployeeInvite = (
  updateEmployeeInviteDto: UpdateEmployeeInviteDto,
) => {
  return customClient<OrganizationInviteEntity>({
    url: `/organization/invite-employee/update`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: updateEmployeeInviteDto,
  });
};

export const getOrganizationUpdatedEmployeeInviteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationUpdatedEmployeeInvite>>,
    TError,
    { data: UpdateEmployeeInviteDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationUpdatedEmployeeInvite>>,
  TError,
  { data: UpdateEmployeeInviteDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationUpdatedEmployeeInvite>>,
    { data: UpdateEmployeeInviteDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationUpdatedEmployeeInvite(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationUpdatedEmployeeInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationUpdatedEmployeeInvite>>
>;
export type OrganizationUpdatedEmployeeInviteMutationBody =
  UpdateEmployeeInviteDto;
export type OrganizationUpdatedEmployeeInviteMutationError = unknown;

export const useOrganizationUpdatedEmployeeInvite = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationUpdatedEmployeeInvite>>,
    TError,
    { data: UpdateEmployeeInviteDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationUpdatedEmployeeInvite>>,
  TError,
  { data: UpdateEmployeeInviteDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationUpdatedEmployeeInviteMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationCreateOrganizationArticle = (
  createArticleDto: CreateArticleDto,
) => {
  return customClient<string>({
    url: `/organization/article/create`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createArticleDto,
  });
};

export const getOrganizationCreateOrganizationArticleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateOrganizationArticle>>,
    TError,
    { data: CreateArticleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationCreateOrganizationArticle>>,
  TError,
  { data: CreateArticleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationCreateOrganizationArticle>>,
    { data: CreateArticleDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationCreateOrganizationArticle(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationCreateOrganizationArticleMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationCreateOrganizationArticle>>
>;
export type OrganizationCreateOrganizationArticleMutationBody =
  CreateArticleDto;
export type OrganizationCreateOrganizationArticleMutationError = unknown;

export const useOrganizationCreateOrganizationArticle = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateOrganizationArticle>>,
    TError,
    { data: CreateArticleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationCreateOrganizationArticle>>,
  TError,
  { data: CreateArticleDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationCreateOrganizationArticleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationCreateOrganizationEvent = (
  createEventDto: CreateEventDto,
) => {
  return customClient<string>({
    url: `/organization/event/create`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createEventDto,
  });
};

export const getOrganizationCreateOrganizationEventMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateOrganizationEvent>>,
    TError,
    { data: CreateEventDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationCreateOrganizationEvent>>,
  TError,
  { data: CreateEventDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationCreateOrganizationEvent>>,
    { data: CreateEventDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationCreateOrganizationEvent(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationCreateOrganizationEventMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationCreateOrganizationEvent>>
>;
export type OrganizationCreateOrganizationEventMutationBody = CreateEventDto;
export type OrganizationCreateOrganizationEventMutationError = unknown;

export const useOrganizationCreateOrganizationEvent = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationCreateOrganizationEvent>>,
    TError,
    { data: CreateEventDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationCreateOrganizationEvent>>,
  TError,
  { data: CreateEventDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationCreateOrganizationEventMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationEditOrganizationArticle = (
  id: string,
  updateArticleDto: UpdateArticleDto,
) => {
  return customClient<string>({
    url: `/organization/article/edit/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateArticleDto,
  });
};

export const getOrganizationEditOrganizationArticleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationEditOrganizationArticle>>,
    TError,
    { id: string; data: UpdateArticleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationEditOrganizationArticle>>,
  TError,
  { id: string; data: UpdateArticleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationEditOrganizationArticle>>,
    { id: string; data: UpdateArticleDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return organizationEditOrganizationArticle(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationEditOrganizationArticleMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationEditOrganizationArticle>>
>;
export type OrganizationEditOrganizationArticleMutationBody = UpdateArticleDto;
export type OrganizationEditOrganizationArticleMutationError = unknown;

export const useOrganizationEditOrganizationArticle = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationEditOrganizationArticle>>,
    TError,
    { id: string; data: UpdateArticleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationEditOrganizationArticle>>,
  TError,
  { id: string; data: UpdateArticleDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationEditOrganizationArticleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationEditOrganizationEvent = (
  id: string,
  updateEventDto: UpdateEventDto,
) => {
  return customClient<string>({
    url: `/organization/event/edit/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateEventDto,
  });
};

export const getOrganizationEditOrganizationEventMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationEditOrganizationEvent>>,
    TError,
    { id: string; data: UpdateEventDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationEditOrganizationEvent>>,
  TError,
  { id: string; data: UpdateEventDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationEditOrganizationEvent>>,
    { id: string; data: UpdateEventDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return organizationEditOrganizationEvent(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationEditOrganizationEventMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationEditOrganizationEvent>>
>;
export type OrganizationEditOrganizationEventMutationBody = UpdateEventDto;
export type OrganizationEditOrganizationEventMutationError = unknown;

export const useOrganizationEditOrganizationEvent = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationEditOrganizationEvent>>,
    TError,
    { id: string; data: UpdateEventDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationEditOrganizationEvent>>,
  TError,
  { id: string; data: UpdateEventDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationEditOrganizationEventMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationDeleteWpPost = (postType: string, postId: string) => {
  return customClient<string>({
    url: `/organization/wp/post/${postType}/${postId}`,
    method: "DELETE",
  });
};

export const getOrganizationDeleteWpPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDeleteWpPost>>,
    TError,
    { postType: string; postId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationDeleteWpPost>>,
  TError,
  { postType: string; postId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationDeleteWpPost>>,
    { postType: string; postId: string }
  > = (props) => {
    const { postType, postId } = props ?? {};

    return organizationDeleteWpPost(postType, postId);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationDeleteWpPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationDeleteWpPost>>
>;

export type OrganizationDeleteWpPostMutationError = unknown;

export const useOrganizationDeleteWpPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationDeleteWpPost>>,
    TError,
    { postType: string; postId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationDeleteWpPost>>,
  TError,
  { postType: string; postId: string },
  TContext
> => {
  const mutationOptions = getOrganizationDeleteWpPostMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationAddLead = (
  createOrganizationInquiryDto: CreateOrganizationInquiryDto,
) => {
  return customClient<OrganizationInquiryEntity>({
    url: `/organization/lead`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createOrganizationInquiryDto,
  });
};

export const getOrganizationAddLeadMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationAddLead>>,
    TError,
    { data: CreateOrganizationInquiryDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationAddLead>>,
  TError,
  { data: CreateOrganizationInquiryDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationAddLead>>,
    { data: CreateOrganizationInquiryDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationAddLead(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationAddLeadMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationAddLead>>
>;
export type OrganizationAddLeadMutationBody = CreateOrganizationInquiryDto;
export type OrganizationAddLeadMutationError = unknown;

export const useOrganizationAddLead = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationAddLead>>,
    TError,
    { data: CreateOrganizationInquiryDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationAddLead>>,
  TError,
  { data: CreateOrganizationInquiryDto },
  TContext
> => {
  const mutationOptions = getOrganizationAddLeadMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationReviewCreate = (createReviewDto: CreateReviewDto) => {
  return customClient<OrganizationReviewEntity>({
    url: `/organization-reviews`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createReviewDto,
  });
};

export const getOrganizationReviewCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationReviewCreate>>,
    TError,
    { data: CreateReviewDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationReviewCreate>>,
  TError,
  { data: CreateReviewDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationReviewCreate>>,
    { data: CreateReviewDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationReviewCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationReviewCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationReviewCreate>>
>;
export type OrganizationReviewCreateMutationBody = CreateReviewDto;
export type OrganizationReviewCreateMutationError = unknown;

export const useOrganizationReviewCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationReviewCreate>>,
    TError,
    { data: CreateReviewDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationReviewCreate>>,
  TError,
  { data: CreateReviewDto },
  TContext
> => {
  const mutationOptions = getOrganizationReviewCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationReviewUpdate = (
  id: number,
  updateReviewDto: UpdateReviewDto,
) => {
  return customClient<OrganizationReviewEntity>({
    url: `/organization-reviews/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateReviewDto,
  });
};

export const getOrganizationReviewUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationReviewUpdate>>,
    TError,
    { id: number; data: UpdateReviewDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationReviewUpdate>>,
  TError,
  { id: number; data: UpdateReviewDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationReviewUpdate>>,
    { id: number; data: UpdateReviewDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return organizationReviewUpdate(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationReviewUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationReviewUpdate>>
>;
export type OrganizationReviewUpdateMutationBody = UpdateReviewDto;
export type OrganizationReviewUpdateMutationError = unknown;

export const useOrganizationReviewUpdate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationReviewUpdate>>,
    TError,
    { id: number; data: UpdateReviewDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationReviewUpdate>>,
  TError,
  { id: number; data: UpdateReviewDto },
  TContext
> => {
  const mutationOptions = getOrganizationReviewUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationReviewDelete = (id: number) => {
  return customClient<void>({
    url: `/organization-reviews/${id}`,
    method: "DELETE",
  });
};

export const getOrganizationReviewDeleteMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationReviewDelete>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationReviewDelete>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationReviewDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return organizationReviewDelete(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationReviewDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationReviewDelete>>
>;

export type OrganizationReviewDeleteMutationError = unknown;

export const useOrganizationReviewDelete = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationReviewDelete>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationReviewDelete>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getOrganizationReviewDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationReviewFindAll = (
  findAllReviewDto: FindAllReviewDto,
) => {
  return customClient<OrganizationReviewResponse>({
    url: `/organization-reviews/list`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: findAllReviewDto,
  });
};

export const getOrganizationReviewFindAllMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationReviewFindAll>>,
    TError,
    { data: FindAllReviewDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationReviewFindAll>>,
  TError,
  { data: FindAllReviewDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationReviewFindAll>>,
    { data: FindAllReviewDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationReviewFindAll(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationReviewFindAllMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationReviewFindAll>>
>;
export type OrganizationReviewFindAllMutationBody = FindAllReviewDto;
export type OrganizationReviewFindAllMutationError = unknown;

export const useOrganizationReviewFindAll = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationReviewFindAll>>,
    TError,
    { data: FindAllReviewDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationReviewFindAll>>,
  TError,
  { data: FindAllReviewDto },
  TContext
> => {
  const mutationOptions = getOrganizationReviewFindAllMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationSharedFileCreateFolder = (
  createFolderDto: CreateFolderDto,
) => {
  return customClient<OrganizationSharedFileEntity>({
    url: `/organization-shared-file/folder`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createFolderDto,
  });
};

export const getOrganizationSharedFileCreateFolderMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileCreateFolder>>,
    TError,
    { data: CreateFolderDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationSharedFileCreateFolder>>,
  TError,
  { data: CreateFolderDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationSharedFileCreateFolder>>,
    { data: CreateFolderDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationSharedFileCreateFolder(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationSharedFileCreateFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationSharedFileCreateFolder>>
>;
export type OrganizationSharedFileCreateFolderMutationBody = CreateFolderDto;
export type OrganizationSharedFileCreateFolderMutationError = unknown;

export const useOrganizationSharedFileCreateFolder = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileCreateFolder>>,
    TError,
    { data: CreateFolderDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationSharedFileCreateFolder>>,
  TError,
  { data: CreateFolderDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationSharedFileCreateFolderMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationSharedFileGetFolders = (signal?: AbortSignal) => {
  return customClient<OrganizationSharedFileEntity[]>({
    url: `/organization-shared-file/folder`,
    method: "GET",
    signal,
  });
};

export const getOrganizationSharedFileGetFoldersQueryKey = () => {
  return [`/organization-shared-file/folder`] as const;
};

export const getOrganizationSharedFileGetFoldersQueryOptions = <
  TData = Awaited<ReturnType<typeof organizationSharedFileGetFolders>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationSharedFileGetFolders>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getOrganizationSharedFileGetFoldersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof organizationSharedFileGetFolders>>
  > = ({ signal }) => organizationSharedFileGetFolders(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof organizationSharedFileGetFolders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type OrganizationSharedFileGetFoldersQueryResult = NonNullable<
  Awaited<ReturnType<typeof organizationSharedFileGetFolders>>
>;
export type OrganizationSharedFileGetFoldersQueryError = unknown;

export const useOrganizationSharedFileGetFolders = <
  TData = Awaited<ReturnType<typeof organizationSharedFileGetFolders>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof organizationSharedFileGetFolders>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrganizationSharedFileGetFoldersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const organizationSharedFileUpdateFolder = (
  id: number,
  updateFolderDto: UpdateFolderDto,
) => {
  return customClient<OrganizationSharedFileEntity>({
    url: `/organization-shared-file/folder/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateFolderDto,
  });
};

export const getOrganizationSharedFileUpdateFolderMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileUpdateFolder>>,
    TError,
    { id: number; data: UpdateFolderDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationSharedFileUpdateFolder>>,
  TError,
  { id: number; data: UpdateFolderDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationSharedFileUpdateFolder>>,
    { id: number; data: UpdateFolderDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return organizationSharedFileUpdateFolder(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationSharedFileUpdateFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationSharedFileUpdateFolder>>
>;
export type OrganizationSharedFileUpdateFolderMutationBody = UpdateFolderDto;
export type OrganizationSharedFileUpdateFolderMutationError = unknown;

export const useOrganizationSharedFileUpdateFolder = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileUpdateFolder>>,
    TError,
    { id: number; data: UpdateFolderDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationSharedFileUpdateFolder>>,
  TError,
  { id: number; data: UpdateFolderDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationSharedFileUpdateFolderMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationSharedFileDeleteFolder = (id: number) => {
  return customClient<void>({
    url: `/organization-shared-file/folder/${id}`,
    method: "DELETE",
  });
};

export const getOrganizationSharedFileDeleteFolderMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileDeleteFolder>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationSharedFileDeleteFolder>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationSharedFileDeleteFolder>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return organizationSharedFileDeleteFolder(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationSharedFileDeleteFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationSharedFileDeleteFolder>>
>;

export type OrganizationSharedFileDeleteFolderMutationError = unknown;

export const useOrganizationSharedFileDeleteFolder = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileDeleteFolder>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationSharedFileDeleteFolder>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getOrganizationSharedFileDeleteFolderMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationSharedFileCreateFile = (
  createFileDto: CreateFileDto,
) => {
  return customClient<OrganizationSharedFileEntity>({
    url: `/organization-shared-file`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createFileDto,
  });
};

export const getOrganizationSharedFileCreateFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileCreateFile>>,
    TError,
    { data: CreateFileDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationSharedFileCreateFile>>,
  TError,
  { data: CreateFileDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationSharedFileCreateFile>>,
    { data: CreateFileDto }
  > = (props) => {
    const { data } = props ?? {};

    return organizationSharedFileCreateFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationSharedFileCreateFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationSharedFileCreateFile>>
>;
export type OrganizationSharedFileCreateFileMutationBody = CreateFileDto;
export type OrganizationSharedFileCreateFileMutationError = unknown;

export const useOrganizationSharedFileCreateFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileCreateFile>>,
    TError,
    { data: CreateFileDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationSharedFileCreateFile>>,
  TError,
  { data: CreateFileDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationSharedFileCreateFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationSharedFileUpdateFile = (
  id: number,
  updateFileDto: UpdateFileDto,
) => {
  return customClient<OrganizationSharedFileEntity>({
    url: `/organization-shared-file/${id}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: updateFileDto,
  });
};

export const getOrganizationSharedFileUpdateFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileUpdateFile>>,
    TError,
    { id: number; data: UpdateFileDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationSharedFileUpdateFile>>,
  TError,
  { id: number; data: UpdateFileDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationSharedFileUpdateFile>>,
    { id: number; data: UpdateFileDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return organizationSharedFileUpdateFile(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationSharedFileUpdateFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationSharedFileUpdateFile>>
>;
export type OrganizationSharedFileUpdateFileMutationBody = UpdateFileDto;
export type OrganizationSharedFileUpdateFileMutationError = unknown;

export const useOrganizationSharedFileUpdateFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileUpdateFile>>,
    TError,
    { id: number; data: UpdateFileDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationSharedFileUpdateFile>>,
  TError,
  { id: number; data: UpdateFileDto },
  TContext
> => {
  const mutationOptions =
    getOrganizationSharedFileUpdateFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const organizationSharedFileDeleteFile = (id: number) => {
  return customClient<void>({
    url: `/organization-shared-file/${id}`,
    method: "DELETE",
  });
};

export const getOrganizationSharedFileDeleteFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileDeleteFile>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof organizationSharedFileDeleteFile>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof organizationSharedFileDeleteFile>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return organizationSharedFileDeleteFile(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrganizationSharedFileDeleteFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationSharedFileDeleteFile>>
>;

export type OrganizationSharedFileDeleteFileMutationError = unknown;

export const useOrganizationSharedFileDeleteFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof organizationSharedFileDeleteFile>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof organizationSharedFileDeleteFile>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getOrganizationSharedFileDeleteFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const analyticsCreate = (createAnalyticsDto: CreateAnalyticsDto) => {
  return customClient<AnalyticsEntity>({
    url: `/analytics`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createAnalyticsDto,
  });
};

export const getAnalyticsCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof analyticsCreate>>,
    TError,
    { data: CreateAnalyticsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof analyticsCreate>>,
  TError,
  { data: CreateAnalyticsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof analyticsCreate>>,
    { data: CreateAnalyticsDto }
  > = (props) => {
    const { data } = props ?? {};

    return analyticsCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AnalyticsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof analyticsCreate>>
>;
export type AnalyticsCreateMutationBody = CreateAnalyticsDto;
export type AnalyticsCreateMutationError = unknown;

export const useAnalyticsCreate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof analyticsCreate>>,
    TError,
    { data: CreateAnalyticsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof analyticsCreate>>,
  TError,
  { data: CreateAnalyticsDto },
  TContext
> => {
  const mutationOptions = getAnalyticsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const assetCreate = (createAssetDto: CreateAssetDto) => {
  return customClient<AssetEntity>({
    url: `/asset`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createAssetDto,
  });
};

export const getAssetCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetCreate>>,
    TError,
    { data: CreateAssetDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetCreate>>,
  TError,
  { data: CreateAssetDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetCreate>>,
    { data: CreateAssetDto }
  > = (props) => {
    const { data } = props ?? {};

    return assetCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetCreate>>
>;
export type AssetCreateMutationBody = CreateAssetDto;
export type AssetCreateMutationError = unknown;

export const useAssetCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetCreate>>,
    TError,
    { data: CreateAssetDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetCreate>>,
  TError,
  { data: CreateAssetDto },
  TContext
> => {
  const mutationOptions = getAssetCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const assetCreateAssetBase64 = (
  createAssetBase64Dto: CreateAssetBase64Dto,
) => {
  return customClient<AssetEntity>({
    url: `/asset/base64`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createAssetBase64Dto,
  });
};

export const getAssetCreateAssetBase64MutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetCreateAssetBase64>>,
    TError,
    { data: CreateAssetBase64Dto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetCreateAssetBase64>>,
  TError,
  { data: CreateAssetBase64Dto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetCreateAssetBase64>>,
    { data: CreateAssetBase64Dto }
  > = (props) => {
    const { data } = props ?? {};

    return assetCreateAssetBase64(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetCreateAssetBase64MutationResult = NonNullable<
  Awaited<ReturnType<typeof assetCreateAssetBase64>>
>;
export type AssetCreateAssetBase64MutationBody = CreateAssetBase64Dto;
export type AssetCreateAssetBase64MutationError = unknown;

export const useAssetCreateAssetBase64 = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetCreateAssetBase64>>,
    TError,
    { data: CreateAssetBase64Dto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetCreateAssetBase64>>,
  TError,
  { data: CreateAssetBase64Dto },
  TContext
> => {
  const mutationOptions = getAssetCreateAssetBase64MutationOptions(options);

  return useMutation(mutationOptions);
};

export const assetUploadFile = () => {
  return customClient<string>({ url: `/asset/upload`, method: "POST" });
};

export const getAssetUploadFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetUploadFile>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetUploadFile>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetUploadFile>>,
    void
  > = () => {
    return assetUploadFile();
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetUploadFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetUploadFile>>
>;

export type AssetUploadFileMutationError = unknown;

export const useAssetUploadFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetUploadFile>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetUploadFile>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAssetUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const assetUploadBase64 = (uploadFileBase64Dto: UploadFileBase64Dto) => {
  return customClient<string>({
    url: `/asset/upload/base64`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: uploadFileBase64Dto,
  });
};

export const getAssetUploadBase64MutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetUploadBase64>>,
    TError,
    { data: UploadFileBase64Dto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetUploadBase64>>,
  TError,
  { data: UploadFileBase64Dto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetUploadBase64>>,
    { data: UploadFileBase64Dto }
  > = (props) => {
    const { data } = props ?? {};

    return assetUploadBase64(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetUploadBase64MutationResult = NonNullable<
  Awaited<ReturnType<typeof assetUploadBase64>>
>;
export type AssetUploadBase64MutationBody = UploadFileBase64Dto;
export type AssetUploadBase64MutationError = unknown;

export const useAssetUploadBase64 = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetUploadBase64>>,
    TError,
    { data: UploadFileBase64Dto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetUploadBase64>>,
  TError,
  { data: UploadFileBase64Dto },
  TContext
> => {
  const mutationOptions = getAssetUploadBase64MutationOptions(options);

  return useMutation(mutationOptions);
};

export const assetUploadFileToWordpress = () => {
  return customClient<string>({
    url: `/asset/wordpress/upload`,
    method: "POST",
  });
};

export const getAssetUploadFileToWordpressMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetUploadFileToWordpress>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetUploadFileToWordpress>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetUploadFileToWordpress>>,
    void
  > = () => {
    return assetUploadFileToWordpress();
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetUploadFileToWordpressMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetUploadFileToWordpress>>
>;

export type AssetUploadFileToWordpressMutationError = unknown;

export const useAssetUploadFileToWordpress = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetUploadFileToWordpress>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetUploadFileToWordpress>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAssetUploadFileToWordpressMutationOptions(options);

  return useMutation(mutationOptions);
};

export const assetFindOne = (id: number, signal?: AbortSignal) => {
  return customClient<AssetEntity>({
    url: `/asset/${id}`,
    method: "GET",
    signal,
  });
};

export const getAssetFindOneQueryKey = (id: number) => {
  return [`/asset/${id}`] as const;
};

export const getAssetFindOneQueryOptions = <
  TData = Awaited<ReturnType<typeof assetFindOne>>,
  TError = unknown,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof assetFindOne>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAssetFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof assetFindOne>>> = ({
    signal,
  }) => assetFindOne(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof assetFindOne>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssetFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof assetFindOne>>
>;
export type AssetFindOneQueryError = unknown;

export const useAssetFindOne = <
  TData = Awaited<ReturnType<typeof assetFindOne>>,
  TError = unknown,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof assetFindOne>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAssetFindOneQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assetRemove = (id: number) => {
  return customClient<AssetEntity>({ url: `/asset/${id}`, method: "DELETE" });
};

export const getAssetRemoveMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetRemove>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assetRemove>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assetRemove>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return assetRemove(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssetRemoveMutationResult = NonNullable<
  Awaited<ReturnType<typeof assetRemove>>
>;

export type AssetRemoveMutationError = unknown;

export const useAssetRemove = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assetRemove>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof assetRemove>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getAssetRemoveMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobCreateJob = (createJobDto: CreateJobDto) => {
  return customClient<JobEntity>({
    url: `/job`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createJobDto,
  });
};

export const getJobCreateJobMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobCreateJob>>,
    TError,
    { data: CreateJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobCreateJob>>,
  TError,
  { data: CreateJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobCreateJob>>,
    { data: CreateJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobCreateJob(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobCreateJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobCreateJob>>
>;
export type JobCreateJobMutationBody = CreateJobDto;
export type JobCreateJobMutationError = unknown;

export const useJobCreateJob = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobCreateJob>>,
    TError,
    { data: CreateJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobCreateJob>>,
  TError,
  { data: CreateJobDto },
  TContext
> => {
  const mutationOptions = getJobCreateJobMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobCreateOrUpdateFromScraper = (
  createScraperJobDto: CreateScraperJobDto,
) => {
  return customClient<JobCreateOrUpdateFromScraper201>({
    url: `/job/add-scraped`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createScraperJobDto,
  });
};

export const getJobCreateOrUpdateFromScraperMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobCreateOrUpdateFromScraper>>,
    TError,
    { data: CreateScraperJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobCreateOrUpdateFromScraper>>,
  TError,
  { data: CreateScraperJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobCreateOrUpdateFromScraper>>,
    { data: CreateScraperJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobCreateOrUpdateFromScraper(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobCreateOrUpdateFromScraperMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobCreateOrUpdateFromScraper>>
>;
export type JobCreateOrUpdateFromScraperMutationBody = CreateScraperJobDto;
export type JobCreateOrUpdateFromScraperMutationError = unknown;

export const useJobCreateOrUpdateFromScraper = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobCreateOrUpdateFromScraper>>,
    TError,
    { data: CreateScraperJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobCreateOrUpdateFromScraper>>,
  TError,
  { data: CreateScraperJobDto },
  TContext
> => {
  const mutationOptions =
    getJobCreateOrUpdateFromScraperMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobRemoveDuplicateJobs = (signal?: AbortSignal) => {
  return customClient<void>({
    url: `/job/remove/duplicates`,
    method: "GET",
    signal,
  });
};

export const getJobRemoveDuplicateJobsQueryKey = () => {
  return [`/job/remove/duplicates`] as const;
};

export const getJobRemoveDuplicateJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof jobRemoveDuplicateJobs>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobRemoveDuplicateJobs>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getJobRemoveDuplicateJobsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof jobRemoveDuplicateJobs>>
  > = ({ signal }) => jobRemoveDuplicateJobs(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof jobRemoveDuplicateJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type JobRemoveDuplicateJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof jobRemoveDuplicateJobs>>
>;
export type JobRemoveDuplicateJobsQueryError = unknown;

export const useJobRemoveDuplicateJobs = <
  TData = Awaited<ReturnType<typeof jobRemoveDuplicateJobs>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobRemoveDuplicateJobs>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getJobRemoveDuplicateJobsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const jobSoftDeleteDuplicateJobs = (signal?: AbortSignal) => {
  return customClient<void>({
    url: `/job/softDelete/duplicates`,
    method: "GET",
    signal,
  });
};

export const getJobSoftDeleteDuplicateJobsQueryKey = () => {
  return [`/job/softDelete/duplicates`] as const;
};

export const getJobSoftDeleteDuplicateJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof jobSoftDeleteDuplicateJobs>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobSoftDeleteDuplicateJobs>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getJobSoftDeleteDuplicateJobsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof jobSoftDeleteDuplicateJobs>>
  > = ({ signal }) => jobSoftDeleteDuplicateJobs(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof jobSoftDeleteDuplicateJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type JobSoftDeleteDuplicateJobsQueryResult = NonNullable<
  Awaited<ReturnType<typeof jobSoftDeleteDuplicateJobs>>
>;
export type JobSoftDeleteDuplicateJobsQueryError = unknown;

export const useJobSoftDeleteDuplicateJobs = <
  TData = Awaited<ReturnType<typeof jobSoftDeleteDuplicateJobs>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobSoftDeleteDuplicateJobs>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getJobSoftDeleteDuplicateJobsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const jobSoftDeleteJobsWithSameTitleByOrganization = (
  signal?: AbortSignal,
) => {
  return customClient<void>({
    url: `/job/softDelete/duplicate-titles`,
    method: "GET",
    signal,
  });
};

export const getJobSoftDeleteJobsWithSameTitleByOrganizationQueryKey = () => {
  return [`/job/softDelete/duplicate-titles`] as const;
};

export const getJobSoftDeleteJobsWithSameTitleByOrganizationQueryOptions = <
  TData = Awaited<
    ReturnType<typeof jobSoftDeleteJobsWithSameTitleByOrganization>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobSoftDeleteJobsWithSameTitleByOrganization>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getJobSoftDeleteJobsWithSameTitleByOrganizationQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof jobSoftDeleteJobsWithSameTitleByOrganization>>
  > = ({ signal }) => jobSoftDeleteJobsWithSameTitleByOrganization(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof jobSoftDeleteJobsWithSameTitleByOrganization>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type JobSoftDeleteJobsWithSameTitleByOrganizationQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof jobSoftDeleteJobsWithSameTitleByOrganization>>
  >;
export type JobSoftDeleteJobsWithSameTitleByOrganizationQueryError = unknown;

export const useJobSoftDeleteJobsWithSameTitleByOrganization = <
  TData = Awaited<
    ReturnType<typeof jobSoftDeleteJobsWithSameTitleByOrganization>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobSoftDeleteJobsWithSameTitleByOrganization>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getJobSoftDeleteJobsWithSameTitleByOrganizationQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const jobRemoveScrapedJob = (
  removeScrapedJobDto: RemoveScrapedJobDto,
) => {
  return customClient<number>({
    url: `/job/remove-scraped`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: removeScrapedJobDto,
  });
};

export const getJobRemoveScrapedJobMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobRemoveScrapedJob>>,
    TError,
    { data: RemoveScrapedJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobRemoveScrapedJob>>,
  TError,
  { data: RemoveScrapedJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobRemoveScrapedJob>>,
    { data: RemoveScrapedJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobRemoveScrapedJob(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobRemoveScrapedJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobRemoveScrapedJob>>
>;
export type JobRemoveScrapedJobMutationBody = RemoveScrapedJobDto;
export type JobRemoveScrapedJobMutationError = unknown;

export const useJobRemoveScrapedJob = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobRemoveScrapedJob>>,
    TError,
    { data: RemoveScrapedJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobRemoveScrapedJob>>,
  TError,
  { data: RemoveScrapedJobDto },
  TContext
> => {
  const mutationOptions = getJobRemoveScrapedJobMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobUpdateJobElasticSearch = (signal?: AbortSignal) => {
  return customClient<void>({ url: `/job/es/update`, method: "GET", signal });
};

export const getJobUpdateJobElasticSearchQueryKey = () => {
  return [`/job/es/update`] as const;
};

export const getJobUpdateJobElasticSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof jobUpdateJobElasticSearch>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobUpdateJobElasticSearch>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getJobUpdateJobElasticSearchQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof jobUpdateJobElasticSearch>>
  > = ({ signal }) => jobUpdateJobElasticSearch(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof jobUpdateJobElasticSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type JobUpdateJobElasticSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof jobUpdateJobElasticSearch>>
>;
export type JobUpdateJobElasticSearchQueryError = unknown;

export const useJobUpdateJobElasticSearch = <
  TData = Awaited<ReturnType<typeof jobUpdateJobElasticSearch>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobUpdateJobElasticSearch>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getJobUpdateJobElasticSearchQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const jobCreateJobFromExternalSource = (
  createExternalJobDto: CreateExternalJobDto,
) => {
  return customClient<void>({
    url: `/job/add-external-job`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createExternalJobDto,
  });
};

export const getJobCreateJobFromExternalSourceMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobCreateJobFromExternalSource>>,
    TError,
    { data: CreateExternalJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobCreateJobFromExternalSource>>,
  TError,
  { data: CreateExternalJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobCreateJobFromExternalSource>>,
    { data: CreateExternalJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobCreateJobFromExternalSource(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobCreateJobFromExternalSourceMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobCreateJobFromExternalSource>>
>;
export type JobCreateJobFromExternalSourceMutationBody = CreateExternalJobDto;
export type JobCreateJobFromExternalSourceMutationError = unknown;

export const useJobCreateJobFromExternalSource = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobCreateJobFromExternalSource>>,
    TError,
    { data: CreateExternalJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobCreateJobFromExternalSource>>,
  TError,
  { data: CreateExternalJobDto },
  TContext
> => {
  const mutationOptions =
    getJobCreateJobFromExternalSourceMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobGetJobMetaData = (signal?: AbortSignal) => {
  return customClient<JobMetadata>({
    url: `/job/metadata`,
    method: "GET",
    signal,
  });
};

export const getJobGetJobMetaDataQueryKey = () => {
  return [`/job/metadata`] as const;
};

export const getJobGetJobMetaDataQueryOptions = <
  TData = Awaited<ReturnType<typeof jobGetJobMetaData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobGetJobMetaData>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getJobGetJobMetaDataQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof jobGetJobMetaData>>
  > = ({ signal }) => jobGetJobMetaData(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof jobGetJobMetaData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type JobGetJobMetaDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof jobGetJobMetaData>>
>;
export type JobGetJobMetaDataQueryError = unknown;

export const useJobGetJobMetaData = <
  TData = Awaited<ReturnType<typeof jobGetJobMetaData>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof jobGetJobMetaData>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getJobGetJobMetaDataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const jobSearchJobs = (searchJobDto: SearchJobDto) => {
  return customClient<JobResponse>({
    url: `/job/search`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchJobDto,
  });
};

export const getJobSearchJobsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobSearchJobs>>,
    TError,
    { data: SearchJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobSearchJobs>>,
  TError,
  { data: SearchJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobSearchJobs>>,
    { data: SearchJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobSearchJobs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobSearchJobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobSearchJobs>>
>;
export type JobSearchJobsMutationBody = SearchJobDto;
export type JobSearchJobsMutationError = unknown;

export const useJobSearchJobs = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobSearchJobs>>,
    TError,
    { data: SearchJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobSearchJobs>>,
  TError,
  { data: SearchJobDto },
  TContext
> => {
  const mutationOptions = getJobSearchJobsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobEarlyCareerJobs = (
  earlyCareerAndApprenticeJobDto: EarlyCareerAndApprenticeJobDto,
) => {
  return customClient<JobResponse>({
    url: `/job/early-career`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: earlyCareerAndApprenticeJobDto,
  });
};

export const getJobEarlyCareerJobsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobEarlyCareerJobs>>,
    TError,
    { data: EarlyCareerAndApprenticeJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobEarlyCareerJobs>>,
  TError,
  { data: EarlyCareerAndApprenticeJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobEarlyCareerJobs>>,
    { data: EarlyCareerAndApprenticeJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobEarlyCareerJobs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobEarlyCareerJobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobEarlyCareerJobs>>
>;
export type JobEarlyCareerJobsMutationBody = EarlyCareerAndApprenticeJobDto;
export type JobEarlyCareerJobsMutationError = unknown;

export const useJobEarlyCareerJobs = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobEarlyCareerJobs>>,
    TError,
    { data: EarlyCareerAndApprenticeJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobEarlyCareerJobs>>,
  TError,
  { data: EarlyCareerAndApprenticeJobDto },
  TContext
> => {
  const mutationOptions = getJobEarlyCareerJobsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobAllJobs = (jobType: string, searchJobDto: SearchJobDto) => {
  return customClient<JobResponse>({
    url: `/job/jobs/${jobType}`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchJobDto,
  });
};

export const getJobAllJobsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobAllJobs>>,
    TError,
    { jobType: string; data: SearchJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobAllJobs>>,
  TError,
  { jobType: string; data: SearchJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobAllJobs>>,
    { jobType: string; data: SearchJobDto }
  > = (props) => {
    const { jobType, data } = props ?? {};

    return jobAllJobs(jobType, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobAllJobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobAllJobs>>
>;
export type JobAllJobsMutationBody = SearchJobDto;
export type JobAllJobsMutationError = unknown;

export const useJobAllJobs = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobAllJobs>>,
    TError,
    { jobType: string; data: SearchJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobAllJobs>>,
  TError,
  { jobType: string; data: SearchJobDto },
  TContext
> => {
  const mutationOptions = getJobAllJobsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobApprenticeshipJobs = (
  earlyCareerAndApprenticeJobDto: EarlyCareerAndApprenticeJobDto,
) => {
  return customClient<JobResponse>({
    url: `/job/apprenticeship`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: earlyCareerAndApprenticeJobDto,
  });
};

export const getJobApprenticeshipJobsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobApprenticeshipJobs>>,
    TError,
    { data: EarlyCareerAndApprenticeJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobApprenticeshipJobs>>,
  TError,
  { data: EarlyCareerAndApprenticeJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobApprenticeshipJobs>>,
    { data: EarlyCareerAndApprenticeJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobApprenticeshipJobs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobApprenticeshipJobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobApprenticeshipJobs>>
>;
export type JobApprenticeshipJobsMutationBody = EarlyCareerAndApprenticeJobDto;
export type JobApprenticeshipJobsMutationError = unknown;

export const useJobApprenticeshipJobs = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobApprenticeshipJobs>>,
    TError,
    { data: EarlyCareerAndApprenticeJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobApprenticeshipJobs>>,
  TError,
  { data: EarlyCareerAndApprenticeJobDto },
  TContext
> => {
  const mutationOptions = getJobApprenticeshipJobsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobMemberDashboardJobs = (
  memberDashboardJobDto: MemberDashboardJobDto,
) => {
  return customClient<JobResponse>({
    url: `/job/member-dashboard`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: memberDashboardJobDto,
  });
};

export const getJobMemberDashboardJobsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobMemberDashboardJobs>>,
    TError,
    { data: MemberDashboardJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobMemberDashboardJobs>>,
  TError,
  { data: MemberDashboardJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobMemberDashboardJobs>>,
    { data: MemberDashboardJobDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobMemberDashboardJobs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobMemberDashboardJobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobMemberDashboardJobs>>
>;
export type JobMemberDashboardJobsMutationBody = MemberDashboardJobDto;
export type JobMemberDashboardJobsMutationError = unknown;

export const useJobMemberDashboardJobs = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobMemberDashboardJobs>>,
    TError,
    { data: MemberDashboardJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobMemberDashboardJobs>>,
  TError,
  { data: MemberDashboardJobDto },
  TContext
> => {
  const mutationOptions = getJobMemberDashboardJobsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobGetFavourites = (favouriteJobsDto: FavouriteJobsDto) => {
  return customClient<UserFavouriteJobResponse>({
    url: `/job/favourites`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: favouriteJobsDto,
  });
};

export const getJobGetFavouritesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobGetFavourites>>,
    TError,
    { data: FavouriteJobsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobGetFavourites>>,
  TError,
  { data: FavouriteJobsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobGetFavourites>>,
    { data: FavouriteJobsDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobGetFavourites(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobGetFavouritesMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobGetFavourites>>
>;
export type JobGetFavouritesMutationBody = FavouriteJobsDto;
export type JobGetFavouritesMutationError = unknown;

export const useJobGetFavourites = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobGetFavourites>>,
    TError,
    { data: FavouriteJobsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobGetFavourites>>,
  TError,
  { data: FavouriteJobsDto },
  TContext
> => {
  const mutationOptions = getJobGetFavouritesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobGetJob = (
  idOrSlug: string,
  params?: JobGetJobParams,
  signal?: AbortSignal,
) => {
  return customClient<JobResponseEntity>({
    url: `/job/${idOrSlug}`,
    method: "GET",
    params,
    signal,
  });
};

export const getJobGetJobQueryKey = (
  idOrSlug: string,
  params?: JobGetJobParams,
) => {
  return [`/job/${idOrSlug}`, ...(params ? [params] : [])] as const;
};

export const getJobGetJobQueryOptions = <
  TData = Awaited<ReturnType<typeof jobGetJob>>,
  TError = unknown,
>(
  idOrSlug: string,
  params?: JobGetJobParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof jobGetJob>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getJobGetJobQueryKey(idOrSlug, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobGetJob>>> = ({
    signal,
  }) => jobGetJob(idOrSlug, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!idOrSlug,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof jobGetJob>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type JobGetJobQueryResult = NonNullable<
  Awaited<ReturnType<typeof jobGetJob>>
>;
export type JobGetJobQueryError = unknown;

export const useJobGetJob = <
  TData = Awaited<ReturnType<typeof jobGetJob>>,
  TError = unknown,
>(
  idOrSlug: string,
  params?: JobGetJobParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof jobGetJob>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getJobGetJobQueryOptions(idOrSlug, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const jobDeleteManualCreatedJob = (id: string) => {
  return customClient<void>({ url: `/job/${id}`, method: "DELETE" });
};

export const getJobDeleteManualCreatedJobMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobDeleteManualCreatedJob>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobDeleteManualCreatedJob>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobDeleteManualCreatedJob>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return jobDeleteManualCreatedJob(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobDeleteManualCreatedJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobDeleteManualCreatedJob>>
>;

export type JobDeleteManualCreatedJobMutationError = unknown;

export const useJobDeleteManualCreatedJob = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobDeleteManualCreatedJob>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobDeleteManualCreatedJob>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getJobDeleteManualCreatedJobMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobUpdateManualCreatedJob = (
  id: string,
  updateJobDto: UpdateJobDto,
) => {
  return customClient<JobEntity>({
    url: `/job/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateJobDto,
  });
};

export const getJobUpdateManualCreatedJobMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobUpdateManualCreatedJob>>,
    TError,
    { id: string; data: UpdateJobDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobUpdateManualCreatedJob>>,
  TError,
  { id: string; data: UpdateJobDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobUpdateManualCreatedJob>>,
    { id: string; data: UpdateJobDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return jobUpdateManualCreatedJob(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobUpdateManualCreatedJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobUpdateManualCreatedJob>>
>;
export type JobUpdateManualCreatedJobMutationBody = UpdateJobDto;
export type JobUpdateManualCreatedJobMutationError = unknown;

export const useJobUpdateManualCreatedJob = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobUpdateManualCreatedJob>>,
    TError,
    { id: string; data: UpdateJobDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobUpdateManualCreatedJob>>,
  TError,
  { id: string; data: UpdateJobDto },
  TContext
> => {
  const mutationOptions = getJobUpdateManualCreatedJobMutationOptions(options);

  return useMutation(mutationOptions);
};

export const jobFavouriteToggle = (favouriteToggleDto: FavouriteToggleDto) => {
  return customClient<string>({
    url: `/job/favourite/toggle`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: favouriteToggleDto,
  });
};

export const getJobFavouriteToggleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobFavouriteToggle>>,
    TError,
    { data: FavouriteToggleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof jobFavouriteToggle>>,
  TError,
  { data: FavouriteToggleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof jobFavouriteToggle>>,
    { data: FavouriteToggleDto }
  > = (props) => {
    const { data } = props ?? {};

    return jobFavouriteToggle(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type JobFavouriteToggleMutationResult = NonNullable<
  Awaited<ReturnType<typeof jobFavouriteToggle>>
>;
export type JobFavouriteToggleMutationBody = FavouriteToggleDto;
export type JobFavouriteToggleMutationError = unknown;

export const useJobFavouriteToggle = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof jobFavouriteToggle>>,
    TError,
    { data: FavouriteToggleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof jobFavouriteToggle>>,
  TError,
  { data: FavouriteToggleDto },
  TContext
> => {
  const mutationOptions = getJobFavouriteToggleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const messageGetConversations = (
  params?: MessageGetConversationsParams,
  signal?: AbortSignal,
) => {
  return customClient<ConversationResponse>({
    url: `/message/conversations`,
    method: "GET",
    params,
    signal,
  });
};

export const getMessageGetConversationsQueryKey = (
  params?: MessageGetConversationsParams,
) => {
  return [`/message/conversations`, ...(params ? [params] : [])] as const;
};

export const getMessageGetConversationsQueryOptions = <
  TData = Awaited<ReturnType<typeof messageGetConversations>>,
  TError = unknown,
>(
  params?: MessageGetConversationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof messageGetConversations>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getMessageGetConversationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof messageGetConversations>>
  > = ({ signal }) => messageGetConversations(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof messageGetConversations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MessageGetConversationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof messageGetConversations>>
>;
export type MessageGetConversationsQueryError = unknown;

export const useMessageGetConversations = <
  TData = Awaited<ReturnType<typeof messageGetConversations>>,
  TError = unknown,
>(
  params?: MessageGetConversationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof messageGetConversations>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getMessageGetConversationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const messageGetConversationMessages = (
  conversationMessagesDto: ConversationMessagesDto,
) => {
  return customClient<ConversationMessagesResponse>({
    url: `/message/conversation/messages`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: conversationMessagesDto,
  });
};

export const getMessageGetConversationMessagesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageGetConversationMessages>>,
    TError,
    { data: ConversationMessagesDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof messageGetConversationMessages>>,
  TError,
  { data: ConversationMessagesDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof messageGetConversationMessages>>,
    { data: ConversationMessagesDto }
  > = (props) => {
    const { data } = props ?? {};

    return messageGetConversationMessages(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MessageGetConversationMessagesMutationResult = NonNullable<
  Awaited<ReturnType<typeof messageGetConversationMessages>>
>;
export type MessageGetConversationMessagesMutationBody =
  ConversationMessagesDto;
export type MessageGetConversationMessagesMutationError = unknown;

export const useMessageGetConversationMessages = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageGetConversationMessages>>,
    TError,
    { data: ConversationMessagesDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof messageGetConversationMessages>>,
  TError,
  { data: ConversationMessagesDto },
  TContext
> => {
  const mutationOptions =
    getMessageGetConversationMessagesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const messageGetConversation = (conversationDto: ConversationDto) => {
  return customClient<ConversationResponseEntity>({
    url: `/message/conversation`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: conversationDto,
  });
};

export const getMessageGetConversationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageGetConversation>>,
    TError,
    { data: ConversationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof messageGetConversation>>,
  TError,
  { data: ConversationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof messageGetConversation>>,
    { data: ConversationDto }
  > = (props) => {
    const { data } = props ?? {};

    return messageGetConversation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MessageGetConversationMutationResult = NonNullable<
  Awaited<ReturnType<typeof messageGetConversation>>
>;
export type MessageGetConversationMutationBody = ConversationDto;
export type MessageGetConversationMutationError = unknown;

export const useMessageGetConversation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageGetConversation>>,
    TError,
    { data: ConversationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof messageGetConversation>>,
  TError,
  { data: ConversationDto },
  TContext
> => {
  const mutationOptions = getMessageGetConversationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const messageMarkConversationAsUnread = (conversationId: string) => {
  return customClient<MessageReadRecipientEntity>({
    url: `/message/conversations/${conversationId}/mark-unread`,
    method: "POST",
  });
};

export const getMessageMarkConversationAsUnreadMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageMarkConversationAsUnread>>,
    TError,
    { conversationId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof messageMarkConversationAsUnread>>,
  TError,
  { conversationId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof messageMarkConversationAsUnread>>,
    { conversationId: string }
  > = (props) => {
    const { conversationId } = props ?? {};

    return messageMarkConversationAsUnread(conversationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type MessageMarkConversationAsUnreadMutationResult = NonNullable<
  Awaited<ReturnType<typeof messageMarkConversationAsUnread>>
>;

export type MessageMarkConversationAsUnreadMutationError = unknown;

export const useMessageMarkConversationAsUnread = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageMarkConversationAsUnread>>,
    TError,
    { conversationId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof messageMarkConversationAsUnread>>,
  TError,
  { conversationId: string },
  TContext
> => {
  const mutationOptions =
    getMessageMarkConversationAsUnreadMutationOptions(options);

  return useMutation(mutationOptions);
};

export const messageDeleteConversation = (conversationId: string) => {
  return customClient<ConversationDeletionsEntity>({
    url: `/message/conversation/${conversationId}`,
    method: "DELETE",
  });
};

export const getMessageDeleteConversationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageDeleteConversation>>,
    TError,
    { conversationId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof messageDeleteConversation>>,
  TError,
  { conversationId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof messageDeleteConversation>>,
    { conversationId: string }
  > = (props) => {
    const { conversationId } = props ?? {};

    return messageDeleteConversation(conversationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type MessageDeleteConversationMutationResult = NonNullable<
  Awaited<ReturnType<typeof messageDeleteConversation>>
>;

export type MessageDeleteConversationMutationError = unknown;

export const useMessageDeleteConversation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageDeleteConversation>>,
    TError,
    { conversationId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof messageDeleteConversation>>,
  TError,
  { conversationId: string },
  TContext
> => {
  const mutationOptions = getMessageDeleteConversationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const messageSendMessage = (createMessageDto: CreateMessageDto) => {
  return customClient<ConversationMessageEntity>({
    url: `/message/send`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createMessageDto,
  });
};

export const getMessageSendMessageMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageSendMessage>>,
    TError,
    { data: CreateMessageDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof messageSendMessage>>,
  TError,
  { data: CreateMessageDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof messageSendMessage>>,
    { data: CreateMessageDto }
  > = (props) => {
    const { data } = props ?? {};

    return messageSendMessage(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MessageSendMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof messageSendMessage>>
>;
export type MessageSendMessageMutationBody = CreateMessageDto;
export type MessageSendMessageMutationError = unknown;

export const useMessageSendMessage = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageSendMessage>>,
    TError,
    { data: CreateMessageDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof messageSendMessage>>,
  TError,
  { data: CreateMessageDto },
  TContext
> => {
  const mutationOptions = getMessageSendMessageMutationOptions(options);

  return useMutation(mutationOptions);
};

export const messageReplyToMessage = (
  messageId: string,
  replyMessageDto: ReplyMessageDto,
) => {
  return customClient<MessageReplyToMessage200>({
    url: `/message/reply/${messageId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: replyMessageDto,
  });
};

export const getMessageReplyToMessageMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageReplyToMessage>>,
    TError,
    { messageId: string; data: ReplyMessageDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof messageReplyToMessage>>,
  TError,
  { messageId: string; data: ReplyMessageDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof messageReplyToMessage>>,
    { messageId: string; data: ReplyMessageDto }
  > = (props) => {
    const { messageId, data } = props ?? {};

    return messageReplyToMessage(messageId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MessageReplyToMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof messageReplyToMessage>>
>;
export type MessageReplyToMessageMutationBody = ReplyMessageDto;
export type MessageReplyToMessageMutationError = unknown;

export const useMessageReplyToMessage = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof messageReplyToMessage>>,
    TError,
    { messageId: string; data: ReplyMessageDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof messageReplyToMessage>>,
  TError,
  { messageId: string; data: ReplyMessageDto },
  TContext
> => {
  const mutationOptions = getMessageReplyToMessageMutationOptions(options);

  return useMutation(mutationOptions);
};

export const notificationSaveToken = (
  createNotificationTokenDto: CreateNotificationTokenDto,
) => {
  return customClient<UserNotificationTokenEntity>({
    url: `/notification`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createNotificationTokenDto,
  });
};

export const getNotificationSaveTokenMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationSaveToken>>,
    TError,
    { data: CreateNotificationTokenDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationSaveToken>>,
  TError,
  { data: CreateNotificationTokenDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationSaveToken>>,
    { data: CreateNotificationTokenDto }
  > = (props) => {
    const { data } = props ?? {};

    return notificationSaveToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationSaveTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationSaveToken>>
>;
export type NotificationSaveTokenMutationBody = CreateNotificationTokenDto;
export type NotificationSaveTokenMutationError = unknown;

export const useNotificationSaveToken = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationSaveToken>>,
    TError,
    { data: CreateNotificationTokenDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationSaveToken>>,
  TError,
  { data: CreateNotificationTokenDto },
  TContext
> => {
  const mutationOptions = getNotificationSaveTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const notificationDeleteToken = (token: string) => {
  return customClient<string>({
    url: `/notification/${token}`,
    method: "DELETE",
  });
};

export const getNotificationDeleteTokenMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationDeleteToken>>,
    TError,
    { token: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationDeleteToken>>,
  TError,
  { token: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationDeleteToken>>,
    { token: string }
  > = (props) => {
    const { token } = props ?? {};

    return notificationDeleteToken(token);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationDeleteTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationDeleteToken>>
>;

export type NotificationDeleteTokenMutationError = unknown;

export const useNotificationDeleteToken = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationDeleteToken>>,
    TError,
    { token: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationDeleteToken>>,
  TError,
  { token: string },
  TContext
> => {
  const mutationOptions = getNotificationDeleteTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const notificationGetUserNotifications = () => {
  return customClient<NotificationLogEntity[]>({
    url: `/notification/user`,
    method: "POST",
  });
};

export const getNotificationGetUserNotificationsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationGetUserNotifications>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationGetUserNotifications>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationGetUserNotifications>>,
    void
  > = () => {
    return notificationGetUserNotifications();
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationGetUserNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationGetUserNotifications>>
>;

export type NotificationGetUserNotificationsMutationError = unknown;

export const useNotificationGetUserNotifications = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationGetUserNotifications>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationGetUserNotifications>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getNotificationGetUserNotificationsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const notificationGetUserUnreadNotificationsCount = (
  signal?: AbortSignal,
) => {
  return customClient<number>({
    url: `/notification/user/unread/count`,
    method: "GET",
    signal,
  });
};

export const getNotificationGetUserUnreadNotificationsCountQueryKey = () => {
  return [`/notification/user/unread/count`] as const;
};

export const getNotificationGetUserUnreadNotificationsCountQueryOptions = <
  TData = Awaited<
    ReturnType<typeof notificationGetUserUnreadNotificationsCount>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof notificationGetUserUnreadNotificationsCount>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getNotificationGetUserUnreadNotificationsCountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationGetUserUnreadNotificationsCount>>
  > = ({ signal }) => notificationGetUserUnreadNotificationsCount(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationGetUserUnreadNotificationsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationGetUserUnreadNotificationsCountQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationGetUserUnreadNotificationsCount>>
  >;
export type NotificationGetUserUnreadNotificationsCountQueryError = unknown;

export const useNotificationGetUserUnreadNotificationsCount = <
  TData = Awaited<
    ReturnType<typeof notificationGetUserUnreadNotificationsCount>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof notificationGetUserUnreadNotificationsCount>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationGetUserUnreadNotificationsCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const notificationMarkNotificationAsClicked = (
  notificationId: string,
) => {
  return customClient<NotificationInteractionEntity>({
    url: `/notification/user/${notificationId}`,
    method: "PATCH",
  });
};

export const getNotificationMarkNotificationAsClickedMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationMarkNotificationAsClicked>>,
    TError,
    { notificationId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationMarkNotificationAsClicked>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationMarkNotificationAsClicked>>,
    { notificationId: string }
  > = (props) => {
    const { notificationId } = props ?? {};

    return notificationMarkNotificationAsClicked(notificationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationMarkNotificationAsClickedMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationMarkNotificationAsClicked>>
>;

export type NotificationMarkNotificationAsClickedMutationError = unknown;

export const useNotificationMarkNotificationAsClicked = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationMarkNotificationAsClicked>>,
    TError,
    { notificationId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationMarkNotificationAsClicked>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationMarkNotificationAsClickedMutationOptions(options);

  return useMutation(mutationOptions);
};

export const notificationDeleteUserNotification = (notificationId: string) => {
  return customClient<GeneralResponse>({
    url: `/notification/user/${notificationId}`,
    method: "DELETE",
  });
};

export const getNotificationDeleteUserNotificationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationDeleteUserNotification>>,
    TError,
    { notificationId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationDeleteUserNotification>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationDeleteUserNotification>>,
    { notificationId: string }
  > = (props) => {
    const { notificationId } = props ?? {};

    return notificationDeleteUserNotification(notificationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationDeleteUserNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationDeleteUserNotification>>
>;

export type NotificationDeleteUserNotificationMutationError = unknown;

export const useNotificationDeleteUserNotification = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationDeleteUserNotification>>,
    TError,
    { notificationId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationDeleteUserNotification>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationDeleteUserNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const notificationClearUserNotifications = () => {
  return customClient<GeneralResponse>({
    url: `/notification/user/clear`,
    method: "DELETE",
  });
};

export const getNotificationClearUserNotificationsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationClearUserNotifications>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationClearUserNotifications>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationClearUserNotifications>>,
    void
  > = () => {
    return notificationClearUserNotifications();
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationClearUserNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationClearUserNotifications>>
>;

export type NotificationClearUserNotificationsMutationError = unknown;

export const useNotificationClearUserNotifications = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationClearUserNotifications>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationClearUserNotifications>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getNotificationClearUserNotificationsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminAdminMetadata = (signal?: AbortSignal) => {
  return customClient<AdminMetadataResponse>({
    url: `/admin/metadata`,
    method: "GET",
    signal,
  });
};

export const getAdminAdminMetadataQueryKey = () => {
  return [`/admin/metadata`] as const;
};

export const getAdminAdminMetadataQueryOptions = <
  TData = Awaited<ReturnType<typeof adminAdminMetadata>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof adminAdminMetadata>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminAdminMetadataQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof adminAdminMetadata>>
  > = ({ signal }) => adminAdminMetadata(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminAdminMetadata>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminAdminMetadataQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminAdminMetadata>>
>;
export type AdminAdminMetadataQueryError = unknown;

export const useAdminAdminMetadata = <
  TData = Awaited<ReturnType<typeof adminAdminMetadata>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof adminAdminMetadata>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminAdminMetadataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminGetProfile = () => {
  return customClient<AdminLoginResponse>({
    url: `/admin/profile`,
    method: "POST",
  });
};

export const getAdminGetProfileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetProfile>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetProfile>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetProfile>>,
    void
  > = () => {
    return adminGetProfile();
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetProfile>>
>;

export type AdminGetProfileMutationError = unknown;

export const useAdminGetProfile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetProfile>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetProfile>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAdminGetProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminImpersonateUser = (
  impersonateUserDto: ImpersonateUserDto,
) => {
  return customClient<ImpersonateResponse>({
    url: `/admin/user/impersonate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: impersonateUserDto,
  });
};

export const getAdminImpersonateUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminImpersonateUser>>,
    TError,
    { data: ImpersonateUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminImpersonateUser>>,
  TError,
  { data: ImpersonateUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminImpersonateUser>>,
    { data: ImpersonateUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminImpersonateUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminImpersonateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminImpersonateUser>>
>;
export type AdminImpersonateUserMutationBody = ImpersonateUserDto;
export type AdminImpersonateUserMutationError = unknown;

export const useAdminImpersonateUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminImpersonateUser>>,
    TError,
    { data: ImpersonateUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminImpersonateUser>>,
  TError,
  { data: ImpersonateUserDto },
  TContext
> => {
  const mutationOptions = getAdminImpersonateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminImpersonateOrganization = (
  impersonateOrganizationDto: ImpersonateOrganizationDto,
) => {
  return customClient<ImpersonateResponse>({
    url: `/admin/organization/impersonate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: impersonateOrganizationDto,
  });
};

export const getAdminImpersonateOrganizationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminImpersonateOrganization>>,
    TError,
    { data: ImpersonateOrganizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminImpersonateOrganization>>,
  TError,
  { data: ImpersonateOrganizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminImpersonateOrganization>>,
    { data: ImpersonateOrganizationDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminImpersonateOrganization(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminImpersonateOrganizationMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminImpersonateOrganization>>
>;
export type AdminImpersonateOrganizationMutationBody =
  ImpersonateOrganizationDto;
export type AdminImpersonateOrganizationMutationError = unknown;

export const useAdminImpersonateOrganization = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminImpersonateOrganization>>,
    TError,
    { data: ImpersonateOrganizationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminImpersonateOrganization>>,
  TError,
  { data: ImpersonateOrganizationDto },
  TContext
> => {
  const mutationOptions =
    getAdminImpersonateOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetUsers = (usersDto: UsersDto) => {
  return customClient<UsersResponse>({
    url: `/admin/users`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: usersDto,
  });
};

export const getAdminGetUsersMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetUsers>>,
    TError,
    { data: UsersDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetUsers>>,
  TError,
  { data: UsersDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetUsers>>,
    { data: UsersDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetUsers(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetUsers>>
>;
export type AdminGetUsersMutationBody = UsersDto;
export type AdminGetUsersMutationError = unknown;

export const useAdminGetUsers = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetUsers>>,
    TError,
    { data: UsersDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetUsers>>,
  TError,
  { data: UsersDto },
  TContext
> => {
  const mutationOptions = getAdminGetUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetDropdownUsers = (dropdownUsersDto: DropdownUsersDto) => {
  return customClient<UserEntity[]>({
    url: `/admin/dropdown-users`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: dropdownUsersDto,
  });
};

export const getAdminGetDropdownUsersMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDropdownUsers>>,
    TError,
    { data: DropdownUsersDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetDropdownUsers>>,
  TError,
  { data: DropdownUsersDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetDropdownUsers>>,
    { data: DropdownUsersDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetDropdownUsers(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetDropdownUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetDropdownUsers>>
>;
export type AdminGetDropdownUsersMutationBody = DropdownUsersDto;
export type AdminGetDropdownUsersMutationError = unknown;

export const useAdminGetDropdownUsers = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDropdownUsers>>,
    TError,
    { data: DropdownUsersDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetDropdownUsers>>,
  TError,
  { data: DropdownUsersDto },
  TContext
> => {
  const mutationOptions = getAdminGetDropdownUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetOrganizations = (organizationsDto: OrganizationsDto) => {
  return customClient<OrganizationsResponse>({
    url: `/admin/organizations`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationsDto,
  });
};

export const getAdminGetOrganizationsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetOrganizations>>,
    TError,
    { data: OrganizationsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetOrganizations>>,
  TError,
  { data: OrganizationsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetOrganizations>>,
    { data: OrganizationsDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetOrganizations(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetOrganizationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetOrganizations>>
>;
export type AdminGetOrganizationsMutationBody = OrganizationsDto;
export type AdminGetOrganizationsMutationError = unknown;

export const useAdminGetOrganizations = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetOrganizations>>,
    TError,
    { data: OrganizationsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetOrganizations>>,
  TError,
  { data: OrganizationsDto },
  TContext
> => {
  const mutationOptions = getAdminGetOrganizationsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetOrganizationProfile = (id: string) => {
  return customClient<OrganizationEntityResponse>({
    url: `/admin/organization/profile/${id}`,
    method: "POST",
  });
};

export const getAdminGetOrganizationProfileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetOrganizationProfile>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetOrganizationProfile>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetOrganizationProfile>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return adminGetOrganizationProfile(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetOrganizationProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetOrganizationProfile>>
>;

export type AdminGetOrganizationProfileMutationError = unknown;

export const useAdminGetOrganizationProfile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetOrganizationProfile>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetOrganizationProfile>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions =
    getAdminGetOrganizationProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminCreateOrganization = (
  createOrganizationDto: CreateOrganizationDto,
) => {
  return customClient<OrganizationEntityResponse>({
    url: `/admin/organization/create`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createOrganizationDto,
  });
};

export const getAdminCreateOrganizationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreateOrganization>>,
    TError,
    { data: CreateOrganizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminCreateOrganization>>,
  TError,
  { data: CreateOrganizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminCreateOrganization>>,
    { data: CreateOrganizationDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminCreateOrganization(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminCreateOrganizationMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminCreateOrganization>>
>;
export type AdminCreateOrganizationMutationBody = CreateOrganizationDto;
export type AdminCreateOrganizationMutationError = unknown;

export const useAdminCreateOrganization = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreateOrganization>>,
    TError,
    { data: CreateOrganizationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminCreateOrganization>>,
  TError,
  { data: CreateOrganizationDto },
  TContext
> => {
  const mutationOptions = getAdminCreateOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUpdateOrganization = (
  id: string,
  updateOrganizationDto: UpdateOrganizationDto,
) => {
  return customClient<OrganizationEntityResponse>({
    url: `/admin/organization/update/${id}`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: updateOrganizationDto,
  });
};

export const getAdminUpdateOrganizationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateOrganization>>,
    TError,
    { id: string; data: UpdateOrganizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUpdateOrganization>>,
  TError,
  { id: string; data: UpdateOrganizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUpdateOrganization>>,
    { id: string; data: UpdateOrganizationDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return adminUpdateOrganization(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUpdateOrganizationMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUpdateOrganization>>
>;
export type AdminUpdateOrganizationMutationBody = UpdateOrganizationDto;
export type AdminUpdateOrganizationMutationError = unknown;

export const useAdminUpdateOrganization = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateOrganization>>,
    TError,
    { id: string; data: UpdateOrganizationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminUpdateOrganization>>,
  TError,
  { id: string; data: UpdateOrganizationDto },
  TContext
> => {
  const mutationOptions = getAdminUpdateOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDeleteOrganization = (id: string) => {
  return customClient<string>({
    url: `/admin/organization/delete/${id}`,
    method: "DELETE",
  });
};

export const getAdminDeleteOrganizationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteOrganization>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDeleteOrganization>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDeleteOrganization>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return adminDeleteOrganization(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDeleteOrganizationMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDeleteOrganization>>
>;

export type AdminDeleteOrganizationMutationError = unknown;

export const useAdminDeleteOrganization = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteOrganization>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDeleteOrganization>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getAdminDeleteOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetUserProfile = (id: string, signal?: AbortSignal) => {
  return customClient<UserResponseEntity>({
    url: `/admin/user/profile/${id}`,
    method: "GET",
    signal,
  });
};

export const getAdminGetUserProfileQueryKey = (id: string) => {
  return [`/admin/user/profile/${id}`] as const;
};

export const getAdminGetUserProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetUserProfile>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof adminGetUserProfile>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminGetUserProfileQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof adminGetUserProfile>>
  > = ({ signal }) => adminGetUserProfile(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetUserProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetUserProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminGetUserProfile>>
>;
export type AdminGetUserProfileQueryError = unknown;

export const useAdminGetUserProfile = <
  TData = Awaited<ReturnType<typeof adminGetUserProfile>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof adminGetUserProfile>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminGetUserProfileQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminUpdateUserProfile = (
  id: string,
  updateUserDto: UpdateUserDto,
) => {
  return customClient<UserResponseEntity>({
    url: `/admin/user/profile/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateUserDto,
  });
};

export const getAdminUpdateUserProfileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateUserProfile>>,
    TError,
    { id: string; data: UpdateUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUpdateUserProfile>>,
  TError,
  { id: string; data: UpdateUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUpdateUserProfile>>,
    { id: string; data: UpdateUserDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return adminUpdateUserProfile(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUpdateUserProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUpdateUserProfile>>
>;
export type AdminUpdateUserProfileMutationBody = UpdateUserDto;
export type AdminUpdateUserProfileMutationError = unknown;

export const useAdminUpdateUserProfile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateUserProfile>>,
    TError,
    { id: string; data: UpdateUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminUpdateUserProfile>>,
  TError,
  { id: string; data: UpdateUserDto },
  TContext
> => {
  const mutationOptions = getAdminUpdateUserProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDeleteUser = (id: string) => {
  return customClient<string>({
    url: `/admin/user/delete/${id}`,
    method: "DELETE",
  });
};

export const getAdminDeleteUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteUser>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDeleteUser>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDeleteUser>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return adminDeleteUser(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDeleteUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDeleteUser>>
>;

export type AdminDeleteUserMutationError = unknown;

export const useAdminDeleteUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteUser>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDeleteUser>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getAdminDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminBlockUser = (banUserDto: BanUserDto) => {
  return customClient<string>({
    url: `/admin/user/block/toggle`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: banUserDto,
  });
};

export const getAdminBlockUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminBlockUser>>,
    TError,
    { data: BanUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminBlockUser>>,
  TError,
  { data: BanUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminBlockUser>>,
    { data: BanUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminBlockUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminBlockUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminBlockUser>>
>;
export type AdminBlockUserMutationBody = BanUserDto;
export type AdminBlockUserMutationError = unknown;

export const useAdminBlockUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminBlockUser>>,
    TError,
    { data: BanUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminBlockUser>>,
  TError,
  { data: BanUserDto },
  TContext
> => {
  const mutationOptions = getAdminBlockUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminCreate = (createAssetDto: CreateAssetDto) => {
  return customClient<AssetEntity>({
    url: `/admin/asset`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createAssetDto,
  });
};

export const getAdminCreateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreate>>,
    TError,
    { data: CreateAssetDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminCreate>>,
  TError,
  { data: CreateAssetDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminCreate>>,
    { data: CreateAssetDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminCreate>>
>;
export type AdminCreateMutationBody = CreateAssetDto;
export type AdminCreateMutationError = unknown;

export const useAdminCreate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreate>>,
    TError,
    { data: CreateAssetDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminCreate>>,
  TError,
  { data: CreateAssetDto },
  TContext
> => {
  const mutationOptions = getAdminCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUploadFile = () => {
  return customClient<string>({ url: `/admin/asset/upload`, method: "POST" });
};

export const getAdminUploadFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUploadFile>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUploadFile>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUploadFile>>,
    void
  > = () => {
    return adminUploadFile();
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUploadFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUploadFile>>
>;

export type AdminUploadFileMutationError = unknown;

export const useAdminUploadFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUploadFile>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminUploadFile>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAdminUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetAcademies = (academyDto: AcademyDto) => {
  return customClient<AcademyResponse>({
    url: `/admin/leads/academies`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: academyDto,
  });
};

export const getAdminGetAcademiesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetAcademies>>,
    TError,
    { data: AcademyDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetAcademies>>,
  TError,
  { data: AcademyDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetAcademies>>,
    { data: AcademyDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetAcademies(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetAcademiesMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetAcademies>>
>;
export type AdminGetAcademiesMutationBody = AcademyDto;
export type AdminGetAcademiesMutationError = unknown;

export const useAdminGetAcademies = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetAcademies>>,
    TError,
    { data: AcademyDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetAcademies>>,
  TError,
  { data: AcademyDto },
  TContext
> => {
  const mutationOptions = getAdminGetAcademiesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetOrganizationInquiries = (
  organizationInquiryDto: OrganizationInquiryDto,
) => {
  return customClient<OrganizationInquiryResponse>({
    url: `/admin/leads/organization/inquiries`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationInquiryDto,
  });
};

export const getAdminGetOrganizationInquiriesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetOrganizationInquiries>>,
    TError,
    { data: OrganizationInquiryDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetOrganizationInquiries>>,
  TError,
  { data: OrganizationInquiryDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetOrganizationInquiries>>,
    { data: OrganizationInquiryDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetOrganizationInquiries(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetOrganizationInquiriesMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetOrganizationInquiries>>
>;
export type AdminGetOrganizationInquiriesMutationBody = OrganizationInquiryDto;
export type AdminGetOrganizationInquiriesMutationError = unknown;

export const useAdminGetOrganizationInquiries = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetOrganizationInquiries>>,
    TError,
    { data: OrganizationInquiryDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetOrganizationInquiries>>,
  TError,
  { data: OrganizationInquiryDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetOrganizationInquiriesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetNewsAttachmentsLeads = (
  newsAttachmentLeadDto: NewsAttachmentLeadDto,
) => {
  return customClient<NewsAttachmentLeadResponse>({
    url: `/admin/leads/news/attachemts`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: newsAttachmentLeadDto,
  });
};

export const getAdminGetNewsAttachmentsLeadsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetNewsAttachmentsLeads>>,
    TError,
    { data: NewsAttachmentLeadDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetNewsAttachmentsLeads>>,
  TError,
  { data: NewsAttachmentLeadDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetNewsAttachmentsLeads>>,
    { data: NewsAttachmentLeadDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetNewsAttachmentsLeads(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetNewsAttachmentsLeadsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetNewsAttachmentsLeads>>
>;
export type AdminGetNewsAttachmentsLeadsMutationBody = NewsAttachmentLeadDto;
export type AdminGetNewsAttachmentsLeadsMutationError = unknown;

export const useAdminGetNewsAttachmentsLeads = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetNewsAttachmentsLeads>>,
    TError,
    { data: NewsAttachmentLeadDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetNewsAttachmentsLeads>>,
  TError,
  { data: NewsAttachmentLeadDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetNewsAttachmentsLeadsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetWorkprides = (workPrideDto: WorkPrideDto) => {
  return customClient<AdminWorkPrideResponse>({
    url: `/admin/leads/workpride`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: workPrideDto,
  });
};

export const getAdminGetWorkpridesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetWorkprides>>,
    TError,
    { data: WorkPrideDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetWorkprides>>,
  TError,
  { data: WorkPrideDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetWorkprides>>,
    { data: WorkPrideDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetWorkprides(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetWorkpridesMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetWorkprides>>
>;
export type AdminGetWorkpridesMutationBody = WorkPrideDto;
export type AdminGetWorkpridesMutationError = unknown;

export const useAdminGetWorkprides = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetWorkprides>>,
    TError,
    { data: WorkPrideDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetWorkprides>>,
  TError,
  { data: WorkPrideDto },
  TContext
> => {
  const mutationOptions = getAdminGetWorkpridesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetWorkFairLeads = (workFairDto: WorkFairDto) => {
  return customClient<AdminWorkFairResponse>({
    url: `/admin/leads/workfair`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: workFairDto,
  });
};

export const getAdminGetWorkFairLeadsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetWorkFairLeads>>,
    TError,
    { data: WorkFairDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetWorkFairLeads>>,
  TError,
  { data: WorkFairDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetWorkFairLeads>>,
    { data: WorkFairDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetWorkFairLeads(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetWorkFairLeadsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetWorkFairLeads>>
>;
export type AdminGetWorkFairLeadsMutationBody = WorkFairDto;
export type AdminGetWorkFairLeadsMutationError = unknown;

export const useAdminGetWorkFairLeads = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetWorkFairLeads>>,
    TError,
    { data: WorkFairDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetWorkFairLeads>>,
  TError,
  { data: WorkFairDto },
  TContext
> => {
  const mutationOptions = getAdminGetWorkFairLeadsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetJobsScrapers = (jobsScrapersDto: JobsScrapersDto) => {
  return customClient<JobsScrapersResponse>({
    url: `/admin/jobs/scrapers`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: jobsScrapersDto,
  });
};

export const getAdminGetJobsScrapersMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetJobsScrapers>>,
    TError,
    { data: JobsScrapersDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetJobsScrapers>>,
  TError,
  { data: JobsScrapersDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetJobsScrapers>>,
    { data: JobsScrapersDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetJobsScrapers(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetJobsScrapersMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetJobsScrapers>>
>;
export type AdminGetJobsScrapersMutationBody = JobsScrapersDto;
export type AdminGetJobsScrapersMutationError = unknown;

export const useAdminGetJobsScrapers = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetJobsScrapers>>,
    TError,
    { data: JobsScrapersDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetJobsScrapers>>,
  TError,
  { data: JobsScrapersDto },
  TContext
> => {
  const mutationOptions = getAdminGetJobsScrapersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminToggleScraperStatus = (id: string) => {
  return customClient<string>({
    url: `/admin/jobs/scraper/toggle/${id}`,
    method: "PATCH",
  });
};

export const getAdminToggleScraperStatusMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminToggleScraperStatus>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminToggleScraperStatus>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminToggleScraperStatus>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return adminToggleScraperStatus(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminToggleScraperStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminToggleScraperStatus>>
>;

export type AdminToggleScraperStatusMutationError = unknown;

export const useAdminToggleScraperStatus = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminToggleScraperStatus>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminToggleScraperStatus>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getAdminToggleScraperStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetUserLoginReports = (
  userLoginReportDto: UserLoginReportDto,
) => {
  return customClient<UserLoginReportData>({
    url: `/admin/reports/user-login/generate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userLoginReportDto,
  });
};

export const getAdminGetUserLoginReportsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetUserLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetUserLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetUserLoginReports>>,
    { data: UserLoginReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetUserLoginReports(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetUserLoginReportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetUserLoginReports>>
>;
export type AdminGetUserLoginReportsMutationBody = UserLoginReportDto;
export type AdminGetUserLoginReportsMutationError = unknown;

export const useAdminGetUserLoginReports = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetUserLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetUserLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const mutationOptions = getAdminGetUserLoginReportsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetDownloadUserLoginReports = (
  userLoginReportDto: UserLoginReportDto,
) => {
  return customClient<UserLoginData[]>({
    url: `/admin/reports/user-login/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userLoginReportDto,
  });
};

export const getAdminGetDownloadUserLoginReportsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDownloadUserLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetDownloadUserLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetDownloadUserLoginReports>>,
    { data: UserLoginReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetDownloadUserLoginReports(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetDownloadUserLoginReportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetDownloadUserLoginReports>>
>;
export type AdminGetDownloadUserLoginReportsMutationBody = UserLoginReportDto;
export type AdminGetDownloadUserLoginReportsMutationError = unknown;

export const useAdminGetDownloadUserLoginReports = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDownloadUserLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetDownloadUserLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetDownloadUserLoginReportsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetUserAverageLoginReports = (
  userLoginReportDto: UserLoginReportDto,
) => {
  return customClient<UserAverageLoginReportData>({
    url: `/admin/reports/average-login/generate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userLoginReportDto,
  });
};

export const getAdminGetUserAverageLoginReportsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetUserAverageLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetUserAverageLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetUserAverageLoginReports>>,
    { data: UserLoginReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetUserAverageLoginReports(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetUserAverageLoginReportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetUserAverageLoginReports>>
>;
export type AdminGetUserAverageLoginReportsMutationBody = UserLoginReportDto;
export type AdminGetUserAverageLoginReportsMutationError = unknown;

export const useAdminGetUserAverageLoginReports = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetUserAverageLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetUserAverageLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetUserAverageLoginReportsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetDownloadUserAverageLoginReports = (
  userLoginReportDto: UserLoginReportDto,
) => {
  return customClient<UserAverageLoginData[]>({
    url: `/admin/reports/average-login/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userLoginReportDto,
  });
};

export const getAdminGetDownloadUserAverageLoginReportsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDownloadUserAverageLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetDownloadUserAverageLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetDownloadUserAverageLoginReports>>,
    { data: UserLoginReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetDownloadUserAverageLoginReports(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetDownloadUserAverageLoginReportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetDownloadUserAverageLoginReports>>
>;
export type AdminGetDownloadUserAverageLoginReportsMutationBody =
  UserLoginReportDto;
export type AdminGetDownloadUserAverageLoginReportsMutationError = unknown;

export const useAdminGetDownloadUserAverageLoginReports = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDownloadUserAverageLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetDownloadUserAverageLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetDownloadUserAverageLoginReportsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetJobClicksViews = (
  jobClicksViewsReportDto: JobClicksViewsReportDto,
) => {
  return customClient<JobClicksViewsReportData>({
    url: `/admin/reports/job-clicks-views/generate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: jobClicksViewsReportDto,
  });
};

export const getAdminGetJobClicksViewsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetJobClicksViews>>,
    TError,
    { data: JobClicksViewsReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetJobClicksViews>>,
  TError,
  { data: JobClicksViewsReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetJobClicksViews>>,
    { data: JobClicksViewsReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetJobClicksViews(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetJobClicksViewsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetJobClicksViews>>
>;
export type AdminGetJobClicksViewsMutationBody = JobClicksViewsReportDto;
export type AdminGetJobClicksViewsMutationError = unknown;

export const useAdminGetJobClicksViews = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetJobClicksViews>>,
    TError,
    { data: JobClicksViewsReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetJobClicksViews>>,
  TError,
  { data: JobClicksViewsReportDto },
  TContext
> => {
  const mutationOptions = getAdminGetJobClicksViewsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetDownloadJobClicksViews = (
  jobClicksViewsReportDto: JobClicksViewsReportDto,
) => {
  return customClient<JobClicksViewsData[]>({
    url: `/admin/reports/job-clicks-views/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: jobClicksViewsReportDto,
  });
};

export const getAdminGetDownloadJobClicksViewsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDownloadJobClicksViews>>,
    TError,
    { data: JobClicksViewsReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetDownloadJobClicksViews>>,
  TError,
  { data: JobClicksViewsReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetDownloadJobClicksViews>>,
    { data: JobClicksViewsReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetDownloadJobClicksViews(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetDownloadJobClicksViewsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetDownloadJobClicksViews>>
>;
export type AdminGetDownloadJobClicksViewsMutationBody =
  JobClicksViewsReportDto;
export type AdminGetDownloadJobClicksViewsMutationError = unknown;

export const useAdminGetDownloadJobClicksViews = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDownloadJobClicksViews>>,
    TError,
    { data: JobClicksViewsReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetDownloadJobClicksViews>>,
  TError,
  { data: JobClicksViewsReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetDownloadJobClicksViewsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDownloadJobsByClicks = () => {
  return customClient<JobByClicksData[]>({
    url: `/admin/reports/jobs-by-clicks/download`,
    method: "POST",
  });
};

export const getAdminDownloadJobsByClicksMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadJobsByClicks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDownloadJobsByClicks>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDownloadJobsByClicks>>,
    void
  > = () => {
    return adminDownloadJobsByClicks();
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDownloadJobsByClicksMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDownloadJobsByClicks>>
>;

export type AdminDownloadJobsByClicksMutationError = unknown;

export const useAdminDownloadJobsByClicks = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadJobsByClicks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDownloadJobsByClicks>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAdminDownloadJobsByClicksMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminJobsByCountry = () => {
  return customClient<JobByCountryData[]>({
    url: `/admin/reports/jobs-by-country/generate`,
    method: "POST",
  });
};

export const getAdminJobsByCountryMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminJobsByCountry>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminJobsByCountry>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminJobsByCountry>>,
    void
  > = () => {
    return adminJobsByCountry();
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminJobsByCountryMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminJobsByCountry>>
>;

export type AdminJobsByCountryMutationError = unknown;

export const useAdminJobsByCountry = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminJobsByCountry>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminJobsByCountry>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAdminJobsByCountryMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminJobsByMissingDescription = () => {
  return customClient<JobByMissingDescriptionData[]>({
    url: `/admin/reports/jobs-by-missing-description/download`,
    method: "POST",
  });
};

export const getAdminJobsByMissingDescriptionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminJobsByMissingDescription>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminJobsByMissingDescription>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminJobsByMissingDescription>>,
    void
  > = () => {
    return adminJobsByMissingDescription();
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminJobsByMissingDescriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminJobsByMissingDescription>>
>;

export type AdminJobsByMissingDescriptionMutationError = unknown;

export const useAdminJobsByMissingDescription = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminJobsByMissingDescription>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminJobsByMissingDescription>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getAdminJobsByMissingDescriptionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetMonthlyUniqueLoginReports = (
  userLoginReportDto: UserLoginReportDto,
) => {
  return customClient<MonthlyUniqueLoginReportData>({
    url: `/admin/reports/monthly-unique-login/generate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userLoginReportDto,
  });
};

export const getAdminGetMonthlyUniqueLoginReportsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetMonthlyUniqueLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetMonthlyUniqueLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetMonthlyUniqueLoginReports>>,
    { data: UserLoginReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetMonthlyUniqueLoginReports(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetMonthlyUniqueLoginReportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetMonthlyUniqueLoginReports>>
>;
export type AdminGetMonthlyUniqueLoginReportsMutationBody = UserLoginReportDto;
export type AdminGetMonthlyUniqueLoginReportsMutationError = unknown;

export const useAdminGetMonthlyUniqueLoginReports = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetMonthlyUniqueLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetMonthlyUniqueLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetMonthlyUniqueLoginReportsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetDownloadMonthlyUniqueLoginReports = (
  userLoginReportDto: UserLoginReportDto,
) => {
  return customClient<MonthlyUniqueLoginData[]>({
    url: `/admin/reports/monthly-unique-login/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userLoginReportDto,
  });
};

export const getAdminGetDownloadMonthlyUniqueLoginReportsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDownloadMonthlyUniqueLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetDownloadMonthlyUniqueLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetDownloadMonthlyUniqueLoginReports>>,
    { data: UserLoginReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetDownloadMonthlyUniqueLoginReports(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetDownloadMonthlyUniqueLoginReportsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof adminGetDownloadMonthlyUniqueLoginReports>>
  >;
export type AdminGetDownloadMonthlyUniqueLoginReportsMutationBody =
  UserLoginReportDto;
export type AdminGetDownloadMonthlyUniqueLoginReportsMutationError = unknown;

export const useAdminGetDownloadMonthlyUniqueLoginReports = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDownloadMonthlyUniqueLoginReports>>,
    TError,
    { data: UserLoginReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetDownloadMonthlyUniqueLoginReports>>,
  TError,
  { data: UserLoginReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetDownloadMonthlyUniqueLoginReportsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGenerateOrganizationClicksReport = (
  organizationClicksReportDto: OrganizationClicksReportDto,
) => {
  return customClient<OrganizationClicksReportData>({
    url: `/admin/reports/organization-clicks/generate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationClicksReportDto,
  });
};

export const getAdminGenerateOrganizationClicksReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGenerateOrganizationClicksReport>>,
    TError,
    { data: OrganizationClicksReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGenerateOrganizationClicksReport>>,
  TError,
  { data: OrganizationClicksReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGenerateOrganizationClicksReport>>,
    { data: OrganizationClicksReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGenerateOrganizationClicksReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGenerateOrganizationClicksReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGenerateOrganizationClicksReport>>
>;
export type AdminGenerateOrganizationClicksReportMutationBody =
  OrganizationClicksReportDto;
export type AdminGenerateOrganizationClicksReportMutationError = unknown;

export const useAdminGenerateOrganizationClicksReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGenerateOrganizationClicksReport>>,
    TError,
    { data: OrganizationClicksReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGenerateOrganizationClicksReport>>,
  TError,
  { data: OrganizationClicksReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminGenerateOrganizationClicksReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDownloadOrganizationClicksReport = (
  organizationClicksReportDto: OrganizationClicksReportDto,
) => {
  return customClient<OrganizationClicksData[]>({
    url: `/admin/reports/organization-clicks/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationClicksReportDto,
  });
};

export const getAdminDownloadOrganizationClicksReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadOrganizationClicksReport>>,
    TError,
    { data: OrganizationClicksReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDownloadOrganizationClicksReport>>,
  TError,
  { data: OrganizationClicksReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDownloadOrganizationClicksReport>>,
    { data: OrganizationClicksReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminDownloadOrganizationClicksReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDownloadOrganizationClicksReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDownloadOrganizationClicksReport>>
>;
export type AdminDownloadOrganizationClicksReportMutationBody =
  OrganizationClicksReportDto;
export type AdminDownloadOrganizationClicksReportMutationError = unknown;

export const useAdminDownloadOrganizationClicksReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadOrganizationClicksReport>>,
    TError,
    { data: OrganizationClicksReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDownloadOrganizationClicksReport>>,
  TError,
  { data: OrganizationClicksReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminDownloadOrganizationClicksReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGenerateOrganizationJobsStatsReport = (
  organizationJobsStatsReportDto: OrganizationJobsStatsReportDto,
) => {
  return customClient<OrganizationJobsStatsReportData>({
    url: `/admin/reports/organization-jobs-stats/generate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationJobsStatsReportDto,
  });
};

export const getAdminGenerateOrganizationJobsStatsReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGenerateOrganizationJobsStatsReport>>,
    TError,
    { data: OrganizationJobsStatsReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGenerateOrganizationJobsStatsReport>>,
  TError,
  { data: OrganizationJobsStatsReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGenerateOrganizationJobsStatsReport>>,
    { data: OrganizationJobsStatsReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGenerateOrganizationJobsStatsReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGenerateOrganizationJobsStatsReportMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof adminGenerateOrganizationJobsStatsReport>>
  >;
export type AdminGenerateOrganizationJobsStatsReportMutationBody =
  OrganizationJobsStatsReportDto;
export type AdminGenerateOrganizationJobsStatsReportMutationError = unknown;

export const useAdminGenerateOrganizationJobsStatsReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGenerateOrganizationJobsStatsReport>>,
    TError,
    { data: OrganizationJobsStatsReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGenerateOrganizationJobsStatsReport>>,
  TError,
  { data: OrganizationJobsStatsReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminGenerateOrganizationJobsStatsReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDownloadOrganizationJobsStatsReport = (
  organizationJobsStatsReportDto: OrganizationJobsStatsReportDto,
) => {
  return customClient<OrganizationJobsStatsData[]>({
    url: `/admin/reports/organization-jobs-stats/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: organizationJobsStatsReportDto,
  });
};

export const getAdminDownloadOrganizationJobsStatsReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadOrganizationJobsStatsReport>>,
    TError,
    { data: OrganizationJobsStatsReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDownloadOrganizationJobsStatsReport>>,
  TError,
  { data: OrganizationJobsStatsReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDownloadOrganizationJobsStatsReport>>,
    { data: OrganizationJobsStatsReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminDownloadOrganizationJobsStatsReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDownloadOrganizationJobsStatsReportMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof adminDownloadOrganizationJobsStatsReport>>
  >;
export type AdminDownloadOrganizationJobsStatsReportMutationBody =
  OrganizationJobsStatsReportDto;
export type AdminDownloadOrganizationJobsStatsReportMutationError = unknown;

export const useAdminDownloadOrganizationJobsStatsReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadOrganizationJobsStatsReport>>,
    TError,
    { data: OrganizationJobsStatsReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDownloadOrganizationJobsStatsReport>>,
  TError,
  { data: OrganizationJobsStatsReportDto },
  TContext
> => {
  const mutationOptions =
    getAdminDownloadOrganizationJobsStatsReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDownloadOrganizationIdsReport = () => {
  return customClient<OrganizationIdsReportResponse[]>({
    url: `/admin/reports/organization-ids/download`,
    method: "POST",
  });
};

export const getAdminDownloadOrganizationIdsReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadOrganizationIdsReport>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDownloadOrganizationIdsReport>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDownloadOrganizationIdsReport>>,
    void
  > = () => {
    return adminDownloadOrganizationIdsReport();
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDownloadOrganizationIdsReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDownloadOrganizationIdsReport>>
>;

export type AdminDownloadOrganizationIdsReportMutationError = unknown;

export const useAdminDownloadOrganizationIdsReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadOrganizationIdsReport>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDownloadOrganizationIdsReport>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getAdminDownloadOrganizationIdsReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetEntryLevelJobsReport = (
  entryLevelJobReportsDto: EntryLevelJobReportsDto,
) => {
  return customClient<EntryLevelJobsReportData>({
    url: `/admin/reports/entry-level-jobs/generate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: entryLevelJobReportsDto,
  });
};

export const getAdminGetEntryLevelJobsReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetEntryLevelJobsReport>>,
    TError,
    { data: EntryLevelJobReportsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetEntryLevelJobsReport>>,
  TError,
  { data: EntryLevelJobReportsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetEntryLevelJobsReport>>,
    { data: EntryLevelJobReportsDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetEntryLevelJobsReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetEntryLevelJobsReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetEntryLevelJobsReport>>
>;
export type AdminGetEntryLevelJobsReportMutationBody = EntryLevelJobReportsDto;
export type AdminGetEntryLevelJobsReportMutationError = unknown;

export const useAdminGetEntryLevelJobsReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetEntryLevelJobsReport>>,
    TError,
    { data: EntryLevelJobReportsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetEntryLevelJobsReport>>,
  TError,
  { data: EntryLevelJobReportsDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetEntryLevelJobsReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDownloadEntryLevelJobsReport = (
  entryLevelJobReportsDto: EntryLevelJobReportsDto,
) => {
  return customClient<EntryLevelJobsData[]>({
    url: `/admin/reports/entry-level-jobs/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: entryLevelJobReportsDto,
  });
};

export const getAdminDownloadEntryLevelJobsReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadEntryLevelJobsReport>>,
    TError,
    { data: EntryLevelJobReportsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDownloadEntryLevelJobsReport>>,
  TError,
  { data: EntryLevelJobReportsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDownloadEntryLevelJobsReport>>,
    { data: EntryLevelJobReportsDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminDownloadEntryLevelJobsReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDownloadEntryLevelJobsReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDownloadEntryLevelJobsReport>>
>;
export type AdminDownloadEntryLevelJobsReportMutationBody =
  EntryLevelJobReportsDto;
export type AdminDownloadEntryLevelJobsReportMutationError = unknown;

export const useAdminDownloadEntryLevelJobsReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadEntryLevelJobsReport>>,
    TError,
    { data: EntryLevelJobReportsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDownloadEntryLevelJobsReport>>,
  TError,
  { data: EntryLevelJobReportsDto },
  TContext
> => {
  const mutationOptions =
    getAdminDownloadEntryLevelJobsReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetEntryLevelVsRegularJobs = (
  entryLevelJobReportsDto: EntryLevelJobReportsDto,
) => {
  return customClient<EntryLevelVsRegularJobsReportData>({
    url: `/admin/reports/entry-level-vs-regular-jobs/generate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: entryLevelJobReportsDto,
  });
};

export const getAdminGetEntryLevelVsRegularJobsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetEntryLevelVsRegularJobs>>,
    TError,
    { data: EntryLevelJobReportsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetEntryLevelVsRegularJobs>>,
  TError,
  { data: EntryLevelJobReportsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetEntryLevelVsRegularJobs>>,
    { data: EntryLevelJobReportsDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetEntryLevelVsRegularJobs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetEntryLevelVsRegularJobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetEntryLevelVsRegularJobs>>
>;
export type AdminGetEntryLevelVsRegularJobsMutationBody =
  EntryLevelJobReportsDto;
export type AdminGetEntryLevelVsRegularJobsMutationError = unknown;

export const useAdminGetEntryLevelVsRegularJobs = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetEntryLevelVsRegularJobs>>,
    TError,
    { data: EntryLevelJobReportsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetEntryLevelVsRegularJobs>>,
  TError,
  { data: EntryLevelJobReportsDto },
  TContext
> => {
  const mutationOptions =
    getAdminGetEntryLevelVsRegularJobsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDownloadEntryLevelVsRegularJobs = (
  entryLevelJobReportsDto: EntryLevelJobReportsDto,
) => {
  return customClient<EntryLevelVsRegularJobsData[]>({
    url: `/admin/reports/entry-level-vs-regular-jobs/download`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: entryLevelJobReportsDto,
  });
};

export const getAdminDownloadEntryLevelVsRegularJobsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadEntryLevelVsRegularJobs>>,
    TError,
    { data: EntryLevelJobReportsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDownloadEntryLevelVsRegularJobs>>,
  TError,
  { data: EntryLevelJobReportsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDownloadEntryLevelVsRegularJobs>>,
    { data: EntryLevelJobReportsDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminDownloadEntryLevelVsRegularJobs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDownloadEntryLevelVsRegularJobsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDownloadEntryLevelVsRegularJobs>>
>;
export type AdminDownloadEntryLevelVsRegularJobsMutationBody =
  EntryLevelJobReportsDto;
export type AdminDownloadEntryLevelVsRegularJobsMutationError = unknown;

export const useAdminDownloadEntryLevelVsRegularJobs = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDownloadEntryLevelVsRegularJobs>>,
    TError,
    { data: EntryLevelJobReportsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDownloadEntryLevelVsRegularJobs>>,
  TError,
  { data: EntryLevelJobReportsDto },
  TContext
> => {
  const mutationOptions =
    getAdminDownloadEntryLevelVsRegularJobsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetDashboardUsers = (dashboardUserDto: DashboardUserDto) => {
  return customClient<DashboardUserResponse>({
    url: `/admin/dashboard/users`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: dashboardUserDto,
  });
};

export const getAdminGetDashboardUsersMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDashboardUsers>>,
    TError,
    { data: DashboardUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetDashboardUsers>>,
  TError,
  { data: DashboardUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetDashboardUsers>>,
    { data: DashboardUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetDashboardUsers(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetDashboardUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetDashboardUsers>>
>;
export type AdminGetDashboardUsersMutationBody = DashboardUserDto;
export type AdminGetDashboardUsersMutationError = unknown;

export const useAdminGetDashboardUsers = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetDashboardUsers>>,
    TError,
    { data: DashboardUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetDashboardUsers>>,
  TError,
  { data: DashboardUserDto },
  TContext
> => {
  const mutationOptions = getAdminGetDashboardUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetDashboardUser = (id: number, signal?: AbortSignal) => {
  return customClient<AdminGetDashboardUser200>({
    url: `/admin/dashboard/users/${id}`,
    method: "GET",
    signal,
  });
};

export const getAdminGetDashboardUserQueryKey = (id: number) => {
  return [`/admin/dashboard/users/${id}`] as const;
};

export const getAdminGetDashboardUserQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetDashboardUser>>,
  TError = unknown,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof adminGetDashboardUser>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAdminGetDashboardUserQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof adminGetDashboardUser>>
  > = ({ signal }) => adminGetDashboardUser(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetDashboardUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetDashboardUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminGetDashboardUser>>
>;
export type AdminGetDashboardUserQueryError = unknown;

export const useAdminGetDashboardUser = <
  TData = Awaited<ReturnType<typeof adminGetDashboardUser>>,
  TError = unknown,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof adminGetDashboardUser>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminGetDashboardUserQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminDeleteDashboardUser = (id: string) => {
  return customClient<string>({
    url: `/admin/dashboard/users/${id}`,
    method: "DELETE",
  });
};

export const getAdminDeleteDashboardUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteDashboardUser>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDeleteDashboardUser>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDeleteDashboardUser>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return adminDeleteDashboardUser(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDeleteDashboardUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDeleteDashboardUser>>
>;

export type AdminDeleteDashboardUserMutationError = unknown;

export const useAdminDeleteDashboardUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteDashboardUser>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDeleteDashboardUser>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getAdminDeleteDashboardUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUpdateDashboardUser = (
  id: number,
  updateDashboardUserDto: UpdateDashboardUserDto,
) => {
  return customClient<AdminUpdateDashboardUser200>({
    url: `/admin/dashboard/users/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateDashboardUserDto,
  });
};

export const getAdminUpdateDashboardUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateDashboardUser>>,
    TError,
    { id: number; data: UpdateDashboardUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUpdateDashboardUser>>,
  TError,
  { id: number; data: UpdateDashboardUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUpdateDashboardUser>>,
    { id: number; data: UpdateDashboardUserDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return adminUpdateDashboardUser(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUpdateDashboardUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUpdateDashboardUser>>
>;
export type AdminUpdateDashboardUserMutationBody = UpdateDashboardUserDto;
export type AdminUpdateDashboardUserMutationError = unknown;

export const useAdminUpdateDashboardUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateDashboardUser>>,
    TError,
    { id: number; data: UpdateDashboardUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminUpdateDashboardUser>>,
  TError,
  { id: number; data: UpdateDashboardUserDto },
  TContext
> => {
  const mutationOptions = getAdminUpdateDashboardUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminCreateDashboardUser = (
  createDashboardUserDto: CreateDashboardUserDto,
) => {
  return customClient<AdminCreateDashboardUser201>({
    url: `/admin/dashboard/users/create`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createDashboardUserDto,
  });
};

export const getAdminCreateDashboardUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreateDashboardUser>>,
    TError,
    { data: CreateDashboardUserDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminCreateDashboardUser>>,
  TError,
  { data: CreateDashboardUserDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminCreateDashboardUser>>,
    { data: CreateDashboardUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminCreateDashboardUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminCreateDashboardUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminCreateDashboardUser>>
>;
export type AdminCreateDashboardUserMutationBody = CreateDashboardUserDto;
export type AdminCreateDashboardUserMutationError = unknown;

export const useAdminCreateDashboardUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreateDashboardUser>>,
    TError,
    { data: CreateDashboardUserDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminCreateDashboardUser>>,
  TError,
  { data: CreateDashboardUserDto },
  TContext
> => {
  const mutationOptions = getAdminCreateDashboardUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetBlackLists = (blacklistDto: BlacklistDto) => {
  return customClient<BlacklistResponse>({
    url: `/admin/moderation/blacklists`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: blacklistDto,
  });
};

export const getAdminGetBlackListsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetBlackLists>>,
    TError,
    { data: BlacklistDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetBlackLists>>,
  TError,
  { data: BlacklistDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetBlackLists>>,
    { data: BlacklistDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetBlackLists(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetBlackListsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetBlackLists>>
>;
export type AdminGetBlackListsMutationBody = BlacklistDto;
export type AdminGetBlackListsMutationError = unknown;

export const useAdminGetBlackLists = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetBlackLists>>,
    TError,
    { data: BlacklistDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetBlackLists>>,
  TError,
  { data: BlacklistDto },
  TContext
> => {
  const mutationOptions = getAdminGetBlackListsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminCreatBlackLists = (
  createBlacklistDto: CreateBlacklistDto,
) => {
  return customClient<BlacklistResponse>({
    url: `/admin/moderation/blacklists/create`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createBlacklistDto,
  });
};

export const getAdminCreatBlackListsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreatBlackLists>>,
    TError,
    { data: CreateBlacklistDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminCreatBlackLists>>,
  TError,
  { data: CreateBlacklistDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminCreatBlackLists>>,
    { data: CreateBlacklistDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminCreatBlackLists(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminCreatBlackListsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminCreatBlackLists>>
>;
export type AdminCreatBlackListsMutationBody = CreateBlacklistDto;
export type AdminCreatBlackListsMutationError = unknown;

export const useAdminCreatBlackLists = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreatBlackLists>>,
    TError,
    { data: CreateBlacklistDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminCreatBlackLists>>,
  TError,
  { data: CreateBlacklistDto },
  TContext
> => {
  const mutationOptions = getAdminCreatBlackListsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetUserConvoReports = (
  userAbuseReportDto: UserAbuseReportDto,
) => {
  return customClient<UserAbuseReportResponse>({
    url: `/admin/moderation/user-convo-reports`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userAbuseReportDto,
  });
};

export const getAdminGetUserConvoReportsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetUserConvoReports>>,
    TError,
    { data: UserAbuseReportDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetUserConvoReports>>,
  TError,
  { data: UserAbuseReportDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetUserConvoReports>>,
    { data: UserAbuseReportDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetUserConvoReports(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetUserConvoReportsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetUserConvoReports>>
>;
export type AdminGetUserConvoReportsMutationBody = UserAbuseReportDto;
export type AdminGetUserConvoReportsMutationError = unknown;

export const useAdminGetUserConvoReports = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetUserConvoReports>>,
    TError,
    { data: UserAbuseReportDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetUserConvoReports>>,
  TError,
  { data: UserAbuseReportDto },
  TContext
> => {
  const mutationOptions = getAdminGetUserConvoReportsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminResolveUserConvoReport = (id: number) => {
  return customClient<string>({
    url: `/admin/moderation/user-convo-reports/resolve/${id}`,
    method: "PUT",
  });
};

export const getAdminResolveUserConvoReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminResolveUserConvoReport>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminResolveUserConvoReport>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminResolveUserConvoReport>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return adminResolveUserConvoReport(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminResolveUserConvoReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminResolveUserConvoReport>>
>;

export type AdminResolveUserConvoReportMutationError = unknown;

export const useAdminResolveUserConvoReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminResolveUserConvoReport>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminResolveUserConvoReport>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getAdminResolveUserConvoReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminBanUserConvoReport = (id: number) => {
  return customClient<string>({
    url: `/admin/moderation/user-convo-reports/ban/${id}`,
    method: "PUT",
  });
};

export const getAdminBanUserConvoReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminBanUserConvoReport>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminBanUserConvoReport>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminBanUserConvoReport>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return adminBanUserConvoReport(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminBanUserConvoReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminBanUserConvoReport>>
>;

export type AdminBanUserConvoReportMutationError = unknown;

export const useAdminBanUserConvoReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminBanUserConvoReport>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminBanUserConvoReport>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getAdminBanUserConvoReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminSendNotification = (
  createNotificationLogDto: CreateNotificationLogDto,
) => {
  return customClient<string>({
    url: `/admin/notificaiton/send`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createNotificationLogDto,
  });
};

export const getAdminSendNotificationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminSendNotification>>,
    TError,
    { data: CreateNotificationLogDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminSendNotification>>,
  TError,
  { data: CreateNotificationLogDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminSendNotification>>,
    { data: CreateNotificationLogDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminSendNotification(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminSendNotificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminSendNotification>>
>;
export type AdminSendNotificationMutationBody = CreateNotificationLogDto;
export type AdminSendNotificationMutationError = unknown;

export const useAdminSendNotification = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminSendNotification>>,
    TError,
    { data: CreateNotificationLogDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminSendNotification>>,
  TError,
  { data: CreateNotificationLogDto },
  TContext
> => {
  const mutationOptions = getAdminSendNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetNotifications = (
  notificationLogDto: NotificationLogDto,
) => {
  return customClient<NotificationLogResponse>({
    url: `/admin/notificaiton/logs`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: notificationLogDto,
  });
};

export const getAdminGetNotificationsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetNotifications>>,
    TError,
    { data: NotificationLogDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetNotifications>>,
  TError,
  { data: NotificationLogDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetNotifications>>,
    { data: NotificationLogDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetNotifications(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetNotifications>>
>;
export type AdminGetNotificationsMutationBody = NotificationLogDto;
export type AdminGetNotificationsMutationError = unknown;

export const useAdminGetNotifications = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetNotifications>>,
    TError,
    { data: NotificationLogDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetNotifications>>,
  TError,
  { data: NotificationLogDto },
  TContext
> => {
  const mutationOptions = getAdminGetNotificationsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetNews = (wpPostsDto: WpPostsDto) => {
  return customClient<WpPostEntityResponse[]>({
    url: `/admin/wp/posts`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: wpPostsDto,
  });
};

export const getAdminGetNewsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetNews>>,
    TError,
    { data: WpPostsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminGetNews>>,
  TError,
  { data: WpPostsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminGetNews>>,
    { data: WpPostsDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminGetNews(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminGetNewsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminGetNews>>
>;
export type AdminGetNewsMutationBody = WpPostsDto;
export type AdminGetNewsMutationError = unknown;

export const useAdminGetNews = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminGetNews>>,
    TError,
    { data: WpPostsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminGetNews>>,
  TError,
  { data: WpPostsDto },
  TContext
> => {
  const mutationOptions = getAdminGetNewsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminGetFilesByFolder = (signal?: AbortSignal) => {
  return customClient<OrganizationSharedFileEntity[]>({
    url: `/admin/organization-shared-file/folder`,
    method: "GET",
    signal,
  });
};

export const getAdminGetFilesByFolderQueryKey = () => {
  return [`/admin/organization-shared-file/folder`] as const;
};

export const getAdminGetFilesByFolderQueryOptions = <
  TData = Awaited<ReturnType<typeof adminGetFilesByFolder>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof adminGetFilesByFolder>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdminGetFilesByFolderQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof adminGetFilesByFolder>>
  > = ({ signal }) => adminGetFilesByFolder(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adminGetFilesByFolder>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AdminGetFilesByFolderQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminGetFilesByFolder>>
>;
export type AdminGetFilesByFolderQueryError = unknown;

export const useAdminGetFilesByFolder = <
  TData = Awaited<ReturnType<typeof adminGetFilesByFolder>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof adminGetFilesByFolder>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAdminGetFilesByFolderQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adminCreateFolder = (createFolderDto: CreateFolderDto) => {
  return customClient<OrganizationSharedFileEntity>({
    url: `/admin/organization-shared-file/folder`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createFolderDto,
  });
};

export const getAdminCreateFolderMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreateFolder>>,
    TError,
    { data: CreateFolderDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminCreateFolder>>,
  TError,
  { data: CreateFolderDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminCreateFolder>>,
    { data: CreateFolderDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminCreateFolder(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminCreateFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminCreateFolder>>
>;
export type AdminCreateFolderMutationBody = CreateFolderDto;
export type AdminCreateFolderMutationError = unknown;

export const useAdminCreateFolder = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreateFolder>>,
    TError,
    { data: CreateFolderDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminCreateFolder>>,
  TError,
  { data: CreateFolderDto },
  TContext
> => {
  const mutationOptions = getAdminCreateFolderMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUpdateFolder = (
  id: number,
  updateFolderDto: UpdateFolderDto,
) => {
  return customClient<OrganizationSharedFileEntity>({
    url: `/admin/organization-shared-file/folder/${id}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateFolderDto,
  });
};

export const getAdminUpdateFolderMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateFolder>>,
    TError,
    { id: number; data: UpdateFolderDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUpdateFolder>>,
  TError,
  { id: number; data: UpdateFolderDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUpdateFolder>>,
    { id: number; data: UpdateFolderDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return adminUpdateFolder(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUpdateFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUpdateFolder>>
>;
export type AdminUpdateFolderMutationBody = UpdateFolderDto;
export type AdminUpdateFolderMutationError = unknown;

export const useAdminUpdateFolder = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateFolder>>,
    TError,
    { id: number; data: UpdateFolderDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminUpdateFolder>>,
  TError,
  { id: number; data: UpdateFolderDto },
  TContext
> => {
  const mutationOptions = getAdminUpdateFolderMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDeleteFolder = (id: number) => {
  return customClient<void>({
    url: `/admin/organization-shared-file/folder/${id}`,
    method: "DELETE",
  });
};

export const getAdminDeleteFolderMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteFolder>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDeleteFolder>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDeleteFolder>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return adminDeleteFolder(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDeleteFolderMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDeleteFolder>>
>;

export type AdminDeleteFolderMutationError = unknown;

export const useAdminDeleteFolder = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteFolder>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDeleteFolder>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getAdminDeleteFolderMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminCreateFile = (createFileDto: CreateFileDto) => {
  return customClient<OrganizationSharedFileEntity>({
    url: `/admin/organization-shared-file`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createFileDto,
  });
};

export const getAdminCreateFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreateFile>>,
    TError,
    { data: CreateFileDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminCreateFile>>,
  TError,
  { data: CreateFileDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminCreateFile>>,
    { data: CreateFileDto }
  > = (props) => {
    const { data } = props ?? {};

    return adminCreateFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminCreateFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminCreateFile>>
>;
export type AdminCreateFileMutationBody = CreateFileDto;
export type AdminCreateFileMutationError = unknown;

export const useAdminCreateFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminCreateFile>>,
    TError,
    { data: CreateFileDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminCreateFile>>,
  TError,
  { data: CreateFileDto },
  TContext
> => {
  const mutationOptions = getAdminCreateFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminUpdateFile = (id: number, updateFileDto: UpdateFileDto) => {
  return customClient<OrganizationSharedFileEntity>({
    url: `/admin/organization-shared-file/${id}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: updateFileDto,
  });
};

export const getAdminUpdateFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateFile>>,
    TError,
    { id: number; data: UpdateFileDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminUpdateFile>>,
  TError,
  { id: number; data: UpdateFileDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminUpdateFile>>,
    { id: number; data: UpdateFileDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return adminUpdateFile(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminUpdateFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUpdateFile>>
>;
export type AdminUpdateFileMutationBody = UpdateFileDto;
export type AdminUpdateFileMutationError = unknown;

export const useAdminUpdateFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminUpdateFile>>,
    TError,
    { id: number; data: UpdateFileDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminUpdateFile>>,
  TError,
  { id: number; data: UpdateFileDto },
  TContext
> => {
  const mutationOptions = getAdminUpdateFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const adminDeleteFile = (id: number) => {
  return customClient<void>({
    url: `/admin/organization-shared-file/${id}`,
    method: "DELETE",
  });
};

export const getAdminDeleteFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteFile>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adminDeleteFile>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adminDeleteFile>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return adminDeleteFile(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdminDeleteFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDeleteFile>>
>;

export type AdminDeleteFileMutationError = unknown;

export const useAdminDeleteFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adminDeleteFile>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adminDeleteFile>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getAdminDeleteFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authAdminLogin = (authLoginDto: AuthLoginDto) => {
  return customClient<AdminLoginResponse>({
    url: `/admin/auth/login`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authLoginDto,
  });
};

export const getAuthAdminLoginMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminLogin>>,
    TError,
    { data: AuthLoginDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authAdminLogin>>,
  TError,
  { data: AuthLoginDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authAdminLogin>>,
    { data: AuthLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return authAdminLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthAdminLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof authAdminLogin>>
>;
export type AuthAdminLoginMutationBody = AuthLoginDto;
export type AuthAdminLoginMutationError = unknown;

export const useAuthAdminLogin = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminLogin>>,
    TError,
    { data: AuthLoginDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authAdminLogin>>,
  TError,
  { data: AuthLoginDto },
  TContext
> => {
  const mutationOptions = getAuthAdminLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authAdminVerifyTwoFactorCode = (
  twoFactorLoginDto: TwoFactorLoginDto,
) => {
  return customClient<AdminLoginResponse>({
    url: `/admin/auth/two-factor/verify`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: twoFactorLoginDto,
  });
};

export const getAuthAdminVerifyTwoFactorCodeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminVerifyTwoFactorCode>>,
    TError,
    { data: TwoFactorLoginDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authAdminVerifyTwoFactorCode>>,
  TError,
  { data: TwoFactorLoginDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authAdminVerifyTwoFactorCode>>,
    { data: TwoFactorLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return authAdminVerifyTwoFactorCode(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthAdminVerifyTwoFactorCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof authAdminVerifyTwoFactorCode>>
>;
export type AuthAdminVerifyTwoFactorCodeMutationBody = TwoFactorLoginDto;
export type AuthAdminVerifyTwoFactorCodeMutationError = unknown;

export const useAuthAdminVerifyTwoFactorCode = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminVerifyTwoFactorCode>>,
    TError,
    { data: TwoFactorLoginDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authAdminVerifyTwoFactorCode>>,
  TError,
  { data: TwoFactorLoginDto },
  TContext
> => {
  const mutationOptions =
    getAuthAdminVerifyTwoFactorCodeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authAdminResendTwoFactorCode = (
  resendTwoFactorLoginDto: ResendTwoFactorLoginDto,
) => {
  return customClient<TwoFactorLoginResponse>({
    url: `/admin/auth/two-factor/resend`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: resendTwoFactorLoginDto,
  });
};

export const getAuthAdminResendTwoFactorCodeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminResendTwoFactorCode>>,
    TError,
    { data: ResendTwoFactorLoginDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authAdminResendTwoFactorCode>>,
  TError,
  { data: ResendTwoFactorLoginDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authAdminResendTwoFactorCode>>,
    { data: ResendTwoFactorLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return authAdminResendTwoFactorCode(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthAdminResendTwoFactorCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof authAdminResendTwoFactorCode>>
>;
export type AuthAdminResendTwoFactorCodeMutationBody = ResendTwoFactorLoginDto;
export type AuthAdminResendTwoFactorCodeMutationError = unknown;

export const useAuthAdminResendTwoFactorCode = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminResendTwoFactorCode>>,
    TError,
    { data: ResendTwoFactorLoginDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authAdminResendTwoFactorCode>>,
  TError,
  { data: ResendTwoFactorLoginDto },
  TContext
> => {
  const mutationOptions =
    getAuthAdminResendTwoFactorCodeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authAdminRefreshToken = (
  refreshTokenRequestDto: RefreshTokenRequestDto,
) => {
  return customClient<AuthAdminRefreshToken201>({
    url: `/admin/auth/refresh-token`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: refreshTokenRequestDto,
  });
};

export const getAuthAdminRefreshTokenMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminRefreshToken>>,
    TError,
    { data: RefreshTokenRequestDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authAdminRefreshToken>>,
  TError,
  { data: RefreshTokenRequestDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authAdminRefreshToken>>,
    { data: RefreshTokenRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return authAdminRefreshToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthAdminRefreshTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof authAdminRefreshToken>>
>;
export type AuthAdminRefreshTokenMutationBody = RefreshTokenRequestDto;
export type AuthAdminRefreshTokenMutationError = unknown;

export const useAuthAdminRefreshToken = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminRefreshToken>>,
    TError,
    { data: RefreshTokenRequestDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authAdminRefreshToken>>,
  TError,
  { data: RefreshTokenRequestDto },
  TContext
> => {
  const mutationOptions = getAuthAdminRefreshTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authAdminForgotPassword = (
  authForgotPasswordDto: AuthForgotPasswordDto,
) => {
  return customClient<void>({
    url: `/admin/auth/forgot-password`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authForgotPasswordDto,
  });
};

export const getAuthAdminForgotPasswordMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminForgotPassword>>,
    TError,
    { data: AuthForgotPasswordDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authAdminForgotPassword>>,
  TError,
  { data: AuthForgotPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authAdminForgotPassword>>,
    { data: AuthForgotPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return authAdminForgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthAdminForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authAdminForgotPassword>>
>;
export type AuthAdminForgotPasswordMutationBody = AuthForgotPasswordDto;
export type AuthAdminForgotPasswordMutationError = unknown;

export const useAuthAdminForgotPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminForgotPassword>>,
    TError,
    { data: AuthForgotPasswordDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authAdminForgotPassword>>,
  TError,
  { data: AuthForgotPasswordDto },
  TContext
> => {
  const mutationOptions = getAuthAdminForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authAdminVerifyToken = (
  authVerifyTokenDto: AuthVerifyTokenDto,
) => {
  return customClient<UserTokenEntity>({
    url: `/admin/auth/verify-token`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authVerifyTokenDto,
  });
};

export const getAuthAdminVerifyTokenMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminVerifyToken>>,
    TError,
    { data: AuthVerifyTokenDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authAdminVerifyToken>>,
  TError,
  { data: AuthVerifyTokenDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authAdminVerifyToken>>,
    { data: AuthVerifyTokenDto }
  > = (props) => {
    const { data } = props ?? {};

    return authAdminVerifyToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthAdminVerifyTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof authAdminVerifyToken>>
>;
export type AuthAdminVerifyTokenMutationBody = AuthVerifyTokenDto;
export type AuthAdminVerifyTokenMutationError = unknown;

export const useAuthAdminVerifyToken = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminVerifyToken>>,
    TError,
    { data: AuthVerifyTokenDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authAdminVerifyToken>>,
  TError,
  { data: AuthVerifyTokenDto },
  TContext
> => {
  const mutationOptions = getAuthAdminVerifyTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authAdminResetPassword = (
  authResetPasswordDto: AuthResetPasswordDto,
) => {
  return customClient<UserEntity>({
    url: `/admin/auth/reset-password`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: authResetPasswordDto,
  });
};

export const getAuthAdminResetPasswordMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminResetPassword>>,
    TError,
    { data: AuthResetPasswordDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authAdminResetPassword>>,
  TError,
  { data: AuthResetPasswordDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authAdminResetPassword>>,
    { data: AuthResetPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return authAdminResetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthAdminResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof authAdminResetPassword>>
>;
export type AuthAdminResetPasswordMutationBody = AuthResetPasswordDto;
export type AuthAdminResetPasswordMutationError = unknown;

export const useAuthAdminResetPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminResetPassword>>,
    TError,
    { data: AuthResetPasswordDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authAdminResetPassword>>,
  TError,
  { data: AuthResetPasswordDto },
  TContext
> => {
  const mutationOptions = getAuthAdminResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authAdminVerifyAccount = (
  token: unknown,
  signal?: AbortSignal,
) => {
  return customClient<GeneralResponse>({
    url: `/admin/auth/verify-account/${token}`,
    method: "GET",
    signal,
  });
};

export const getAuthAdminVerifyAccountQueryKey = (token: unknown) => {
  return [`/admin/auth/verify-account/${token}`] as const;
};

export const getAuthAdminVerifyAccountQueryOptions = <
  TData = Awaited<ReturnType<typeof authAdminVerifyAccount>>,
  TError = unknown,
>(
  token: unknown,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authAdminVerifyAccount>>,
      TError,
      TData
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthAdminVerifyAccountQueryKey(token);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authAdminVerifyAccount>>
  > = ({ signal }) => authAdminVerifyAccount(token, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!token,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof authAdminVerifyAccount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthAdminVerifyAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof authAdminVerifyAccount>>
>;
export type AuthAdminVerifyAccountQueryError = unknown;

export const useAuthAdminVerifyAccount = <
  TData = Awaited<ReturnType<typeof authAdminVerifyAccount>>,
  TError = unknown,
>(
  token: unknown,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof authAdminVerifyAccount>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthAdminVerifyAccountQueryOptions(token, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const authAdminResendVerifyEmail = () => {
  return customClient<void>({
    url: `/admin/auth/resend-verify-email`,
    method: "POST",
  });
};

export const getAuthAdminResendVerifyEmailMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminResendVerifyEmail>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authAdminResendVerifyEmail>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authAdminResendVerifyEmail>>,
    void
  > = () => {
    return authAdminResendVerifyEmail();
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthAdminResendVerifyEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof authAdminResendVerifyEmail>>
>;

export type AuthAdminResendVerifyEmailMutationError = unknown;

export const useAuthAdminResendVerifyEmail = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authAdminResendVerifyEmail>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof authAdminResendVerifyEmail>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAuthAdminResendVerifyEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
